import { Modal, Typography, LinkButton } from '@eucalyptusvc/design-system';
import { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

export const NurseInfo = (): React.ReactElement => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <LinkButton size="sm" onClick={() => setShowPopup(true)}>
        <FaQuestionCircle size="16px" className="mr-2" />
        <Typography size="paragraph" isBold>
          What is a nurse practitioner?
        </Typography>
      </LinkButton>
      <Modal
        title="What is a nurse practitioner?"
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <Typography size="paragraph">
          A Nurse Practitioner (NP) is a Registered Nurse with the expertise and
          authority to diagnose, prescribe and treat people with a variety of
          conditions. NPs have completed additional university study at
          Master&apos;s degree level and are the most senior and independent
          clinical nurses in our health care system.
        </Typography>
        <br />
        <Typography size="paragraph">
          NPs at Software provide high-quality, dermatological care in
          partnership with our Dermatologists, GPs and the clinical support
          teams.
        </Typography>
      </Modal>
    </>
  );
};
