import {
  SkinGoalPersonalisationOption,
  SKIN_GOAL_PERSONALISATION_OPTIONS,
} from 'pages/consultation/regimen/constants';
import { MappedSkinGoal } from 'utils/personalisation';

export const getSkinPillOption = (
  sg: MappedSkinGoal,
): SkinGoalPersonalisationOption => {
  switch (sg.name) {
    case 'pimples':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['ReducePimples'];
    case 'pigmentation':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['FadePigmentation'];
    case 'dullness':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['BrightenSkin'];
    case 'blackhead':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['ReduceBlackheadsWhiteheads'];
    case 'fine lines':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['RemoveFineLinesWrinkles'];
    case 'redness':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['ReduceRedness'];
    case 'dry skin':
      return SKIN_GOAL_PERSONALISATION_OPTIONS['TreatDrySkin'];
    default:
      return SKIN_GOAL_PERSONALISATION_OPTIONS['TreatAcneScarring'];
  }
};
