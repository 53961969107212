import { ProblemType } from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';
import add from 'date-fns/add';
import format from 'date-fns/format';

export const formatDate = (date: Date): string => {
  return format(date, 'do MMM');
};

export const formatFullDate = (date: Date): string => {
  return format(date, 'do MMM yyyy');
};

export const formatDob = (date: Date): string => {
  return format(date, 'dd/MM/yyyy');
};

export function formatProblemType(type?: ProblemType): string {
  switch (type) {
    case 'ANTI_AGEING':
      return 'Anti-ageing';
    case 'ACNE':
      return 'Acne';
    default:
      return '';
  }
}

export function getAnswerFromShortCode(
  shortcode: string,
  answers?:
    | {
        question?: { shortcode?: string | null } | null;
        answer?: string | null;
      }[]
    | null,
): string {
  return (
    answers?.find(
      (answer) =>
        answer.question?.shortcode?.toUpperCase() === shortcode.toUpperCase(),
    )?.answer ?? ''
  );
}

export function getAnswerArrayFromShortCode(
  shortcode: string,
  answers?:
    | {
        question?: { shortcode?: string | null } | null;
        answersArray: string[];
      }[]
    | null,
): string[] {
  return (
    answers?.find(
      (answer) =>
        answer.question?.shortcode?.toUpperCase() === shortcode.toUpperCase(),
    )?.answersArray ?? []
  );
}

export function getSkinGoalsFromResponse(response?: {
  answer?: string | null;
  answersArray: string[];
}): string[] {
  let answers = [];
  if (response?.answer) {
    answers.push(response.answer);
  }

  if (response?.answersArray) {
    answers = answers.concat(response.answersArray);
  }

  return answers;
}

export const calculateEstimatedDeliveryDate = (
  orderDate: string | Date,
): Date => add(new Date(orderDate), { days: 7 });

export const arrayWithElementAtFront = <T>(
  array: T[],
  predicate: (value: T) => boolean,
): T[] => {
  const elementPosition = array.findIndex(predicate);

  if (elementPosition < 0) {
    return array;
  }

  return [
    array[elementPosition],
    ...array.slice(0, elementPosition),
    ...array.slice(elementPosition + 1),
  ];
};

export const isNursePractitionerEnabled = (
  problemType?: ProblemType,
): boolean => isSkinProblemType(problemType);

export const isCPRedesignEnabledForProblemType = (
  problemType?: ProblemType,
): boolean => problemType === 'HAIR';
