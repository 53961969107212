import { Button } from '@eucalyptusvc/design-system';
import React from 'react';
import { routes } from 'utils/routes';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    if (error.name.includes('ChunkLoadError')) {
      return { hasError: true };
    }
    return { hasError: false };
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <section className="h-screen flex">
          <div className="w-2/3 md:w-1/3 lg:w-1/4 mx-auto my-auto space-y-4">
            <div className="text-center">
              We&apos;ve released a new update for Software, please refresh to
              continue
            </div>
            <div className="mx-auto flex flex-col gap-y-4">
              <Button onClick={() => window.location.reload()}>Refresh</Button>
              <Button
                isFullWidth
                level="secondary"
                onClick={() => {
                  window.location.pathname = routes.profile;
                }}
              >
                Go to home
              </Button>
            </div>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}
