import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { ConsultationCardContentView } from './consultation-card-content-view';
import {
  ProblemType,
  RestartFlowMutation,
  RestartFlowMutationVariables,
} from '@customer-frontend/graphql-types';
import { useNotification } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';

type RestartFlowPromptCardProps = {
  problemType: ProblemType;
  routes: {
    consultation: {
      followUp: (id: string) => string;
    };
  };
};

export function RestartFlowPromptCard({
  problemType,
  routes,
}: RestartFlowPromptCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const [restartFlow, { loading }] = useMutation<
    RestartFlowMutation,
    RestartFlowMutationVariables
  >(
    gql`
      mutation RestartFlow($input: RestartFlowInput!) {
        restartFlow(input: $input) {
          consultation {
            id
          }
        }
      }
    `,
    {
      variables: {
        input: {
          type: problemType,
        },
      },
    },
  );

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'scriptExpiredPromptText',
          text: (
            <FormattedMessage
              defaultMessage="The script your {isPrescriber, select, true {prescriber} other {practitioner}} prescribed in your last consult has expired. Please complete a review consult with a {isPrescriber, select, true {prescriber} other {practitioner}} to proceed."
              description="Profile page card prompt text indicating that a user's script has expired and they'll need a new consultation"
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
              }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'scriptExpiredReviewConsultationButton',
          text: (
            <FormattedMessage
              defaultMessage="Complete review consult"
              description="Text for a review consultation due to expired scripts"
            />
          ),
          onClick: async () => {
            try {
              const consultation = await restartFlow();
              if (consultation.data?.restartFlow?.consultation.id) {
                history.push(
                  routes.consultation.followUp(
                    consultation.data?.restartFlow?.consultation.id,
                  ),
                );
              } else {
                throw new Error('No quiz application to push user to');
              }
            } catch (e) {
              notify.error({
                message: formatMessage({
                  defaultMessage:
                    'There was an issue creating a review consultation. Please contact support for further assistance.',
                }),
              });
            }
          },
          isDisabled: loading,
        },
      ]}
    />
  );
}
