import { Typography } from '@eucalyptusvc/design-system';
import {
  formatCentsToCurrency,
  calculateDiscountedPrice,
} from '@customer-frontend/order';
import { ReactElement } from 'react';
import { DiscountSubtotalProps } from './types';

const ReceiptItemWithPrice = ({
  title,
  amount,
  bolded = false,
}: {
  title: string;
  amount: number;
  bolded?: boolean;
}): React.ReactElement => {
  const formattedAmount = formatCentsToCurrency(amount, {
    includeDecimals: true,
  });
  return (
    <div className="flex justify-between">
      <Typography size="medium-paragraph" isBold={bolded}>
        {title}
      </Typography>
      <Typography size="medium-paragraph" isBold={bolded}>
        {formattedAmount}
      </Typography>
    </div>
  );
};

export const DiscountSubtotal = ({
  discountCode,
  chargeAmount,
  totalCopy = 'Total',
  products,
}: DiscountSubtotalProps): ReactElement => {
  const { totalPrice, amountSaved } = calculateDiscountedPrice({
    subtotal: chargeAmount,
    discount: discountCode,
    products,
  });
  return (
    <>
      <ReceiptItemWithPrice title="Subtotal" amount={chargeAmount} />
      {!!discountCode && (
        <ReceiptItemWithPrice title="Discount" amount={-amountSaved} />
      )}
      <ReceiptItemWithPrice bolded title={totalCopy} amount={totalPrice} />
    </>
  );
};
