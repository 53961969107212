import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  ConsultationStatus,
  FulfillmentStatus,
  ProblemType,
  SkinUsage,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { QuizCode } from '@customer-frontend/quiz';
import { useInSegment } from '@customer-frontend/services';
import { isSkinProblemType } from '@customer-frontend/utils';
import moment from 'moment';
import { CheckInQuizOutcomes } from 'pages/quiz/quiz-complete-general/types';
import { Card } from '../use-profile-cards';
import { completedStatuses } from '../utils';
import { getCheckInCard, SkinCheckInCardProps } from './check-in-card';
import {
  getCheckInResultsCard,
  SkinCheckInResultsCardProps,
} from './check-in-results';

import {
  CheckInQuizStatus,
  getCheckInQuizStatus,
} from './get-check-in-quiz-status';
interface UseCheckInQuizConfigProps {
  consultation?: {
    type: ProblemType;
    status: ConsultationStatus;
    treatment?: {
      id: string;
      status: TreatmentStatus;
      customerStatedTreatmentStartedAt?: string | null;
      orders: {
        fulfillment?: FulfillmentStatus | null | undefined;
        fulfilledAt?: string;
      }[];
    } | null;
  };
  trackerQuizApplications?:
    | {
        quiz: {
          quizCode: string;
        };
        createdAt: string;
        submittedAt?: string | null;
        outcomeSummary?: string | null | undefined;
        outcomes?: CheckInQuizOutcomes;
      }[]
    | null;
  customerAttributes?: {
    id: string;
    skinSeeingResults?: boolean | null | undefined;
    skinSideEffects?: boolean | null | undefined;
    skinTreatmentUsage?: SkinUsage | null | undefined;
  } | null;
}

export const useCheckInQuiz = ({
  consultation,
  trackerQuizApplications,
  customerAttributes,
}: UseCheckInQuizConfigProps):
  | Card<SkinCheckInCardProps>
  | Card<SkinCheckInResultsCardProps>
  | null => {
  const { data: inCheckInSegment } = useInSegment({
    variables: {
      segmentName: 'SKIN_CHECK_IN_SMS',
    },
  });

  const featureFlagClient = useFeatureFlagClient();

  const checkInQuizzes = trackerQuizApplications?.filter(
    (application) => application.quiz.quizCode === QuizCode.CHECK_IN,
  );

  const sortedDesCheckInQuizApplications = checkInQuizzes?.sort((a, b) =>
    moment(b.createdAt).diff(moment(a.createdAt)),
  );

  const mostRecentCheckInQuiz = sortedDesCheckInQuizApplications?.[0];

  const checkInShowStatus = getCheckInQuizStatus({
    customerStatedTreatmentStartedAt:
      consultation?.treatment?.customerStatedTreatmentStartedAt,
    mostRecentCheckInQuiz,
    treatment: consultation?.treatment,
  });

  const showCheckInQuiz =
    !!consultation &&
    isSkinProblemType(consultation.type) &&
    completedStatuses.includes(consultation.status) &&
    checkInShowStatus !== CheckInQuizStatus.HIDE &&
    featureFlagClient.getBoolean('SOFTWARE_SKIN_CHECK_IN_QUIZ');

  const ctaText =
    checkInShowStatus === CheckInQuizStatus.RESUME ? 'Resume' : 'Get started';

  if (showCheckInQuiz && inCheckInSegment?.inSegment) {
    return checkInShowStatus === CheckInQuizStatus.RESULTS
      ? getCheckInResultsCard({
          props: {
            customerAttributes,
            mostRecentCheckInQuiz,
            treatmentId: consultation.treatment?.id,
            type: consultation.type,
          },
          key: 'skinCheckInQuizCard',
        })
      : getCheckInCard({
          props: { ctaText },
          key: 'skinCheckInQuizCard',
        });
  }

  return null;
};
