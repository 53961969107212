import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { ProgressChecklistGuide } from 'components/progress-checklist';
import { useConsultationFlowConfig } from '@customer-frontend/config';
import { isSyncConsultation } from '@customer-frontend/consultation';
import { routes } from 'utils/routes';
import { isToday } from 'date-fns';

export const AwaitingDoctorCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const {
    id: consultationId,
    type: problemType,
    requiresPrescriberCall,
    isInNoPickUpQueue,
    latestPractitionerBooking,
  } = consultation;
  const { isSyncExperience } = useConsultationFlowConfig(problemType) ?? {};
  const isSyncConsult = isSyncConsultation(
    isSyncExperience,
    requiresPrescriberCall,
  );
  const { formatDate } = useIntl();
  const history = useHistory();

  let formattedPractitionerBookingString = null;
  if (latestPractitionerBooking) {
    const formattedStartTime = formatDate(
      latestPractitionerBooking.windowStartAt,
      { hour: 'numeric' },
    );
    const formattedEndTime = formatDate(latestPractitionerBooking.windowEndAt, {
      hour: 'numeric',
      timeZoneName: 'short',
    });
    const formattedDay = isToday(
      new Date(latestPractitionerBooking?.windowStartAt),
    )
      ? 'today'
      : formatDate(latestPractitionerBooking.windowStartAt, {
          weekday: 'long',
        });
    formattedPractitionerBookingString = `${formattedStartTime} - ${formattedEndTime} ${formattedDay}`;
  }

  return (
    <StatusLayout {...layoutProps} testId="awaiting-doctor-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      {isSyncConsult ? (
        <>
          {isInNoPickUpQueue && !latestPractitionerBooking ? (
            <Typography size="medium-paragraph">
              Your practitioner called but wasn’t able to reach you. Let’s make
              sure they call at a time that works for you.
            </Typography>
          ) : (
            <Typography size="medium-paragraph">
              <strong>Status:</strong> Your practitioner will call you between{' '}
              {formattedPractitionerBookingString}. While you’re waiting for
              your practitioner to call, feel free to leave questions for them
              in the chat.
            </Typography>
          )}

          <div className="flex flex-col gap-2 w-min">
            <Button
              onClick={() => {
                history.push(routes.consultation.chat(consultationId));
              }}
            >
              Open chat
            </Button>
            <Button
              onClick={() => {
                history.push(routes.consultation.scheduleCall(consultation.id));
              }}
            >
              Reschedule call
            </Button>
          </div>
        </>
      ) : (
        <div className="py-2">
          <ProgressChecklistGuide
            source="profile"
            problemType={layoutProps.problemType}
          />
        </div>
      )}
    </StatusLayout>
  );
};
