import React from 'react';
import { RestartTreatmentModal } from '../../restart-treatment';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { calculateTreatmentCost } from '@customer-frontend/treatment';
import { SkinGoalsSummary, StatusLayout } from './components';
import { StatusCardContentProps } from './types';
import { ViewPathologyReferral } from './view-pathology-referral';
import { getZendeskRequestUrl } from '@customer-frontend/utils';

export const PausedTreatmentCardContent = (
  props: StatusCardContentProps,
): React.ReactElement => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <StatusLayout {...props} testId="paused-treatment-status-card">
        <SkinGoalsSummary
          skinGoals={props.personalisationInput.mappedSkinGoals}
        />
        <Typography size="medium-paragraph">
          <strong>Treatment plan: </strong>
          {props.consultation.treatment?.product.name}
        </Typography>
        <div className="flex flex-col py-4 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Button
            isFullWidth
            eventElementName="treatmentPageResumeTreatmentButton"
            onClick={() => setShowModal(true)}
          >
            Resume Treatment
          </Button>
          <ViewPathologyReferral consultation={props.consultation} />
        </div>
        <Typography size="small-text" textAlign="center">
          If you were experiencing side effects or would like to change your
          treatment,{' '}
          <a
            target="_blank"
            className="underline cursor"
            href={getZendeskRequestUrl({
              problemType: props.problemType,
              email: props.userEmail,
              params: {
                tf_15542333724569: 'medical_support',
              },
            })}
            rel="noopener noreferrer"
          >
            click here
          </a>{' '}
          to connect with our medical&nbsp;team.
        </Typography>
      </StatusLayout>
      {props.consultation.treatment?.id && (
        <RestartTreatmentModal
          consultation={props.consultation}
          treatment={props.consultation?.treatment}
          close={() => setShowModal(false)}
          show={showModal}
          chargeAmount={calculateTreatmentCost(props.consultation.treatment)}
        />
      )}
    </>
  );
};
