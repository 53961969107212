import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useStartQuizApplication } from '@customer-frontend/services';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { SkinGoalsSummary, StatusLayout } from './components';
import { StatusCardContentProps } from './types';
import { useConsultationFlowConfig } from '@customer-frontend/config';
import { isSyncConsultation } from '@customer-frontend/consultation';
import { getInlineReadableProblemType } from '@customer-frontend/utils';

export const AwaitingSurveyCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const { type: problemType, requiresPrescriberCall } = consultation;
  const { isSyncExperience } = useConsultationFlowConfig(problemType) ?? {};
  const isSyncConsult = isSyncConsultation(
    isSyncExperience,
    requiresPrescriberCall,
  );
  const startQuizApplication = useStartQuizApplication();

  const handleContinueClick = async (): Promise<void> => {
    if (consultation.screeningQuizApplication?.id) {
      return history.push(
        routes.quiz.root(consultation.screeningQuizApplication.id),
      );
    }
    const quizApplicationInfo = await startQuizApplication(problemType);
    return history.push(routes.quiz.root(quizApplicationInfo.id));
  };

  return (
    <StatusLayout {...layoutProps} testId="awaiting-survey-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />

      <Typography size="medium-paragraph">
        <strong>Status: </strong>
        {isSyncConsult
          ? 'Answer a few questions to proceed to a call with your practitioner.'
          : `
            Looks like you've almost finished the quiz. Just a few more questions to go to get started on your ${getInlineReadableProblemType(
              problemType,
            )} journey with Software!
          `}
      </Typography>
      <Button
        eventElementName="treatmentPageContinueQuizButton"
        onClick={handleContinueClick}
      >
        {isSyncConsult ? 'Continue consult' : 'Continue quiz'}
      </Button>
    </StatusLayout>
  );
};
