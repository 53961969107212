import { type ReactElement } from 'react';
import type {
  Maybe,
  ReviewAwaitingSurveyCardTreatmentFragment,
} from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';

type ReviewAwaitingSurveyCardProps = {
  consultationId: string;
  requiresPhoneCall: boolean;
  quizApplicationSubmitted: boolean;
  treatment?: Maybe<ReviewAwaitingSurveyCardTreatmentFragment>;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  routes: {
    consultation: {
      followUp: (id: string) => string;
      phoneCall: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function ReviewAwaitingSurveyCard({
  consultationId,
  requiresPhoneCall,
  treatment,
  quizApplicationSubmitted,
  useCustomTreatmentDetails,
  routes,
}: ReviewAwaitingSurveyCardProps): ReactElement {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');
  const history = useHistory();
  const shouldShowWeightDashboardButton =
    treatment &&
    treatment.active &&
    treatment.type === 'WEIGHT_LOSS' &&
    !sunsetBpDashboard;

  const buttons = [
    {
      key: 'reviewAwaitingSurveyButton',
      text: (
        <FormattedMessage
          defaultMessage="Start consult"
          description="Button to start a review consult"
        />
      ),
      onClick: () => {
        if (quizApplicationSubmitted && requiresPhoneCall) {
          return history.push(routes.consultation.phoneCall(consultationId));
        }
        history.push(routes.consultation.followUp(consultationId));
      },
    },
  ];

  if (shouldShowWeightDashboardButton) {
    buttons.push({
      key: 'reviewAwaitingSurveyWeightDashboardButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => history.push(routes.weightLoss.dashboard),
    });
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'reviewAwaitingSurveyText',
          text: (
            <FormattedMessage
              defaultMessage="We've created a consultation to review your current treatment plan. Before we begin, you'll need to answer some questions for your {isPrescriber, select, true {prescriber} other {practitioner}}."
              description="Description informing users that they need to answer some questions before their next consultation"
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
              }}
            />
          ),
        },
      ]}
      buttons={buttons}
    >
      {treatment && (
        <TreatmentDetails
          treatment={treatment}
          status={formatMessage({
            defaultMessage: 'In review',
            description:
              'Text status on the profile to show that the consultation is in review',
          })}
          useCustomTreatmentDetails={useCustomTreatmentDetails}
        />
      )}
    </ConsultationCardContentView>
  );
}

ReviewAwaitingSurveyCard.fragment = gql`
  fragment ReviewAwaitingSurveyCardTreatment on Treatment {
    id
    type
    active
    ...TreatmentDetailsTreatment
  }

  ${TreatmentDetails.fragment}
`;
