import clsx from 'clsx';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { primaryNavigationRoutes } from '../../utils/routes';
import { ProfileLayout } from './layout';
import { Typography } from '@eucalyptusvc/design-system';
import {
  SoftwareProfileQuery,
  SoftwareProfileQueryVariables,
} from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';
import { shouldShowExploreProductsSection } from './treatment/utils';
import { gql, useQuery } from '@apollo/client';

type ProfileProps = {
  children: ReactElement[] | ReactElement;
};

const getTabs = (showDiscoverTab: boolean): [string, string][] => {
  const { home, treatment, discover, support } = primaryNavigationRoutes;

  const tabs: [string, string][] = [
    ['home', home],
    ['treatment', treatment],
  ];

  if (showDiscoverTab) {
    tabs.push(['discover', discover]);
  }

  tabs.push(['support', support]);

  return tabs;
};

function Profile({ children }: ProfileProps): ReactElement {
  const location = useLocation();
  const { data } = useQuery<
    SoftwareProfileQuery,
    SoftwareProfileQueryVariables
  >(
    gql`
      query SoftwareProfile {
        consultations {
          id
          type
          treatment {
            id
            status
            hasUnfulfilledOrder
            type
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const skinTreatment = data?.consultations?.find(
    (c) => c && isSkinProblemType(c.type),
  )?.treatment;

  const discoverTabEnabled = shouldShowExploreProductsSection(skinTreatment);

  return (
    <div className="bg-beige-100 min-h-screen">
      <div className="border-b border-beige-200 bg-primary-100">
        <div className={'flex justify-evenly max-w-2xl mx-auto md:px-4 pt-1'}>
          {getTabs(discoverTabEnabled).map(([name, route]) => {
            const isActive = location.pathname.includes(route);

            return (
              <Link
                key={name}
                className={clsx(
                  'text-center',
                  'cursor-pointer',
                  'py-4 w-full px-2',
                  'select-none flex justify-center border-b-4',
                  isActive ? 'border-black' : 'border-transparent',
                )}
                to={route}
              >
                <div className="relative">
                  <Typography size="medium-paragraph" isBold={isActive}>
                    <span className="capitalize break-normal">{name}</span>
                  </Typography>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <ProfileLayout>{children}</ProfileLayout>
    </div>
  );
}

export default Profile;
