import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/misc';
import { Button, Divider, Typography } from '@eucalyptusvc/design-system';
import { shouldShowExploreProductsSection } from '../utils';
import {
  formatCentsToCurrency,
  getActiveOtcScheduleProducts,
} from '@customer-frontend/order';
import { StatusCardContentProps } from './types';
import { ProductRow, SkinGoalsSummary, StatusLayout } from './components';
import { ReactComponent as ShoppingCartIcon } from '../../../../assets/icons/cart.svg';
import { ReactComponent as DollarCoinIcon } from '../../../../assets/icons/dollar-coin.svg';
import { primaryNavigationRoutes } from 'utils/routes';
import { calculateTreatmentCost } from '@customer-frontend/treatment';
import { ViewPathologyReferral } from './view-pathology-referral';
import { getRxProductName } from 'utils/logic/order';
import { getConfig } from '@customer-frontend/config';

export const ActiveTreatmentCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const config = getConfig();

  const treatment = consultation.treatment;
  const refillsLeft = consultation.treatment?.refillsLeft;
  const nextPayment = consultation.treatment?.nextPayment;
  const treatmentCostCents = treatment
    ? calculateTreatmentCost(treatment)
    : null;
  const shouldShowExploreProducts = shouldShowExploreProductsSection(
    consultation.treatment,
  );

  const activeOrderProducts = getActiveOtcScheduleProducts(
    consultation.treatment,
  );

  if (consultation.treatment?.product) {
    activeOrderProducts.unshift({
      ...consultation.treatment?.product,
      name:
        getRxProductName({
          product: treatment?.product ?? {},
          problemType: consultation.type,
        }) ?? '',
      cadence: 'RECURRING',
      quantity: 1,
    });
  }

  return (
    <StatusLayout {...layoutProps} testId="active-treatment-status-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <strong>Treatment plan: </strong>
          {consultation.treatment?.product.name}
        </Typography>
        <Typography size="medium-paragraph">
          <strong>Repeats left: </strong>
          {refillsLeft}
        </Typography>
      </div>
      <div className="space-y-4">
        {nextPayment && treatment?.hasScriptExpired && (
          <div className="space-y-2">
            <Typography size="xs" isBold>
              Your next order is on hold
            </Typography>
            <Typography size="medium-paragraph" isItalic>
              Your script has expired since it`&apos;`s been over a year since
              you spoke to a practitioner. You will be automatically put in
              touch with a practitioner on {formatDate(new Date(nextPayment))}
            </Typography>
            <Typography size="medium-paragraph" isItalic>
              To speak with a practitioner and renew your script sooner, please
              contact{' '}
              <a href={`mailto:${config.supportEmail}`}>
                {config.supportEmail}
              </a>
            </Typography>
          </div>
        )}
        {!treatment?.hasScriptExpired && (
          <>
            <Typography size="xs" isBold>
              Your next order
            </Typography>
            <div>
              <ul className="flex flex-col md:flex-row md:flex-wrap">
                {activeOrderProducts.map((product) => (
                  <ProductRow product={product} key={product.id} />
                ))}
              </ul>
              <Divider variant="separator" palette="alternate" mt="xs" />

              <div className="flex flex-col space-y-2 md:flex-row md:space-x-8 md:space-y-0 pb-2">
                {nextPayment && (
                  <div className="flex items-center space-x-2">
                    <ShoppingCartIcon />
                    <Typography size="medium-paragraph">
                      Next order shipping: {formatDate(new Date(nextPayment))}
                    </Typography>
                  </div>
                )}
                {treatmentCostCents && (
                  <div className="flex items-center space-x-2">
                    <DollarCoinIcon />
                    <Typography size="medium-paragraph">
                      Total: {formatCentsToCurrency(treatmentCostCents)}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!treatment?.hasScriptExpired && (
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Button
            isFullWidth
            eventElementName="treatmentPageViewTreatmentButton"
            onClick={() => {
              history.push(`/treatment/${consultation?.treatment?.id}/plan`);
            }}
          >
            View details
          </Button>
          <ViewPathologyReferral consultation={consultation} />
          {shouldShowExploreProducts && treatment && (
            <Button
              isFullWidth
              eventElementName="treatmentPageExploreProductsButton"
              level="secondary"
              onClick={() => {
                history.push(
                  primaryNavigationRoutes.discover +
                    '?utm_source=explore_products',
                );
              }}
            >
              ✨ Explore additional products
            </Button>
          )}
        </div>
      )}
    </StatusLayout>
  );
};
