import { Divider, Typography } from '@eucalyptusvc/design-system';
import { colors } from '@eucalyptusvc/design-system/src/theme/software/palette';
import { getSkinPillOption } from 'pages/consultation/prepayment-experience/personalisation-screen/utils';
import { SkinGoalPill } from 'pages/consultation/regimen/skin-goal-labels';
import React from 'react';
import { isSkinProblemType } from '@customer-frontend/utils';
import { MappedSkinGoal } from 'utils/personalisation';
import { StatusHeaderProps } from './types';
import { ReactComponent as SkinWithHair } from '../../../../assets/icons/skin-with-hair.svg';
import { ReactComponent as SkinNoHair } from '../../../../assets/icons/skin-no-hair.svg';

export const SkinGoalsSummary = ({
  skinGoals,
}: {
  skinGoals: MappedSkinGoal[];
}): React.ReactElement | null => {
  if (skinGoals.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-1 flex-wrap">
      <Typography element="span" size="medium-paragraph">
        <strong>Treating: </strong>
      </Typography>
      {skinGoals.map((sg) => {
        const skinGoalV2Options = getSkinPillOption(sg);
        return <SkinGoalPill key={sg.name} options={skinGoalV2Options} />;
      })}
    </div>
  );
};

export const StatusLayout = ({
  title: subtitle,
  testId,
  children,
  problemType,
}: StatusHeaderProps & {
  testId: string;
  children: React.ReactNode;
}): React.ReactElement => {
  let Icon: React.FC | null = null;
  let title: 'Hair loss' | 'Skin' | null = null;
  if (isSkinProblemType(problemType)) {
    Icon = SkinNoHair;
    title = 'Skin';
  } else if (problemType === 'HAIR') {
    Icon = SkinWithHair;
    title = 'Hair loss';
  }

  return (
    <div>
      {title && (
        <>
          <div className="flex items-center space-x-2">
            {Icon ? <Icon /> : null}
            <Typography size="md" isBold>
              {title}
            </Typography>
          </div>

          <Divider size="sm" mt="sm" />
        </>
      )}
      <div className="space-y-4 flex flex-col" data-testid={testId}>
        {subtitle && (
          <Typography size="medium-paragraph" isBold>
            {subtitle}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};

export const ProductRow = ({
  product,
}: {
  product: { photo?: { url: string } | null; name: string; quantity: number };
}): React.ReactElement => {
  return (
    <li className="flex md:w-1/2 items-center mb-3">
      <img
        src={product.photo?.url}
        className="h-14 w-14 rounded-lg mr-3 border border-primary-400"
      />
      <div className="space-y-1">
        <Typography size="medium-paragraph">{product.name}</Typography>
        <Typography size="paragraph" color={colors.primary[500]}>
          Qty: {product.quantity}
        </Typography>
      </div>
    </li>
  );
};
