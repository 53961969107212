import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { getConfig } from '@customer-frontend/config';

type DoctorRejectedCardProps = {
  consultationId: string;
  doctorName: string;
  routes: {
    consultation: {
      reason: (id: string) => string;
    };
  };
};

export function DoctorRejectedCard({
  consultationId,
  doctorName,
  routes,
}: DoctorRejectedCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'doctorCompletedText',
          text: (
            <FormattedMessage
              defaultMessage="Unfortunately, {doctorName} has reviewed your information and determined {brandTitle} does not have a suitable treatment for you today."
              description="Description in profile to show that practitioner has rejected the users consultation"
              values={{
                doctorName,
                brandTitle: config.title,
              }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'doctorCompletedButton',
          level: 'secondary' as const,
          text: (
            <FormattedMessage
              defaultMessage="View reason"
              description="Button in profile to open up a page that shows a reason for being rejected"
            />
          ),
          onClick: () => {
            history.push(routes.consultation.reason(consultationId));
          },
        },
      ]}
    />
  );
}
