import { QuizCode } from '@customer-frontend/quiz';
import { Button, Typography, useResponsive } from '@eucalyptusvc/design-system';
import { ReactComponent as BookOpenIcon } from '../../../../assets/icons/book-open-thin.svg';
import { ReactComponent as ReadingTimeIcon } from '../../../../assets/icons/reading-time.svg';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Card as CardType, CardKey } from '../use-profile-cards';

type SkinOnboardingCardProps = {
  ctaText: string;
};

const SkinOnboardingCard: React.FC<SkinOnboardingCardProps> = ({ ctaText }) => {
  const { isMobile } = useResponsive();
  const history = useHistory();

  return (
    <div className="border border-black rounded-2xl bg-secondary-100 p-4">
      <div className="flex justify-between space-x-4">
        <div className="flex flex-col space-y-3">
          <div className="flex items-center space-x-2">
            <BookOpenIcon className="h-3 w-auto" />
            <Typography size="paragraph">
              <span className="uppercase">software 101</span>
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            Improve your skin journey with our guide to getting started.
          </Typography>
          <div className="flex items-center space-x-1">
            <ReadingTimeIcon className="h-3 w-auto" />
            <Typography size="paragraph">
              <span className="uppercase">2 min</span>
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-center md:justify-end items-end">
          <Button
            size={isMobile ? 'sm' : 'md'}
            onClick={() =>
              history.push(routes.quiz.general.start(QuizCode.SKIN_ONBOARDING))
            }
          >
            {ctaText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const getSkinOnboardingCard = ({
  props,
  key,
}: {
  props: SkinOnboardingCardProps;
  key: CardKey;
}): CardType<SkinOnboardingCardProps> => ({
  CardComponent: SkinOnboardingCard,
  key,
  props,
});
