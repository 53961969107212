import { Button, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { StatusCardContentProps } from './types';
import { StatusLayout } from './components';
import { routes } from 'utils/routes';
import { getInlineReadableProblemType } from '@customer-frontend/utils';

export const DoctorCompletedCardContent = ({
  consultation,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();

  return (
    <StatusLayout {...layoutProps} testId="doctor-completed-consultation-card">
      <Typography size="medium-paragraph">
        Based on your questions, {consultation.doctor?.shortClinicianName} has
        determined that a personalised Software{' '}
        {getInlineReadableProblemType(layoutProps.problemType)} treatment
        isn&apos;t suitable for you at this time.
      </Typography>
      <Typography size="medium-paragraph">
        We understand if you&apos;re disappointed. However, your health and
        safety will always be our first priority.
      </Typography>
      <Button
        eventElementName="treatmentPageRejectedTreatmentButton"
        onClick={() => {
          history.push(routes.consultation.reason(consultation.id));
        }}
      >
        View reason for rejection
      </Button>
    </StatusLayout>
  );
};
