import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { PrescriberType } from '@customer-frontend/doctor';
import { getConfig } from '@customer-frontend/config';

type InitialPurchasePromptCardProps = {
  consultationId: string;
  isSyncConsult: boolean;
  routes: {
    consultation: {
      upfrontPayment: (id: string) => string;
    };
  };
};

export function InitialPurchasePromptCard({
  consultationId,
  isSyncConsult,
  routes,
}: InitialPurchasePromptCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();

  if (isSyncConsult) {
    return (
      <ConsultationCardContentView
        paragraphs={[
          {
            key: 'initialPurchaseText',
            text: (
              <FormattedMessage
                defaultMessage="Your answers indicate you could be suitable for our program. Your next step is to proceed to payment so you can continue your consult."
                description="Profile page initial purchase prompt text informing users to pay to proceed their consultation"
              />
            ),
          },
        ]}
        buttons={[
          {
            key: 'initialPurchaseButton',
            text: (
              <FormattedMessage
                defaultMessage="Go to payment"
                description="Profile page initial purchase prompt button"
              />
            ),
            onClick: () => {
              history.push(routes.consultation.upfrontPayment(consultationId));
            },
          },
        ]}
      />
    );
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'initialPurchaseText',
          text: (
            <FormattedMessage
              defaultMessage="You've finished the quiz! Your next step is to pay for a consultation and have a {isPrescriber, select, true {prescriber} other {practitioner}} review your responses."
              description="Profile page initial purchase prompt text"
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
              }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'initialPurchaseButton',
          text: (
            <FormattedMessage
              defaultMessage="Continue to payment"
              description="Profile page initial purchase prompt button"
            />
          ),
          onClick: () => {
            history.push(routes.consultation.upfrontPayment(consultationId));
          },
        },
      ]}
    />
  );
}
