import { ProblemType, SkinUsage } from '@customer-frontend/graphql-types';
import { Collapse, Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as DownChevron } from '../../../../assets/icons/chevron-down.svg';
import { ReactComponent as UpChevron } from '../../../../assets/icons/chevron-up.svg';
import clsx from 'clsx';
import { CheckInQuizOutcome } from 'pages/quiz/quiz-complete-general/check-in/check-in-quiz-outcome';
import { CheckInQuizOutcomeActions } from 'pages/quiz/quiz-complete-general/check-in/check-in-quiz-outcome-actions';
import { CheckInResponseSummary } from 'pages/quiz/quiz-complete-general/check-in/check-in-response-summary';
import { CheckInQuizOutcomes } from 'pages/quiz/quiz-complete-general/types';
import { useState } from 'react';
import { Card, CardKey } from '../use-profile-cards';

export type SkinCheckInResultsCardProps = {
  mostRecentCheckInQuiz?: {
    outcomeSummary?: string | null | undefined;
    outcomes?: CheckInQuizOutcomes;
  };
  customerAttributes?: {
    id: string;
    skinSeeingResults?: boolean | null | undefined;
    skinSideEffects?: boolean | null | undefined;
    skinTreatmentUsage?: SkinUsage | null | undefined;
  } | null;
  treatmentId?: string | null;
  type: ProblemType;
};

const CheckInResultsCard: React.FC<SkinCheckInResultsCardProps> = ({
  mostRecentCheckInQuiz,
  customerAttributes,
  treatmentId,
  type,
}: SkinCheckInResultsCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  if (
    !mostRecentCheckInQuiz ||
    !mostRecentCheckInQuiz?.outcomeSummary ||
    !customerAttributes ||
    !treatmentId
  ) {
    return null;
  }

  const isOffTrack = mostRecentCheckInQuiz?.outcomeSummary === 'OFF_TRACK';

  return (
    <div className="rounded-tr-2xl rounded-tl-2xl ">
      <div
        className={clsx(
          'border border-b-0 border-black flex flex-col justify-between space-y-4 p-4 rounded-tr-2xl rounded-tl-2xl',
          isOffTrack ? 'bg-black text-white' : 'bg-beige-200',
        )}
      >
        <CheckInQuizOutcome
          quizSummary={mostRecentCheckInQuiz?.outcomeSummary}
          hideSubHeading={true}
        />
        <CheckInQuizOutcomeActions
          outcomes={mostRecentCheckInQuiz?.outcomes}
          treatmentId={treatmentId}
          type={type}
          isOffTrack={isOffTrack}
        />
      </div>
      <div className="border border-t-0  border-black rounded-br-2xl rounded-bl-2xl">
        <div className="px-4 py-3">
          <div className="flex flex-row justify-between items-center mb-2">
            <Typography size="small-text" isBold>
              What you shared with us
            </Typography>
            <div onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <UpChevron /> : <DownChevron />}
            </div>
          </div>

          <Collapse in={isExpanded}>
            <CheckInResponseSummary
              usage={customerAttributes.skinTreatmentUsage}
              seeingResults={customerAttributes.skinSeeingResults}
              sideEffects={customerAttributes.skinSideEffects}
            />
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export const getCheckInResultsCard = ({
  props,
  key,
}: {
  props: SkinCheckInResultsCardProps;
  key: CardKey;
}): Card<SkinCheckInResultsCardProps> => ({
  CardComponent: CheckInResultsCard,
  key,
  props,
});
