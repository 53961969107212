import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { formatDoctorName, TitleType } from '@customer-frontend/doctor';
import { NurseInfo } from './nurse-info';
import { isNursePractitionerEnabled } from 'utils/misc';

export const DoctorAssignedCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const { doctor } = consultation;

  return (
    <StatusLayout {...layoutProps} testId="doctor-assigned-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      <Typography size="medium-paragraph">
        Your information is currently being reviewed by{' '}
        {doctor
          ? formatDoctorName(doctor, TitleType.DOCTOR_LAST_NAME)
          : 'your practitioner'}
        . We&apos;ll be in touch with you soon.
      </Typography>
      {consultation.stage === 'FOLLOW_UP' &&
        isNursePractitionerEnabled(layoutProps.problemType) && <NurseInfo />}
      {consultation.allowPatientToSendMessageToDoctor &&
        (consultation.chatThread?.id ? (
          <Button
            onClick={() => {
              history.push(routes.consultation.chat(consultation.id));
            }}
          >
            Open Chat
          </Button>
        ) : (
          <Button
            eventElementName="treatmentPageViewConsultButton"
            onClick={() => {
              history.push(routes.consultation.review(consultation.id));
            }}
          >
            View consult
          </Button>
        ))}
    </StatusLayout>
  );
};
