import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';

type SubstitutePurchasePromptCardProps = {
  consultationId: string;
  practitionerName: string;
  routes: {
    consultation: {
      reviewSubstitution: (id: string) => string;
      activatePurchaseFlow: {
        review: (id: string) => string;
      };
    };
  };
};

export function SubstitutePurchasePromptCard({
  consultationId,
  practitionerName,
  routes,
}: SubstitutePurchasePromptCardProps): JSX.Element | null {
  const history = useHistory();
  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'substitutePurchaseText',
          text: (
            <FormattedMessage
              defaultMessage="{person} has prescribed you a treatment plan."
              description="Profile page substitute purchase prompt text"
              values={{ person: practitionerName }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'substitutePurchaseButton',
          text: (
            <FormattedMessage
              defaultMessage="View treatment plan"
              description="Profile page substitute purchase prompt button"
            />
          ),
          onClick: () => {
            history.push(
              routes.consultation.activatePurchaseFlow.review(consultationId),
            );
          },
        },
      ]}
    />
  );
}
