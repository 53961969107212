import { gql, useQuery } from '@apollo/client';
import { SoftwareProfileHomeQuery } from '@customer-frontend/graphql-types';
import { Typography, useResponsive } from '@eucalyptusvc/design-system';
import { Loading } from 'components/presentational/loading';
import { type ReactElement } from 'react';
import { useProfileCards } from './use-profile-cards';
import { useProfileCards as useSharedProfileCards } from '@customer-frontend/services';

const SOFTWARE_PROFILE_HOME_QUERY_DOCUMENT = gql`
  query SoftwareProfileHome {
    customerAttributes {
      id
      skinSeeingResults
      skinSideEffects
      skinTreatmentUsage
    }
    profile {
      id
      firstName
      email
      consultations(orderBy: { createdAt: desc }) {
        id
        type
        status
        createdAt
        updatedAt
        completedAt
        stage
        isUpfrontPayment
        isApproved
        requiresPrescriberCall
        isInNoPickUpQueue
        latestPractitionerBooking {
          id
          windowStartAt
          windowEndAt
        }
        doctor {
          id
          fullName
          fullClinicianName
          shortClinicianName
          provider {
            id
            clinicianType
          }
        }
        reminders {
          id
          canceled
          doneAt
          scheduledAt
        }
        chatThread {
          id
        }
        allowPatientToSendMessageToDoctor
        treatment {
          id
          type
          status
          isLastOrderUnfulfilled
          refillsLeft
          nextPayment
          createdAt
          customerStatedTreatmentStartedAt
          pendingFollowUpReminderDate
          hasScriptExpired
          plan {
            id
            amount
          }
          orders {
            id
            createdAt
            fulfilledAt
            fulfillment
          }
          otcSchedules {
            id
            isActive
            cadence
            quantity
            product {
              id
              name
              variants {
                id
                price
              }
              photo {
                id
                url
              }
            }
          }
          hasUnfulfilledOrder
          product {
            id
            name
            variants {
              id
              price
            }
            photo {
              id
              url
            }
          }
        }
        quizApplication {
          id
          submittedAt
          answers {
            id
            answer
            answersArray
            question {
              id
              shortcode
            }
          }
        }
        screeningQuizApplication {
          id
          submittedAt
        }
        rejectReason
        ...UseProfileCardsConsultation
      }
      initialQuizApplications: quizApplications(purpose: INITIAL) {
        id
        createdAt
        updatedAt
        submittedAt
        problemType
        purpose
        ...UseProfileCardsQuizApplication
      }
      trackerQuizApplications: quizApplications(purpose: TRACKER) {
        id
        quiz {
          id
          quizCode
        }
        outcomeSummary
        outcomes {
          ... on QuizOutcomeV2 {
            id
          }
          ... on ArticleQuizOutcome {
            link
          }
          ... on NotEligibleQuizOutcome {
            reasons
          }
          ... on BuyOtcQuizOutcome {
            products {
              id
              name
              variants {
                id
                public
              }
              photo {
                id
                url
              }
            }
          }
          ... on ZendeskQuizOutcome {
            id
          }
        }
        createdAt
        submittedAt
      }
      purchases {
        id
        ...UseProfileCardsPurchase
      }
    }
    progressEntries(type: SKIN) {
      id
      date
      type
      photos {
        id
        angle
        signedUrl
      }
    }
  }

  ${useSharedProfileCards.fragment}
`;

const TreatmentSection = (): ReactElement => {
  const { data, loading } = useQuery<SoftwareProfileHomeQuery>(
    SOFTWARE_PROFILE_HOME_QUERY_DOCUMENT,
  );
  const cards = useProfileCards({ data });
  const { isMobile } = useResponsive();
  const user = data?.profile;

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="space-y-2 mb-8">
        <Typography size="medium-paragraph">Welcome</Typography>
        {user && (
          <Typography size={isMobile ? 'lg' : 'md'} isBold>
            {user?.firstName}
          </Typography>
        )}
      </div>
      <div className="space-y-4 md:space-y-6">
        {cards.map(({ CardComponent, props, key }) => {
          return <CardComponent key={key} {...props} />;
        })}
      </div>
    </div>
  );
};

export default TreatmentSection;
