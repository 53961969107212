import React, { useState } from 'react';
import { Divider, Typography, ActionModal } from '@eucalyptusvc/design-system';
import { gql, useMutation } from '@apollo/client';
import { notificationService } from '@customer-frontend/notifications';
import {
  formatCentsToCurrency,
  getActiveOtcScheduleProducts,
  RemovableDiscountCodeForm,
  usePersistedDiscountFromURL,
} from '@customer-frontend/order';
import {
  DiscountCodeFormFragment,
  OtcScheduleCadence,
  ProblemType,
} from '@customer-frontend/graphql-types';
import { ProductList } from './product-list';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { useAddTreatmentDiscount } from '@customer-frontend/services';
import { DiscountSubtotal } from './discount-form-with-total/discount-subtotal';
import { getAllProductsFromTreatment } from '@customer-frontend/treatment';

const restartTreatmentMutation = gql`
  mutation RefillTreatmentRestartTreatmentSoftware($treatmentId: String!) {
    refillTreatment(treatmentId: $treatmentId) {
      id
      status
      refillsLeft
      nextPayment
    }
  }
`;

export const RestartTreatmentModal = ({
  show,
  close,
  treatment,
  chargeAmount,
  consultation,
}: {
  show: boolean;
  close: () => void;
  chargeAmount: number;
  treatment: {
    id: string;
    type: ProblemType;
    product: {
      name: string;
      id: string;
      photo?: {
        url: string;
      } | null;
    };
    plan?: {
      amount: number;
    } | null;
    otcSchedules: {
      cadence: OtcScheduleCadence;
      quantity: number;
      product: {
        name: string;
        id: string;
        variants: { price: number; id: string }[];
        photo?: {
          url: string;
        } | null;
      };
      isActive: boolean;
    }[];
  };
  consultation: {
    order?: {
      id: string;
    } | null;
  };
}): React.ReactElement => {
  const [validDiscountCode, setValidDiscountCode] =
    useState<DiscountCodeFormFragment>();

  const [restartTreatment, { loading }] = useMutation(
    restartTreatmentMutation,
    {
      onCompleted: () =>
        notificationService.show({
          type: 'success',
          message: 'Your treatment has been restarted.',
        }),
      onError: () => {
        notificationService.show({
          type: 'error',
          message:
            'Your request to restart your treatment has failed. Please try again.',
        });
        close();
      },
    },
  );
  const [addTreatmentDiscount, { loading: addDiscountLoading }] =
    useAddTreatmentDiscount({
      onError: () => {
        (): void => {
          notificationService.show({
            type: 'error',
            message: 'Unable to add discount to treatment',
          });
        };
      },
    });
  const handleRemoveDiscount = (): void => {
    setValidDiscountCode(undefined);
  };

  const featureFlagClient = useFeatureFlagClient();
  const showDiscountFields = featureFlagClient.getBoolean(
    'ENABLE_TREATMENT_PLAN_DISCOUNT_FIELDS',
  );

  const { persistedDiscountCode, clearCode } = usePersistedDiscountFromURL();

  const handleRestartTreatment = async (): Promise<void> => {
    try {
      await restartTreatment({
        variables: {
          treatmentId: treatment.id,
        },
      });
      if (showDiscountFields && validDiscountCode && treatment.id) {
        await addTreatmentDiscount({
          variables: {
            discountCode: validDiscountCode.code,
            treatmentId: treatment.id,
          },
        });
        clearCode();
      }
    } catch {
      notificationService.show({
        type: 'error',
        message:
          'Your request to restart your treatment has failed. Please try again.',
      });
    } finally {
      close();
    }
  };
  return (
    <ActionModal
      primaryAction={{
        label: 'Confirm',
        onClick: handleRestartTreatment,
        isLoading: loading || addDiscountLoading,
      }}
      isOpen={show}
      onClose={close}
      title="Confirm shipment"
    >
      <div className="space-y-4">
        <ProductList
          treatment={treatment}
          consultation={consultation}
          otcProducts={getActiveOtcScheduleProducts(treatment)}
        />
        {showDiscountFields ? (
          <>
            <Divider palette="alternate" />
            <RemovableDiscountCodeForm
              code={validDiscountCode}
              stage={'ORDER_PAID'}
              onChange={setValidDiscountCode}
              onRemoveDiscount={handleRemoveDiscount}
              products={getAllProductsFromTreatment(treatment)}
              subtotal={chargeAmount}
              defaultCodeToApply={persistedDiscountCode}
            />
            <Divider palette="alternate" />
            <div className="space-y-2 pb-4">
              <DiscountSubtotal
                discountCode={validDiscountCode}
                chargeAmount={chargeAmount}
                products={getAllProductsFromTreatment(treatment)}
              />
            </div>
          </>
        ) : (
          <Typography size="medium-paragraph">
            You will be charged{' '}
            <strong>{formatCentsToCurrency(chargeAmount)}.</strong>
          </Typography>
        )}
      </div>
    </ActionModal>
  );
};
