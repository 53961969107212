import type {
  Maybe,
  TreatmentDetailsTreatmentFragment,
} from '@customer-frontend/graphql-types';
import {
  getActiveOtcScheduleProducts,
  OrderDetails,
} from '@customer-frontend/order';
import { gql } from '@apollo/client';
import { LoadingSpinner } from '@eucalyptusvc/design-system';

export type UseCustomTreatmentDetails = (
  treatment: TreatmentDetailsTreatmentFragment,
  showNextOrderDate?: boolean,
  showFullProductList?: boolean,
) => {
  treatmentName?: string;
  nextPayment?: Maybe<string>;
  refillsLeft?: Maybe<number>;
  orderItems: { name: string }[];
  loading: boolean;
};

type TreatmentDetailsProps = {
  treatment: TreatmentDetailsTreatmentFragment;
  status: string;
  showNextOrderDate?: boolean;
  showFullProductList?: boolean;
  treatmentDescription?: string | null;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
};

export function TreatmentDetails({
  treatment,
  status,
  showNextOrderDate,
  showFullProductList = false,
  treatmentDescription,
  useCustomTreatmentDetails,
}: TreatmentDetailsProps): React.ReactElement {
  const { loading, ...customOrderDetailsProps } =
    useCustomTreatmentDetails?.(
      treatment,
      showNextOrderDate,
      showFullProductList,
    ) ?? {};

  const treatmentName =
    treatment.product?.friendlyName ?? treatment.product.name;

  const orderDetailsProps = {
    treatmentName,
    nextPayment: showNextOrderDate ? treatment.nextPayment : undefined,
    refillsLeft: treatment.refillsLeft,
    orderItems: showFullProductList
      ? [{ name: treatmentName }, ...getActiveOtcScheduleProducts(treatment)]
      : [],
    ...customOrderDetailsProps,
  };

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <OrderDetails
      status={status}
      treatmentDescription={treatmentDescription}
      {...orderDetailsProps}
    />
  );
}

TreatmentDetails.fragment = gql`
  fragment TreatmentDetailsTreatment on Treatment {
    id
    refillsLeft
    nextPayment
    product {
      id
      name
      friendlyName
    }
    otcSchedules {
      id
      cadence
      quantity
      product {
        id
        name
        friendlyName
      }
      isActive
    }
  }
`;
