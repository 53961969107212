import { ReactElement } from 'react';
import { progressTrackerRoutes, routes } from 'utils/routes';
import { useHistory } from 'react-router-dom';
import { getLocalStorage, getWeeksBetweenDates } from '../utils';
import ProgressFeed from '../progress-feed';
import { LOCAL_STORAGE_COMPARE_DONE } from '../compare/utils';
import { useStartQuizApplication } from '@customer-frontend/services';
import {
  Card,
  Button,
  Typography,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';
import { colors } from '@eucalyptusvc/design-system/src/theme/software/palette';
import {
  ProgressEntry,
  UserProfileWithConsultationsQuery,
  UserProfileWithConsultationsQueryVariables,
} from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';
import { gql, useQuery } from '@apollo/client';

const ProgressSummary = ({
  progressEntries,
}: {
  progressEntries: ProgressEntry[];
}): ReactElement => {
  const history = useHistory();
  const startQuizApplication = useStartQuizApplication();
  const { loading, data } = useQuery<
    UserProfileWithConsultationsQuery,
    UserProfileWithConsultationsQueryVariables
  >(
    gql`
      query UserProfileWithConsultations {
        profile {
          id
          firstName
          consultations(orderBy: { createdAt: desc }) {
            id
            type
            createdAt
            completedAt
            treatment {
              id
              customerStatedTreatmentStartedAt
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
    },
  );

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const user = data?.profile;
  const userConsultations = user?.consultations ?? [];
  const completedAt =
    [...userConsultations]
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .find((c) => isSkinProblemType(c.type))?.completedAt ?? new Date();
  const customerStatedTreatmentStartedAt = userConsultations.find(
    (c) => isSkinProblemType(c.type) && c.treatment,
  )?.treatment?.customerStatedTreatmentStartedAt;
  const treatmentStartDate = customerStatedTreatmentStartedAt || completedAt;
  const weeksOnSoftware = getWeeksBetweenDates(treatmentStartDate, new Date());

  const startSkinQuizCheckIn = async (): Promise<void> => {
    const skinTrackerQuizApplication = await startQuizApplication(
      'SKIN_GENERAL',
      'TRACKER',
    );

    history.push(
      routes.quiz.root(
        skinTrackerQuizApplication.id + '?utm_source=progress_feed',
      ),
    );
  };

  const handleAddProgress = (): void => {
    startSkinQuizCheckIn();
  };

  const handleCompareClick = (): void => {
    const hasCompletedCompare = !!getLocalStorage(LOCAL_STORAGE_COMPARE_DONE);
    const entryCount = progressEntries?.length || 0;

    const nextRouteExtension =
      hasCompletedCompare && entryCount > 1 ? '/before' : '';
    history.push(progressTrackerRoutes.compare + nextRouteExtension);
  };

  return (
    <>
      <Card>
        <Typography size="lg" isBold>
          {user?.firstName}&apos;s progress
        </Typography>
        <div className="flex flex-row items-center space-x-1 py-1 my-3">
          <div className="bg-primary-lighter rounded-full h-8 w-8 flex items-center justify-center border border-black">
            <Typography
              size="medium-paragraph"
              isBold
              color={colors.primary[500]}
            >
              {weeksOnSoftware}
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            weeks since using Software
          </Typography>
        </div>
        <div className="flex space-x-4">
          <Button
            eventElementName="progressSummaryPageAddProgressButton"
            isFullWidth
            onClick={handleAddProgress}
          >
            <span className="hidden md:inline-block">+</span>&nbsp;Add progress
          </Button>
          <Button
            eventElementName="progressSummaryPageCompareButton"
            isFullWidth
            level="secondary"
            onClick={handleCompareClick}
          >
            Compare&nbsp;
            <span className="hidden md:inline-block">progress</span>
          </Button>
        </div>
      </Card>
      <ProgressFeed
        entries={progressEntries}
        treatmentStartDate={treatmentStartDate}
      />
    </>
  );
};
export default ProgressSummary;
