import { type ReactElement } from 'react';
import {
  AwaitingSurveyCardScreeningQuizApplicationFragment,
  Maybe,
  ProblemType,
} from '@customer-frontend/graphql-types';
import { notificationService } from '@customer-frontend/notifications';
import { useStartQuizApplication } from '@customer-frontend/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useIHI } from '@customer-frontend/healthcare-collection';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { addRumError } from '@customer-frontend/real-user-monitoring';
import { useNotification } from '@eucalyptusvc/design-system';

type AwaitingSurveyCardProps = {
  consultationId: string;
  requiresPhoneCall: boolean;
  problemType: ProblemType;
  isSyncConsult: boolean;
  screeningQuizApplication?: Maybe<AwaitingSurveyCardScreeningQuizApplicationFragment>;
  quizApplicationSubmitted: boolean;
  routes: {
    quiz: {
      root: (id: string) => string;
    };
    consultation: {
      collectAddress: (id: string) => string;
      phoneCall: (id: string) => string;
    };
  };
};

export function AwaitingSurveyCard({
  consultationId,
  problemType,
  isSyncConsult,
  screeningQuizApplication,
  quizApplicationSubmitted,
  routes,
  requiresPhoneCall,
}: AwaitingSurveyCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const { canRouteToMedicare, hasResidentialAddress } = useIHI(problemType);
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const startQuizApplication = useStartQuizApplication();

  if (isSyncConsult && (screeningQuizApplication || quizApplicationSubmitted)) {
    return (
      <ConsultationCardContentView
        paragraphs={[
          {
            key: 'initialAwaitingSurveyTextQuiz',
            text: (
              <FormattedMessage
                defaultMessage="Continue with your consult so your {isPrescriber, select, true {prescriber} other {practitioner}} can determine if our program is suitable for you."
                description="Description informing users to finish their consult on profile"
                values={{
                  isPrescriber:
                    config.prescriberType === PrescriberType.PRESCRIBER,
                }}
              />
            ),
          },
        ]}
        buttons={[
          {
            key: 'initialAwaitingSurveyButton',
            text: (
              <FormattedMessage
                defaultMessage="Continue consult"
                description="Button that navigates to the quiz on profile"
              />
            ),
            onClick: () => {
              if (
                screeningQuizApplication &&
                !screeningQuizApplication.submittedAt
              ) {
                return history.push(
                  routes.quiz.root(screeningQuizApplication.id),
                );
              }
              if (canRouteToMedicare || !hasResidentialAddress) {
                return history.push(
                  routes.consultation.collectAddress(consultationId),
                );
              }
              if (requiresPhoneCall) {
                return history.push(
                  routes.consultation.phoneCall(consultationId),
                );
              }
              addRumError(
                'Awaiting survey continue: failed to redirect user, missing supported route',
              );
              notify.error({
                message: formatMessage({
                  defaultMessage:
                    'Something went wrong, please reach out to customer support',
                }),
              });
            },
          },
        ]}
      />
    );
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'initialAwaitingSurveyTextQuiz',
          text: (
            <FormattedMessage
              defaultMessage="Looks like you've almost finished the quiz. Just a few more questions!"
              description="Description informing users to finish a few more questions on their quiz"
            />
          ),
        },
        {
          key: 'initialAwaitingSurveyTextPrescriber',
          text: (
            <FormattedMessage
              defaultMessage="A {isPrescriber, select, true {prescriber} other {practitioner}} will then assess your symptoms and prescribe you a treatment plan if they determine {brandTitle} is the right fit for you."
              description="Description informing users about the consult experience & expectations once they complete a quiz"
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
                brandTitle: config.title,
              }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: 'initialAwaitingSurveyButton',
          text: (
            <FormattedMessage
              defaultMessage="Continue quiz"
              description="Button that navigates to the quiz"
            />
          ),
          onClick: async (): Promise<void> => {
            if (
              screeningQuizApplication &&
              !screeningQuizApplication.submittedAt
            ) {
              return history.push(
                routes.quiz.root(screeningQuizApplication.id),
              );
            }

            let quizApplicationInfo;
            try {
              quizApplicationInfo = await startQuizApplication(problemType);
            } catch (e) {
              return notificationService.show({
                type: 'error',
                message: 'Failed to start quiz',
              });
            }

            const quizApplicationId =
              quizApplicationInfo?.id ?? screeningQuizApplication?.id ?? '';
            return history.push(routes.quiz.root(quizApplicationId));
          },
        },
      ]}
    />
  );
}

AwaitingSurveyCard.fragment = gql`
  fragment AwaitingSurveyCardScreeningQuizApplication on QuizApplication {
    id
    submittedAt
  }
`;
