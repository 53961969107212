export enum Variant {
  TreatmentPage,
  ConsultationPaidPage,
}

export interface UseSkinTrackerVariantInput {
  variant: Variant;
  progressEntries: { date: Date }[];
  treatmentStartDate?: Date;
}

export interface UseSkinTrackerVariantResult {
  title: string;
  photoTitles: string[];
  previewPhotos: string[];
  description: React.ReactElement | null;
  checklist: React.ReactElement | null;
  showHelperModal?: boolean;
  startSkinQuiz: () => Promise<void>;
}
