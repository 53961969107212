import React from 'react';
import { Typography } from '@eucalyptusvc/design-system';
import { StatusLayout } from './components';
import { StatusCardContentProps } from './types';
import { environment } from 'utils/environment';

export const CanceledCardContent = ({
  consultation,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  return (
    <StatusLayout {...layoutProps} testId="awaiting-survey-consultation-card">
      <Typography size="medium-paragraph">
        Your treatment plan has been cancelled. If you believe this is a mistake
        or have any questions, please{' '}
        <a
          href={`${environment.landingPageUrl}/contact-us`}
          className="text-link"
        >
          contact our support team.
        </a>
      </Typography>
    </StatusLayout>
  );
};
