import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '@customer-frontend/auth';
import { getCurrentParamsForRedirect, routes } from '../../utils/routes';

function PrivateRoute({ children, ...rest }: RouteProps): ReactElement {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(): React.ReactNode =>
        auth.isLoggedIn ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              search: getCurrentParamsForRedirect(),
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
