import React, { PropsWithChildren, ReactElement } from 'react';

export const ProfileLayout = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  return (
    <section className="max-w-2xl py-8 px-4 mx-auto overflow-hidden sm:overflow-visible">
      {children}
    </section>
  );
};
