import {
  RequestReviewTreatmentMutation,
  RequestReviewTreatmentMutationOptions,
  RequestReviewTreatmentMutationVariables,
} from '@customer-frontend/graphql-types';
import { MutationTuple, useMutation, gql } from '@apollo/client';

export function useRequestReviewTreatment(
  options?: RequestReviewTreatmentMutationOptions,
): MutationTuple<
  RequestReviewTreatmentMutation,
  RequestReviewTreatmentMutationVariables
> {
  return useMutation<
    RequestReviewTreatmentMutation,
    RequestReviewTreatmentMutationVariables
  >(
    gql`
      mutation RequestReviewTreatment($input: RequestReviewTreatmentInput!) {
        requestReviewTreatment(input: $input) {
          id
          status
        }
      }
    `,
    options,
  );
}
