import { ProblemType } from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';

export const primaryNavigationRoutes = {
  home: '/home',
  treatment: '/treatment',
  discover: '/discover',
  support: '/support',
  account: '/account',
};

export const treatmentRoutes = {
  plan: '/treatment/:treatmentId/plan',
  notes: '/treatment/:treatmentId/notes',
};

export const consultationRoutes = {
  review: '/consultation/:consultationId/follow-up',
};

export const progressTrackerRoutes = {
  root: '/progress',
  tracker: `/progress/tracker`,
  compare: `/progress/compare`,
};

export const learnRoutes = {
  root: '/learn',
  article: (id: string) => `/learn/${id}`,
};

export const routes = {
  profile: '/profile',
  forgotPassword: '/forgot-password',
  signup: '/signup',
  login: '/login',
  reset: '/reset',
  start: '/start',
  startWelcome: '/start/welcome',
  startSignup: '/start/signup',
  startConfirm: '/start/confirm',
  startProblemType: '/start/problem-type',
  startEnterFirstName: '/start/first-name',
  startEnterLastName: '/start/last-name',
  continueToProfile: '/start/continue',
  checkIn: '/check-in',
  quiz: {
    root: (id: string): string => `/quiz/${id}`,
    summary: (id: string): string => `/quiz/${id}/summary`,
    general: {
      start: (quizCode: string): string => `/quiz/start/${quizCode}`,
      complete: (quizCode: string): string => `/quiz/complete/${quizCode}`,
    },
  },
  consultation: {
    createAccount: (id: string): string => `/consultation/${id}/create-account`,
    consultationProfile: (id: string): string =>
      `/consultation/${id}/consultationProfile`,
    payment: (id: string): string => `/consultation/${id}/payment`,
    plan: (id: string): string => `/consultation/${id}/plan`,
    doctorsNote: (id: string): string => `/consultation/${id}/doctors-note`,
    pharmacyInformation: (id: string): string =>
      `/consultation/${id}/pharmacyInformation`,
    regimen: (id: string): string => `/consultation/${id}/regimen`,
    shipping: (id: string): string => `/consultation/${id}/shipping`,
    review: (id: string): string => `/consultation/${id}/review`,
    reason: (id: string): string => `/consultation/${id}/reason`,
    followUp: (id: string): string => `/consultation/${id}/follow-up`,
    collectMedicare: (id: string): string =>
      `/consultation/${id}/collect-medicare`,
    collectAddress: (id: string): string =>
      `/consultation/${id}/collect-address`,
    consultPay: (id: string): string => `/consultation/${id}/consult-pay`,
    confirmation: (id: string): string => `/consultation/${id}/confirmation`,
    confirmPathology: (id: string): string =>
      `/consultation/${id}/confirm-pathology`,
    phoneCall: (id: string): string => `/consultation/${id}/phone-call`,
    scheduleCall: (id: string): string => `/consultation/${id}/schedule-call`,
    chat: (id: string, loadAtTop?: boolean): string =>
      `/consultation/${id}/chat${loadAtTop ? '?load-at-top=true' : ''}`,
    phoneCallConfirmed: (id: string): string => `/call/${id}/confirmation`,
  },
  treatment: {
    notes: (id: string): string => `/treatment/${id}/notes`,
    plan: (id: string): string => `/treatment/${id}/plan`,
  },
};

export const webflowRoutes = {
  privacyPolicy: '/privacy',
  termsAndConditions: '/terms-and-conditions',
};

export function getCurrentParamsForRedirect(): string {
  const { pathname, search, hash } = window.location;
  const target = `${pathname}${search}${hash}`;

  return `?redirect=${encodeURIComponent(target)}`;
}

export const getConsultationPaymentRoute = (
  consultationId: string,
  problemType?: ProblemType,
): string => {
  if (isSkinProblemType(problemType)) {
    return routes.consultation.consultationProfile(consultationId);
  }

  return routes.consultation.payment(consultationId);
};
