import {
  getAnswerArrayFromShortCode,
  getAnswerFromShortCode,
} from 'utils/misc';
import { differenceInYears } from 'date-fns';
import {
  Ingredient,
  MappedSkinGoal,
  UserSkinProfile,
  BeforeAndAfter,
  Sex,
  SkinType,
  SensitiveSkin,
  IngredientDescription,
  IngredientName,
} from './types';
import {
  niacinamide,
  hyaluronicAcid,
  retinoid,
  azelaicAcid,
  beforeAndAfter,
  azelaicAcidExtended,
  retinoidExtended,
  niacinamideExtended,
  hyaluronicAcidExtended,
} from './constants';

function getAge(dob: Date): number {
  return differenceInYears(new Date(), dob);
}

enum SkinGoalId {
  pimple = 'pimple',
  redness = 'redness',
  pigment = 'pigment',
  acne = 'acne',
  blackhead = 'blackhead',
  wrinkle = 'wrinkle',
  brighten = 'brighten',
  dry = 'dry skin',
}

// The main function that return the UserSkinProfile
export function getPersonalisationInput(
  answers?:
    | {
        answer?: string | null;
        answersArray: string[];
        question?: { shortcode?: string | null } | null;
      }[]
    | null,
  suggestTretWherePossible?: boolean,
): UserSkinProfile {
  const sex = getAnswerFromShortCode('sex', answers);
  const dob = getAnswerFromShortCode('dob', answers);
  const age = getAge(new Date(dob));
  const skinGoals = getAnswerArrayFromShortCode('skinGoals', answers);
  const primarySkinGoal = getAnswerFromShortCode('primarySkinGoal', answers);
  const pregnantStatus = getAnswerFromShortCode('conditions', answers);
  const skinType = getAnswerFromShortCode('skinType', answers);
  const sensitiveSkin = getAnswerFromShortCode('sensitiveSkin', answers);
  const pregnancy = getPregnancyStatus(pregnantStatus);
  const skinGoalIds = mapSkinAnswerToSkinGoalId(skinGoals);
  const ingredients = suggestTretWherePossible
    ? getRecommendedFormulaTretWherePossibleExperiment(pregnancy)
    : getRecommendedFormula(skinGoalIds, pregnancy);

  const mappedSkinGoals = getMappedSkinGoals(skinGoalIds); // using the simple skin goals
  const primaryGoalIds = mapSkinAnswerToSkinGoalId([primarySkinGoal]);

  const sortedMergedGoals = Array.from(mappedSkinGoals).sort((a) => {
    return a.name === primaryGoalIds[0] ? -1 : 1; // Puts primary goal first in list
  });

  const shouldBeDash = (answer: string): boolean =>
    !answer || answer.trim() === "I don't know";
  const mapSex = (sex: string): Sex => {
    switch (sex) {
      case 'Female':
        return 'F';
      case 'Male':
        return 'M';
      default:
        return '-';
    }
  };
  const skinProfile: UserSkinProfile = {
    ingredients,
    skinGoals,
    mappedSkinGoals: sortedMergedGoals,
    primarySkinGoal: primarySkinGoal ? sortedMergedGoals[0] : undefined,
    skinType: (shouldBeDash(skinType) ? '-' : skinType) as SkinType,
    sensitiveSkin: (shouldBeDash(sensitiveSkin)
      ? '-'
      : sensitiveSkin) as SensitiveSkin,
    sex: mapSex(sex),
    age,
    beforeAndAfters: getBeforeAndAfter(mappedSkinGoals),
  };

  return skinProfile;
}

// function to centralise the goal even if the answer copy is changed.
function mapSkinAnswerToSkinGoalId(skinAnswers: string[]): string[] {
  const goals: string[] = Object.values(SkinGoalId);
  const answers: string[] = [];
  skinAnswers.forEach((ans) => {
    const newgoal = goals.find((goal) =>
      ans.toLowerCase().includes(goal.toLowerCase()),
    );
    if (newgoal) {
      answers.push(newgoal);
    }
  });
  return answers;
}

// skin goals shown to the user, takes in the baseSkinGoalType
function getMappedSkinGoals(skinGoals: string[]): MappedSkinGoal[] {
  const skinGoalsArr: MappedSkinGoal[] = [];
  if (
    skinGoals.includes(SkinGoalId.pimple) ||
    skinGoals.includes(SkinGoalId.blackhead)
  ) {
    skinGoalsArr.push({
      name: 'pimples',
      color: 'bg-sticker-red',
    });
  }
  if (
    skinGoals.includes(SkinGoalId.acne) ||
    skinGoals.includes(SkinGoalId.pigment)
  ) {
    skinGoalsArr.push({
      name: 'pigmentation',
      color: 'bg-sticker-yellow',
    });
  }
  if (skinGoals.includes(SkinGoalId.dry)) {
    skinGoalsArr.push({
      name: 'dry skin',
      color: 'bg-sticker-blue',
    });
  }
  if (skinGoals.includes(SkinGoalId.brighten)) {
    skinGoalsArr.push({
      name: 'dullness',
      color: 'bg-sticker-blue',
    });
  }
  if (skinGoals.includes(SkinGoalId.wrinkle)) {
    skinGoalsArr.push({
      name: 'fine lines',
      color: 'bg-sticker-green',
    });
  }
  if (skinGoals.includes(SkinGoalId.redness)) {
    skinGoalsArr.push({
      name: SkinGoalId.redness,
      color: 'bg-sticker-purple',
    });
  }
  return skinGoalsArr;
}
function getPregnancyStatus(pregnancyStatus: string): string {
  if (pregnancyStatus.includes('breastfeeding')) {
    return 'breastfeeding';
  } else if (pregnancyStatus.includes('pregnant')) {
    return 'pregnant';
  } else if (pregnancyStatus.includes('trying')) {
    return 'trying';
  }
  return 'not trying';
}

function getBeforeAndAfter(skinGoals: MappedSkinGoal[]): BeforeAndAfter[] {
  const personalisedBeforeAndAfter = beforeAndAfter.filter((ba) => {
    const goals = ba.goals.map((goal) => goal.name);
    return goals.some((goal) => skinGoals.map((sg) => sg.name).includes(goal));
  });
  const filteredBeforeAndAfter = filterBeforeAndAfterSkinGoals(
    personalisedBeforeAndAfter,
    skinGoals,
  );
  return shuffleArray(filteredBeforeAndAfter);
}

function filterBeforeAndAfterSkinGoals(
  beforeAndAfters: BeforeAndAfter[],
  skinGoals: MappedSkinGoal[],
): BeforeAndAfter[] {
  const newBA: BeforeAndAfter[] = [];
  beforeAndAfters.forEach((ba) => {
    const curr = ba;
    const currGoals: MappedSkinGoal[] = []; // new goals.
    ba.goals.forEach((goal) => {
      skinGoals.forEach((sg) => {
        if (goal.name.includes(sg.name)) {
          currGoals.push(goal);
        }
      });
    });
    curr.goals = currGoals;
    newBA.push(curr);
  });
  return newBA;
}

// shuffle the before and after array so that it's not ordered by skinGoal
function shuffleArray(inputArray: BeforeAndAfter[]): BeforeAndAfter[] {
  const theArray = inputArray;
  for (let i = theArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = theArray[i];
    theArray[i] = theArray[j];
    theArray[j] = temp;
  }
  return theArray;
}

function suitableForTretinoin(pregnancy: string): boolean {
  const inProcessOfPregnancy =
    pregnancy === 'breastfeeding' ||
    pregnancy === 'trying' ||
    pregnancy === 'pregnant';

  return !inProcessOfPregnancy;
}

function containsAzelaicAndTretinoin(
  skinGoals: string[],
  pregnancy: string,
): boolean {
  if (!suitableForTretinoin(pregnancy)) {
    return false;
  }
  if (
    skinGoals.includes(SkinGoalId.pigment) &&
    skinGoals.includes(SkinGoalId.pimple)
  ) {
    return true;
  }

  if (
    skinGoals.includes(SkinGoalId.acne) &&
    skinGoals.includes(SkinGoalId.pimple)
  ) {
    return true;
  }
  return false;
}

// if pregnant, only prescribe AZA.
function containsAzelaic(skinGoals: string[], pregnancy: string): boolean {
  if (skinGoals.length === 1 && skinGoals.includes(SkinGoalId.redness)) {
    return true;
  }
  if (
    pregnancy === 'breastfeeding' ||
    pregnancy === 'trying' ||
    pregnancy === 'pregnant'
  ) {
    return true;
  }
  if (skinGoals.includes(SkinGoalId.pigment)) {
    return true;
  }
  return false;
}

function getRecommendedFormulaTretWherePossibleExperiment(
  pregnancy: string,
): Ingredient[] {
  if (suitableForTretinoin(pregnancy)) {
    return [niacinamide, hyaluronicAcid, retinoid, azelaicAcid];
  } else {
    return [niacinamide, hyaluronicAcid, azelaicAcid];
  }
}

function getRecommendedFormula(
  skinGoals: string[],
  pregnancy: string,
): Ingredient[] {
  // all recommendations have niacinamide and HA base.
  const ing: Ingredient[] = [niacinamide, hyaluronicAcid];
  if (skinGoals.length === 0) {
    return ing;
  }

  if (containsAzelaicAndTretinoin(skinGoals, pregnancy)) {
    ing.push(retinoid, azelaicAcid);
  } else if (containsAzelaic(skinGoals, pregnancy)) {
    ing.push(azelaicAcid);
  } else if (suitableForTretinoin(pregnancy)) {
    ing.push(retinoid);
  }
  return ing;
}

const ingredientToExtendedMap: Record<IngredientName, IngredientDescription> = {
  'Azelaic Acid': azelaicAcidExtended,
  'Hyaluronic Acid': hyaluronicAcidExtended,
  Niacinamide: niacinamideExtended,
  Retinoid: retinoidExtended,
};

export const ingredientsToExtendedDescriptions = (
  ingredients: Ingredient[],
): IngredientDescription[] =>
  ingredients
    .map(
      (i): IngredientDescription | undefined => ingredientToExtendedMap[i.name],
    )
    .filter((i): i is IngredientDescription => i !== undefined);
