import { ProblemType } from '@customer-frontend/graphql-types';

const titles: Partial<Record<ProblemType, string>> = {
  ERECTILE: 'Erectile Dysfunction',
  HAIR: 'Hair Loss',
  MENTAL_HEALTH_GOOD: 'Mental Health',
  MENTAL_HEALTH_MEH: 'Mental Health',
  MENTAL_HEALTH_SAD: 'Mental Health',
  PREMATURE: 'Premature Ejaculation',
  SLEEP: 'Sleep',
  HERPES: 'Herpes',
  COLD_SORES: 'Cold Sores',
  ANTI_AGEING: 'Daily Routine',
  ACNE: 'Acne',
  STI: 'STI Test',
  GENETIC_TEST: 'Genetic Test',
  MENOPAUSE: 'Menopause',
  SKIN_GENERAL: 'Skin',
  BREASTFEEDING: 'Breatfeeding',
  CONTRACEPTION: 'Contraception',
  FERTILITY: 'Fertility',
  INFANT_SLEEP: 'Infant Sleep',
  DEPRECATED_OTC: 'Over the Counter',
  FERTILITY_HORMONE: 'Fertility Hormone',
  FERTILITY_NAVIGATOR: 'Fertility Navigator',
  FERTILITY_NAVIGATOR_PREGNANCY: 'Fertility Navigator Pregnancy',
  POSTPARTUM: 'Postpartum',
  POSTPARTUM_MENTAL_HEALTH: 'Postpartum Mental Health',
  POSTPARTUM_NUTRITION: 'Postpartum Nutrition',
  POSTPARTUM_PHYSIO: 'Postpartum Physio',
  PRECONCEPTION_FERTILITY: 'Preconception Fertility',
  PRECONCEPTION_MENTAL_HEALTH: 'Preconception Mental Health',
  PRECONCEPTION_NUTRITION: 'Preconception Nutrition',
  PRECONCEPTION_PHYSIO: 'Preconception Physio',
  PREGNANCY: 'Pregnancy',
  PREGNANCY_MENTAL_HEALTH: 'Pregnancy Mental Health',
  PREGNANCY_NUTRITION: 'Pregnancy Nutrition',
  PREGNANCY_PHYSIO: 'Pregnancy Physio',
  UTI: 'UTI',
  WEIGHT_LOSS: 'Weight Loss',
  WOMENS_HEALTH: "Women's Health",
};

type ShortHandTitles = {
  [k in ProblemType]?: string;
};

const shortHandTitles: ShortHandTitles = {
  ERECTILE: 'ED',
  PREMATURE: 'PE',
};

/**
 * @deprecated use useFormatProblemType instead
 */
export function getReadableProblemType(
  problemType: ProblemType,
  shortHand?: boolean,
): string | undefined {
  const short = shortHandTitles[problemType];
  if (shortHand && short) {
    return short;
  }
  return titles[problemType];
}

/**
 * @deprecated use useFormatInlineProblemType instead
 */
export function getInlineReadableProblemType(
  problemType?: ProblemType,
): string | undefined {
  const text = problemType && getReadableProblemType(problemType);

  return text?.toLowerCase();
}
