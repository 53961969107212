import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { routes } from 'utils/routes';
import { isCPRedesignEnabledForProblemType } from 'utils/misc';

export const AwaitingPaymentCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  return (
    <StatusLayout {...layoutProps} testId="awaiting-payment-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      <Typography size="medium-paragraph">
        <strong>Status:</strong> You’re almost there! Please proceed to payment
        so we can create your personalised treatment plan.
      </Typography>
      <Button
        eventElementName="treatmentPageAwaitingPaymentButton"
        onClick={() => {
          if (isCPRedesignEnabledForProblemType(layoutProps.problemType)) {
            history.push(routes.consultation.consultPay(consultation.id));
          } else {
            history.push(routes.consultation.payment(consultation.id));
          }
        }}
      >
        Go to payment
      </Button>
    </StatusLayout>
  );
};
