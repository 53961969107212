import {
  Consultation,
  Maybe,
  Variant,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { useEffect } from 'react';
import { routes } from 'utils/routes';
import { useHistory } from 'react-router-dom';
import { Variant as progressVariant } from 'components/progress-checklist/types';

export const getRxProductVariant = (
  consultation: Consultation | undefined,
): Maybe<Variant> => {
  return consultation?.treatment?.product?.variants?.find(
    (variant) => variant && !variant.isRefill,
  );
};

export const useCanSeeOrderScreen = (
  treatmentStatus: Maybe<TreatmentStatus>,
): void => {
  const history = useHistory();

  useEffect(() => {
    const planStatuses: TreatmentStatus[] = ['CREATED', 'FOLLOW_UP', 'REVIEW'];

    if (treatmentStatus && !planStatuses.includes(treatmentStatus)) {
      history.replace(routes.profile);
    }
  }, [treatmentStatus, history]);
};

export const getMedicareProgressNodeVariant = (
  medicareDetailsCollected: boolean,
): progressVariant => (medicareDetailsCollected ? 'completed' : 'incomplete');

export const getMedicareProgressNodeClassName = (
  medicareDetailsCollected: boolean,
): string =>
  medicareDetailsCollected ? 'text-gray-500 line-through' : 'font-semibold';

export const getMedicareProgressNodeText = (
  medicareDetailsCollected: boolean,
): string =>
  medicareDetailsCollected
    ? 'Medicare details completed'
    : "Let's confirm your Medicare details";
