import { getConfig } from '@customer-frontend/config';
import { TreatmentPausedCardTreatmentFragment } from '@customer-frontend/graphql-types';
import { calculateTreatmentCost } from '@customer-frontend/treatment';
import { Typography } from '@eucalyptusvc/design-system';
import { RestartTreatmentModal } from '../../../restart-treatment-modal';
import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { ConsultationCardProps } from '../types';
import { RefetchQueriesInclude, gql } from '@apollo/client';

type TreatmentPausedCardProps = {
  treatment: TreatmentPausedCardTreatmentFragment;
  refetchQueries?: RefetchQueriesInclude;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
};

export function TreatmentPausedCard({
  treatment,
  refetchQueries,
  useCustomTreatmentDetails,
}: TreatmentPausedCardProps): ReactElement {
  const { formatMessage } = useIntl();
  const config = getConfig();
  const [showModal, setShowModal] = useState(false);

  const buttons: ConsultationCardProps['buttons'] = [
    {
      key: 'treatmentPausedCardButton',
      text: (
        <FormattedMessage
          defaultMessage="Resume treatment"
          description="Button on the profile to resume a paused treatment"
        />
      ),
      onClick: () => {
        setShowModal(true);
      },
      level: 'secondary' as const,
      isDisabled: !treatment.canRefill,
    },
  ];

  return (
    <>
      <ConsultationCardContentView
        paragraphs={[
          {
            key: 'treatmentPausedCardText',
            text: (
              <FormattedMessage
                defaultMessage="You've currently paused your treatment."
                description="Text informing users the status of their treatment"
              />
            ),
          },
        ]}
        buttons={buttons}
      >
        <RestartTreatmentModal
          isOpen={showModal}
          treatment={treatment}
          onClose={() => setShowModal(false)}
          chargeAmount={calculateTreatmentCost(treatment)}
          refetchQueries={refetchQueries}
        />
        <TreatmentDetails
          treatment={treatment}
          status={formatMessage({ defaultMessage: 'Paused' })}
          useCustomTreatmentDetails={useCustomTreatmentDetails}
        />
      </ConsultationCardContentView>
      {!treatment.canRefill && (
        <div className="mt-3">
          <Typography size="small-text">
            <FormattedMessage
              defaultMessage="Unfortunately, you can’t resume your treatment right now. If you would like to restart your treatment, please submit a request <a>here</a>."
              description="Text informing users that they cannot resume their treatment"
              values={{
                a: (chunks) => (
                  <a href={config.zendeskRequestUrl} className="text-link">
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </div>
      )}
    </>
  );
}

TreatmentPausedCard.fragment = gql`
  fragment TreatmentPausedCardTreatment on Treatment {
    id
    canRefill
    nextPaymentAmount
    plan {
      id
      amount
    }
    otcSchedules {
      id
      isActive
      quantity
      product {
        id
        variants {
          id
          price
        }
      }
    }
    ...TreatmentDetailsTreatment
  }

  ${TreatmentDetails.fragment}
`;
