import { Button, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatFullDate } from 'utils/misc';
import { routes } from 'utils/routes';
import { SkinGoalsSummary, StatusLayout } from './components';
import { CreatedTreatmentCardContent } from './created-treatment';
import { StatusCardContentProps } from './types';
import { getConsultationStatusContent } from './utils/consultation-status';
import { ViewPathologyReferral } from './view-pathology-referral';

export const FollowUpTreatmentCardContent = (
  props: StatusCardContentProps,
): React.ReactElement => {
  const history = useHistory();

  if (
    props.consultation.status === 'DOCTOR_COMPLETED' &&
    props.consultation.isApproved
  ) {
    return <CreatedTreatmentCardContent {...props} />;
  }

  if (props.consultation.status !== 'AWAITING_SURVEY') {
    const consultationContent = getConsultationStatusContent(
      props.consultation,
    );

    if (!consultationContent) {
      return <></>;
    }

    const { component: ConsultationContent, ...consultProps } =
      consultationContent;

    return <ConsultationContent {...props} {...consultProps} />;
  }

  return (
    <StatusLayout {...props} testId="followup-consultation-card">
      <div className="space-y-2">
        <SkinGoalsSummary
          skinGoals={props.personalisationInput.mappedSkinGoals}
        />
        <Typography size="medium-paragraph">
          <strong>Your treatment: </strong>
          {props.consultation.treatment?.product.name}
        </Typography>
        <Typography size="medium-paragraph">
          <strong>Repeats left: </strong>
          {props.consultation.treatment?.refillsLeft}
        </Typography>
        {props.consultation.treatment?.isLastOrderUnfulfilled && (
          <Typography size="medium-paragraph">
            <strong>Status: </strong>
            your order is being processed.
          </Typography>
        )}
        {!props.consultation.treatment?.isLastOrderUnfulfilled && (
          <>
            {!props.consultation.treatment?.pendingFollowUpReminderDate && (
              <Typography size="medium-paragraph">
                To continue with your treatment, complete a free follow up with
                one of our practitioners.
              </Typography>
            )}
            {props.consultation.treatment?.pendingFollowUpReminderDate && (
              <>
                <Typography size="medium-paragraph">
                  We have scheduled a follow up consultation when you are close
                  to finishing your treatment on the{' '}
                  <b>
                    {formatFullDate(
                      new Date(
                        props.consultation.treatment.pendingFollowUpReminderDate,
                      ),
                    )}
                  </b>
                  .
                </Typography>
                <Typography size="medium-paragraph">
                  However, if you are concerned about your treatment,{' '}
                  <Link
                    className="text-link"
                    to={routes.consultation.followUp(props.consultation.id)}
                  >
                    click here to start your follow up consult early
                  </Link>
                </Typography>
              </>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        {!props.consultation.treatment?.pendingFollowUpReminderDate && (
          <Button
            isFullWidth
            eventElementName="treatmentPageStartFollowUpButton"
            onClick={() => {
              history.push(routes.consultation.followUp(props.consultation.id));
            }}
          >
            Start follow up
          </Button>
        )}
        <ViewPathologyReferral consultation={props.consultation} />
      </div>
    </StatusLayout>
  );
};
