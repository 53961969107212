import { Button, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AwaitingAnswersCardContent } from './awaiting-answers';
import { CreatedTreatmentCardContent } from './created-treatment';
import { routes } from 'utils/routes';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { formatDoctorName, TitleType } from '@customer-frontend/doctor';
import { NurseInfo } from './nurse-info';
import { isNursePractitionerEnabled } from 'utils/misc';
import { useIntl } from 'react-intl';
import { isToday } from 'date-fns';

export const ReviewTreatmentContent = (
  props: StatusCardContentProps,
): React.ReactElement => {
  const history = useHistory();
  const { formatDate } = useIntl();

  const doctor = props.consultation.doctor;
  const latestPractitionerBooking =
    props.consultation.latestPractitionerBooking;

  let formattedPractitionerBookingString = null;
  if (latestPractitionerBooking) {
    const formattedStartTime = formatDate(
      latestPractitionerBooking.windowStartAt,
      { hour: 'numeric' },
    );
    const formattedEndTime = formatDate(latestPractitionerBooking.windowEndAt, {
      hour: 'numeric',
      timeZoneName: 'short',
    });
    const formattedDay = isToday(
      new Date(latestPractitionerBooking?.windowStartAt),
    )
      ? 'today'
      : formatDate(latestPractitionerBooking.windowStartAt, {
          weekday: 'long',
        });
    formattedPractitionerBookingString = `${formattedStartTime} - ${formattedEndTime} ${formattedDay}`;
  }

  if (
    props.consultation.status === 'DOCTOR_COMPLETED' &&
    props.consultation.isApproved
  ) {
    return <CreatedTreatmentCardContent {...props} />;
  }
  if (props.consultation.status === 'AWAITING_ANSWERS') {
    return <AwaitingAnswersCardContent {...props} />;
  }
  return (
    <StatusLayout {...props} testId="review-treatment-status-card">
      {props.consultation.status === 'AWAITING_SURVEY' && (
        <div className="space-y-4">
          <SkinGoalsSummary
            skinGoals={props.personalisationInput.mappedSkinGoals}
          />
          <Typography size="medium-paragraph">
            <strong>Treatment plan: </strong>
            {props.consultation.treatment?.product.name}
          </Typography>
          <Typography size="medium-paragraph">
            <strong>Repeats left: </strong>
            {props.consultation.treatment?.refillsLeft}
          </Typography>
          <Typography size="medium-paragraph">
            <strong>Status: </strong>
            In review
          </Typography>
          <Typography size="medium-paragraph">
            We&apos;ve created a consultation to review your treatment plan. To
            begin, you&apos;ll need to answer some questions for your
            practitioner.
          </Typography>
          <Button
            eventElementName="treatmentPageReviewTreatmentButton"
            onClick={() => {
              history.push(routes.consultation.followUp(props.consultation.id));
            }}
          >
            Review
          </Button>
        </div>
      )}
      {props.consultation.status === 'AWAITING_DOCTOR' && (
        <>
          <Typography size="medium-paragraph">
            Thank you, a practitioner will be reviewing your follow-up shortly.
          </Typography>
          {props.consultation.isInNoPickUpQueue &&
            !formattedPractitionerBookingString && (
              <Typography size="medium-paragraph">
                Your practitioner called but wasn’t able to reach you. Let’s
                make sure they call at a time that works for you.
              </Typography>
            )}
          {formattedPractitionerBookingString && (
            <Typography size="medium-paragraph">
              Your practitioner will call you between{' '}
              <strong>{formattedPractitionerBookingString}</strong>.
            </Typography>
          )}

          {props.consultation.allowPatientToSendMessageToDoctor &&
            props.consultation.chatThread?.id && (
              <>
                <Typography size="medium-paragraph">
                  While you’re waiting for your practitioner, feel free to leave
                  questions for them in the chat.
                </Typography>
                <Button
                  onClick={() => {
                    history.push(
                      routes.consultation.chat(props.consultation.id),
                    );
                  }}
                >
                  Open Chat
                </Button>
                {props.consultation.isInNoPickUpQueue ||
                  (formattedPractitionerBookingString && (
                    <Button
                      onClick={() => {
                        history.push(
                          routes.consultation.scheduleCall(
                            props.consultation.id,
                          ),
                        );
                      }}
                    >
                      Reschedule call
                    </Button>
                  ))}
              </>
            )}
        </>
      )}
      {props.consultation.status === 'DOCTOR_ASSIGNED' && (
        <div className="space-y-4">
          <Typography size="medium-paragraph">
            {`${
              doctor
                ? formatDoctorName(doctor, TitleType.DOCTOR_LAST_NAME)
                : 'Your practitioner'
            } is reviewing your information and will get back to you shortly.`}
          </Typography>
          {props.consultation.stage === 'FOLLOW_UP' &&
            isNursePractitionerEnabled(props.problemType) && <NurseInfo />}
          {props.consultation.allowPatientToSendMessageToDoctor &&
            (props.consultation.chatThread?.id ? (
              <Button
                onClick={() => {
                  history.push(routes.consultation.chat(props.consultation.id));
                }}
                isFullWidth
              >
                Open Chat
              </Button>
            ) : (
              <Button
                eventElementName="treatmentPageViewConsultButton"
                onClick={() => {
                  history.push(
                    routes.consultation.review(props.consultation.id),
                  );
                }}
                isFullWidth
              >
                View consult
              </Button>
            ))}
        </div>
      )}
    </StatusLayout>
  );
};
