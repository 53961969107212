import { TreatmentStatus } from '@customer-frontend/graphql-types';

export enum SkinOnboardingQuizCardPosition {
  ABOVE_TREATMENT,
  HIDDEN,
  BELOW_TREATMENT,
}

export const getSkinOnboardingQuizPosition = (
  treatmentStatus?: TreatmentStatus,
): SkinOnboardingQuizCardPosition => {
  if (!treatmentStatus) {
    return SkinOnboardingQuizCardPosition.HIDDEN;
  }
  switch (treatmentStatus) {
    case 'PROCESSING_ORDER':
    case 'ACTIVE':
      return SkinOnboardingQuizCardPosition.ABOVE_TREATMENT;
    case 'CREATED':
    case 'CANCELED':
      return SkinOnboardingQuizCardPosition.HIDDEN;
    default:
      return SkinOnboardingQuizCardPosition.BELOW_TREATMENT;
  }
};
