import {
  Button,
  LinkButton,
  ListButton,
  Modal,
  Typography,
  useNotification,
  useResponsive,
} from '@eucalyptusvc/design-system';
import React, { useState } from 'react';
import { Card as CardType, CardKey } from './use-profile-cards';
import { useAddUserToSegment, useInSegment } from '@customer-frontend/services';

import { RiCloseCircleFill } from 'react-icons/ri';
import verticalCarouselImage from '../../../assets/insiders-vertical-carousel.png';
import horizontalCarouselImage from '../../../assets/insiders-horizontal-carousel.png';
import blueBob from '../../../assets/blobs/blue-blob-static.png';
import { UIInteractionElementName } from '@customer-frontend/events';

const INSIDERS_FB_LINK = 'https://www.facebook.com/groups/skinsoftware';

const dismissReasons: {
  label: string;
  elementName: UIInteractionElementName;
}[] = [
  {
    label: 'I’ve joined the group',
    elementName: 'insidersDismissReasonAlreadyJoined',
  },
  {
    label: 'I don’t have Facebook',
    elementName: 'insidersDismissReasonDontHaveFacebook',
  },
  {
    label: 'I’m not interested',
    elementName: 'insidersDismissReasonNotInterested',
  },
];

const SoftwareInsidersCard = (): React.ReactElement | null => {
  const notify = useNotification();
  const { isMobile } = useResponsive();

  const [reasonModalOpen, setReasonModalOpen] = useState<boolean>(false);

  const {
    data: segmentData,
    loading: segmentDataLoading,
    refetch: refetchSegmentData,
  } = useInSegment({
    variables: {
      segmentName: 'SOFTWARE_INSIDERS_WIDGET_DISMISSED',
    },
  });

  const [addUserToSegment] = useAddUserToSegment({
    variables: {
      segmentName: 'SOFTWARE_INSIDERS_WIDGET_DISMISSED',
    },
  });

  const hasDismissed = segmentData?.inSegment;

  const handleJoinClick = (): void => {
    window.open(INSIDERS_FB_LINK, '_blank', 'noopener noreferrer');
  };

  const handleDismiss = async (): Promise<void> => {
    await addUserToSegment();
    await refetchSegmentData();

    notify.success({ message: 'Software Insiders removed' });
    setReasonModalOpen(false);
  };

  if (segmentDataLoading || hasDismissed) {
    return null;
  }

  return (
    <>
      <div className="relative">
        <RiCloseCircleFill
          className="w-8 h-8 absolute -top-2 -right-2 md:top-2 md:right-2 cursor-pointer z-10"
          onClick={() => setReasonModalOpen(true)}
        />
        <div className="rounded-xl bg-primary-200 overflow-hidden flex items-center flex-col md:flex-row relative">
          {isMobile ? (
            <div className="pt-4 flex-shrink-0 w-full">
              <div
                style={{
                  height: '120px',
                  width: 'calc(1286px * 2)', // width of image x2 for carousel
                  backgroundImage: `url(${horizontalCarouselImage})`,
                  backgroundSize: 'auto 100%',
                  backgroundRepeat: 'repeat-x',
                  backgroundPositionX: '0px',
                }}
                className="pt-2 animate-insiders-horizontal-carousel"
              />
            </div>
          ) : (
            <div className="px-8 flex-shrink-0 h-72">
              <div
                style={{
                  height: 'calc(592px * 2)', // height of image x2 for carousel
                  width: '248px',
                  backgroundImage: `url(${verticalCarouselImage})`,
                  backgroundSize: '100% auto',
                  backgroundRepeat: 'repeat-y',
                  backgroundPositionY: '0px',
                }}
                className="pt-2 animate-insiders-vertical-carousel"
              />
            </div>
          )}
          <div className="p-4 space-y-2 z-10">
            <Typography size="md" isBold>
              Become a Software Insider
            </Typography>
            <Typography size="medium-paragraph">
              Get access to unfiltered feedback, progress pictures and exclusive
              member discounts.
            </Typography>
            <div className="pt-2">
              <Button
                onClick={handleJoinClick}
                isFullWidth
                eventElementName="insidersJoinFacebookButton"
              >
                Join on Facebook
              </Button>
            </div>
          </div>
          <img
            src={blueBob}
            className="absolute -bottom-18 md:-bottom-14 -right-16 h-40 w-auto z-0"
          />
        </div>
      </div>
      <Modal
        title="Why don’t you want to see Software Insiders?"
        isOpen={reasonModalOpen}
        onClose={() => setReasonModalOpen(false)}
      >
        <div className="pt-2 space-y-2 flex flex-col items-center">
          {dismissReasons.map((reason) => (
            <ListButton
              key={reason.elementName}
              onClick={handleDismiss}
              eventElementName={reason.elementName}
            >
              {reason.label}
            </ListButton>
          ))}
          <div className="pt-4">
            <LinkButton
              onClick={() => setReasonModalOpen(false)}
              eventElementName="insidersDismissChangedMind"
            >
              Actually, I do want to see it
            </LinkButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const getSoftwareInsidersCard = ({
  key,
}: {
  key: CardKey;
}): CardType<Record<string, never>> => ({
  CardComponent: SoftwareInsidersCard,
  key,
  props: {},
});
