import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { getConfig } from '@customer-frontend/config';

export function TreatmentCancelledCard(): ReactElement {
  const config = getConfig();

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'treatmentCancelledText',
          text: (
            <FormattedMessage
              defaultMessage="Your treatment plan has been cancelled. If you believe this is a mistake or have any questions, please <a> contact our support team. </a>"
              description="Text explaining that the customers treatment has been canceled on the profile"
              values={{
                a: (chunks: React.ReactNode[]) => (
                  <a
                    href={config.zendeskRequestUrl}
                    className="text-link text-primary-400"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          ),
        },
      ]}
      buttons={[]}
    />
  );
}
