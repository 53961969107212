import React from 'react';
import { PurchaseProcessingCardPurchaseFragment } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardProps } from '../consultation/types';
import { ConsultationCardContentView } from '../consultation/components/consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { gql } from '@apollo/client';

type PurchaseProcessingCardProps = {
  purchase: PurchaseProcessingCardPurchaseFragment;
  routes: {
    offering: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  children: React.ReactNode;
};

export function PurchaseProcessingCard({
  purchase,
  routes,
  children,
}: PurchaseProcessingCardProps): React.ReactElement | null {
  const history = useHistory();
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');

  if (!purchase.offering) {
    return null;
  }

  const buttons: ConsultationCardProps['buttons'] = [
    {
      key: 'purchaseProcessingCardViewButton',
      text: (
        <FormattedMessage
          defaultMessage="View treatment plan"
          description="Button that navigates to the treatment plan details page"
        />
      ),
      onClick: () => {
        history.push(routes.offering.plan(purchase.id));
      },
      level: 'secondary' as const,
    },
  ];
  if (
    purchase.offering.problemTypes?.includes('WEIGHT_LOSS') &&
    !sunsetBpDashboard
  ) {
    buttons.unshift({
      key: 'purchaseProcessingCardWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: 'primary' as const,
    });
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'purchaseProcessingText',
          text: (
            <FormattedMessage
              defaultMessage="You will be notified when your order is on its way. Sit tight!"
              description="Description telling users that they will be notified of any updates to their order"
            />
          ),
        },
      ]}
      buttons={buttons}
    >
      {children}
    </ConsultationCardContentView>
  );
}

PurchaseProcessingCard.fragment = gql`
  fragment PurchaseProcessingCardPurchase on Purchase {
    id
    offering {
      id
      problemTypes
    }
  }
`;
