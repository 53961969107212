import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { MutationUpdatePasswordArgs } from '@customer-frontend/graphql-types';
import { useForm } from 'react-hook-form';
import {
  Card,
  Button,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  capitaliseString,
  minLengthValidation,
  requiredValidation,
} from '@customer-frontend/utils';

const updatePasswordDocument = gql`
  mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

type FormDetails = {
  currentPassword: string;
  newPassword: string;
};

export const SecurityLoginCard = (): React.ReactElement => {
  const notify = useNotification();
  const [isEditing, setIsEditing] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormDetails>();

  const [updatePassword, { loading }] = useMutation<
    {
      updatePassword: boolean;
    },
    MutationUpdatePasswordArgs
  >(updatePasswordDocument, {
    onCompleted: () => {
      notify.success({
        message: 'Password successfully updated',
      });
      setIsEditing(false);
    },
  });

  const submitForm = async (data: FormDetails): Promise<void> => {
    try {
      await updatePassword({
        variables: {
          newPassword: data.newPassword,
          currentPassword: data.currentPassword,
        },
      });
    } catch (err) {
      notify.error({
        message: capitaliseString(err.message),
      });
    }
  };

  return (
    <Card>
      <div className="space-y-6">
        <Typography size="md" isBold>
          Security and login
        </Typography>
        {!isEditing && (
          <Button
            eventElementName="accountPageChangePasswordButton"
            level="secondary"
            size="sm"
            onClick={() => setIsEditing(true)}
          >
            Change password
          </Button>
        )}
      </div>
      {isEditing && (
        <form className="space-y-2 mt-4" onSubmit={handleSubmit(submitForm)}>
          <TextInput
            ref={register(requiredValidation('Current Password'))}
            type="password"
            label="Current Password"
            name="currentPassword"
            errorMessage={errors?.currentPassword?.message}
          />
          <TextInput
            ref={register({
              ...requiredValidation('New Password'),
              ...minLengthValidation('New Password', 8),
            })}
            type="password"
            label="New Password"
            name="newPassword"
            errorMessage={errors?.newPassword?.message}
          />

          <div className="flex flex-col space-y-2 items-center md:w-56 md:flex-row md:space-x-2 md:space-y-0 pt-2">
            <Button
              eventElementName="accountPageChangePasswordSubmitButton"
              level="primary"
              isLoading={loading}
              isSubmit
              isFullWidth
            >
              Save
            </Button>
            <Button
              eventElementName="accountPageChangePasswordCancelButton"
              isFullWidth
              level="secondary"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Card>
  );
};
