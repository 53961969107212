import { ProblemType } from '@customer-frontend/graphql-types';
import { useStartQuizApplication } from '@customer-frontend/services';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';

export const useStartTrackerQuiz = (
  consultationId?: string,
): ((
  trackerQuizUtmSource: string,
  problemType: ProblemType,
) => Promise<void>) => {
  const history = useHistory();
  const startQuizApplication = useStartQuizApplication();
  return useCallback(
    async (
      trackerQuizUtmSource: string,
      problemType: ProblemType,
    ): Promise<void> => {
      const trackerQuizApplication = await startQuizApplication(
        problemType,
        'TRACKER',
      );
      const consultationParam = consultationId
        ? `&consultationId=${consultationId}`
        : '';
      history.push(
        routes.quiz.root(
          trackerQuizApplication.id +
            `?utm_source=${trackerQuizUtmSource}${consultationParam}`,
        ),
      );
    },
    [startQuizApplication, consultationId, history],
  );
};
