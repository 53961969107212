import { ProgressChecklistItem } from './progress-checklist-item';
import { ProgressChecklistProps } from './types';

export const ProgressChecklist: React.FunctionComponent<
  ProgressChecklistProps
> = ({ items }) => {
  return (
    <ul className="list-none">
      {items.map((item, i) => (
        <ProgressChecklistItem
          key={item.key}
          currentItem={item}
          nextItem={items[i + 1]}
          previousItem={items[i - 1]}
        >
          {item.node}
        </ProgressChecklistItem>
      ))}
    </ul>
  );
};
