import { Typography, useResponsive } from '@eucalyptusvc/design-system';
import { ReactElement } from 'react';
import { FaCamera } from 'react-icons/fa';
import { SkinProgressEntry } from './entry-photo';
import { ImageTitle } from './image-title';
import clsx from 'clsx';
import { PhotoAngleType } from '@customer-frontend/graphql-types';

export const ProgressPreview = ({
  progressEntries,
  photoTitles,
  startSkinQuiz,
  previewPhotos,
}: {
  progressEntries: {
    photos?:
      | ({ angle: PhotoAngleType; signedUrl: string } | undefined | null)[]
      | null;
  }[];
  photoTitles: string[];
  previewPhotos: string[];
  startSkinQuiz: () => Promise<void>;
}): ReactElement => {
  const numOfEntries = progressEntries.length;
  const firstEntry = progressEntries[numOfEntries - 1];
  const latestEntry = progressEntries[0];

  const { isMobile } = useResponsive();

  return (
    <div className="grid grid-cols-2 md:grid-cols-3">
      {numOfEntries === 0 ? (
        <div onClick={startSkinQuiz} className="cursor-pointer">
          <ImageTitle copy={photoTitles[0]} className="text-gray-500" />
          <PreviewImage src={previewPhotos[0]} />
        </div>
      ) : (
        <SkinProgressEntry entry={firstEntry} imageTitle={photoTitles[0]} />
      )}
      {numOfEntries < 2 ? (
        <div onClick={startSkinQuiz} className="cursor-pointer">
          <ImageTitle copy={photoTitles[1]} className="text-gray-500" />
          <PreviewImage src={previewPhotos[1]} />
        </div>
      ) : (
        <SkinProgressEntry entry={latestEntry} imageTitle={photoTitles[1]} />
      )}
      <div className="col-span-2 md:col-span-1">
        {photoTitles[2] && <ImageTitle copy={photoTitles[2]} />}
        <div
          className={clsx(
            'bg-beige-200 hover:bg-beige-300 border border-black flex rounded-lg mx-1 h-44 md:h-60 w-full md:w-auto cursor-pointer p-8',
            !photoTitles[2] && 'mt-2 md:mt-5',
          )}
          onClick={startSkinQuiz}
        >
          <div className="flex flex-col justify-center items-center text-center space-y-2 w-full">
            <FaCamera size="32px" />
            <Typography size="xs" isBold>
              {isMobile ? 'Tap' : 'Click'} to add&nbsp;progress
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewImage = ({ src }: { src: string }): ReactElement => {
  return (
    <div className="px-1 w-full">
      <img
        className="h-44 md:h-60 border-2 border-gray-300 border-dashed rounded-lg mx-auto"
        src={src}
      />
    </div>
  );
};
