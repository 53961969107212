import type {
  AwaitingResultsCardPathologyRequestFragment,
  Maybe,
} from '@customer-frontend/graphql-types';
import { type ReactElement } from 'react';
import {
  defineMessage,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { gql } from '@apollo/client';
import { PrescriberType } from '@customer-frontend/doctor';
import { getConfig } from '@customer-frontend/config';

const getContent = (
  hasTakenTest: boolean,
): { status: MessageDescriptor; description: ReactElement } => {
  const config = getConfig();

  if (hasTakenTest) {
    return {
      status: defineMessage({
        defaultMessage: 'Awaiting pathology results',
        description: 'Label showing user that we are waiting for their results',
      }),
      description: (
        <FormattedMessage
          defaultMessage="Your {isPrescriber, select, true {prescriber} other {practitioner}} will reach out to you in 2-3 business days with your results."
          description="Text informing users their results are 2-3 days away, and will be shared when ready"
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
    };
  }
  return {
    status: defineMessage({
      defaultMessage: 'Awaiting pathology test',
      description:
        'Label showing user that we are waiting for them to take their test',
    }),
    description: (
      <FormattedMessage
        defaultMessage="The next step is to go to a pathology clinic near you with a printed copy of the referral letter below to get your results."
        description="Instructional text informing users to print their referral and go get a pathology test"
      />
    ),
  };
};

type AwaitingResultsCardProps = {
  pathologyRequests?: Maybe<
    Maybe<AwaitingResultsCardPathologyRequestFragment>[]
  >;
};

export function AwaitingResultsCard({
  pathologyRequests,
}: AwaitingResultsCardProps): ReactElement {
  const { formatMessage } = useIntl();
  const pathologyRequest = pathologyRequests?.[0];
  const customerStatedCollectedAt = pathologyRequest?.customerStatedCollectedAt;
  const hasTakenTest = !!customerStatedCollectedAt;
  const { status, description } = getContent(hasTakenTest);
  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'awaitingResultsText',
          text: (
            <FormattedMessage
              defaultMessage="Status: <strong>{status}</strong>"
              description="Label for treatment status field"
              values={{
                status: formatMessage(status),
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          ),
        },
        {
          key: 'awaitingResultsDescription',
          text: description,
        },
      ]}
      buttons={[]}
    />
  );
}

AwaitingResultsCard.fragment = gql`
  fragment AwaitingResultsCardPathologyRequest on PathologyRequest {
    id
    customerStatedCollectedAt
  }
`;
