import {
  Maybe,
  OtcScheduleCadence,
  Treatment,
  useUpsertOtcProductToTreatmentMutation,
} from '@customer-frontend/graphql-types';
import { useRemoveOtcFromTreatmentMutation } from '@customer-frontend/services';
import { useNotification } from '@eucalyptusvc/design-system';
import { useState, useCallback } from 'react';

type Props = {
  treatment: Maybe<Treatment>;
  onComplete?: () => Promise<unknown>;
};

type ReturnType = {
  productIdLoading: string | null;
  addProduct: (
    productId: string,
    cadence: OtcScheduleCadence,
    quantity?: number,
    name?: string,
  ) => Promise<void>;
  removeProduct: (productId: string) => Promise<void>;
};

/**
 * Hook for managing OTC's once a treatment is active. This will update your treatment automatically.
 *
 * If you're looking for a hook to only update your treatment once confirmed, please reference `useCart`
 */
export const useOtcAttachmentManagement = ({
  treatment,
  onComplete = () => Promise.resolve(),
}: Props): ReturnType => {
  const notify = useNotification();
  const [productIdLoading, setProductIdLoading] = useState<string | null>(null);

  const [upsertOtcToTreatment] = useUpsertOtcProductToTreatmentMutation();
  const [removeOtcProductFromTreatment] = useRemoveOtcFromTreatmentMutation();

  const handleAddToCart = useCallback(
    async (
      productId: string,
      cadence: OtcScheduleCadence,
      quantity?: number,
      name?: string,
    ): Promise<void> => {
      if (!treatment) {
        return;
      }

      setProductIdLoading(productId);
      try {
        await upsertOtcToTreatment({
          variables: {
            productId,
            quantity: quantity ?? 1,
            id: treatment.id,
            source: 'repeat',
            cadence,
          },
        });
        await onComplete();
        notify.success({
          message: `${name ? `${name} added` : 'Added'} to scheduled order`,
        });
      } catch (error) {
        notify.error({
          message: "Couldn't add product, please try again",
        });
      } finally {
        setProductIdLoading(null);
      }
    },
    [notify, onComplete, treatment, upsertOtcToTreatment],
  );

  const handleRemoveFromCart = useCallback(
    async (productId: string): Promise<void> => {
      if (!treatment) {
        return;
      }

      setProductIdLoading(productId);
      try {
        await removeOtcProductFromTreatment({
          variables: {
            id: treatment.id,
            source: 'repeat',
            productId: productId,
          },
        });
        await onComplete();
        notify.success({
          message: 'Product removed from your scheduled order',
        });
      } catch (error) {
        notify.error({
          message: "Couldn't remove product, please try again",
        });
      } finally {
        setProductIdLoading(null);
      }
    },
    [notify, onComplete, treatment, removeOtcProductFromTreatment],
  );

  return {
    addProduct: handleAddToCart,
    removeProduct: handleRemoveFromCart,
    productIdLoading: productIdLoading,
  };
};
