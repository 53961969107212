import { gql, useMutation } from '@apollo/client';
import { IntlAddressInput } from '@customer-frontend/intl';
import {
  User,
  Maybe,
  Address,
  MutationUpdateShippingArgs,
} from '@customer-frontend/graphql-types';
import {
  Card,
  Button,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { capitaliseString } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';

const updateShippingDocument = gql`
  mutation UpdateShipping($address: AddressCreateWithoutUserInput!) {
    updateShipping(address: $address) {
      id
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        building
        company
      }
    }
  }
`;

type FormDetails = {
  address: {
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    company: string;
    building: string;
  };
};

export const ShippingDetailsCard = ({
  address,
  onUpdated,
}: {
  address: Maybe<Address>;
  onUpdated?: () => void;
}): React.ReactElement => {
  const notify = useNotification();
  const config = getConfig();
  const [isEditing, setIsEditing] = useState(!address || !address?.line1);

  const { register, handleSubmit, errors, setValue } = useForm<FormDetails>();

  const [updateShipping, { loading }] = useMutation<
    {
      updateShipping: User;
    },
    MutationUpdateShippingArgs
  >(updateShippingDocument, {
    onCompleted: () => {
      setIsEditing(false);
      onUpdated?.();
    },
  });

  useEffect(() => {
    setValue('address', {
      line1: address?.line1,
      line2: address?.line2,
      city: address?.city,
      postalCode: address?.postalCode,
      state: address?.state,
      country: config.country,
      company: address?.company,
      building: address?.building,
    });
  }, [setValue, address, isEditing, config.country]);

  const submitForm = async (data: FormDetails): Promise<void> => {
    try {
      await updateShipping({
        variables: {
          address: {
            line1: data.address.line1 || null,
            line2: data.address.line2 || null,
            city: data.address.city || null,
            postalCode: data.address.postalCode || null,
            state: data.address.state || null,
            company: data.address.company || null,
            building: data.address.building || null,
            country: data.address.country || config.country,
          },
        },
      });
    } catch (err) {
      notify.error({
        message: capitaliseString(err.message),
      });
    }
  };

  return (
    <Card>
      <div className="flex align-start justify-between mb-4">
        <Typography size="md" isBold>
          Shipping details
        </Typography>
        {!isEditing && (
          <Button
            eventElementName="accountPageShippingDetailsEditButton"
            size="sm"
            level="secondary"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </div>
      {isEditing ? (
        <form className="space-y-2" onSubmit={handleSubmit(submitForm)}>
          <IntlAddressInput
            name="address"
            registerField={register}
            errors={errors.address ?? {}}
            useAutoComplete
          />
          <div className="flex space-x-2">
            <div className="w-1/2">
              <TextInput
                ref={register}
                label="Company"
                name="address.company"
                errorMessage={errors?.address?.company?.message}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                ref={register}
                label="Building"
                name="address.building"
                errorMessage={errors?.address?.building?.message}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 items-center md:w-56 md:flex-row md:space-x-2 md:space-y-0 pt-2">
            <Button
              eventElementName="accountPageShippingDetailsSubmitButton"
              level="primary"
              isLoading={loading}
              isSubmit
              isFullWidth
            >
              Save
            </Button>
            {address && (
              <Button
                eventElementName="accountPageShippingDetailsCancelButton"
                level="secondary"
                isLoading={loading}
                isSubmit
                isFullWidth
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      ) : (
        <div className="space-y-2">
          <Typography size="medium-paragraph">{address?.line1}</Typography>
          <Typography size="medium-paragraph">{address?.line2}</Typography>
          <Typography size="medium-paragraph">
            {address?.city}
            {', '}
            {address?.postalCode}
            {', '}
            {address?.state}
          </Typography>
          <Typography size="medium-paragraph">
            {address?.building ?? ''} {address?.company ?? ''}
          </Typography>
        </div>
      )}
    </Card>
  );
};
