import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { routes } from 'utils/routes';

export const AwaitingAnswersCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();

  return (
    <StatusLayout {...layoutProps} testId="awaiting-answers-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      <Typography size="medium-paragraph">
        {consultation.doctor?.shortClinicianName} has sent you a message.
      </Typography>
      <Button
        eventElementName="treatmentPageAwaitingAnswersCardButton"
        onClick={() => {
          if (consultation.chatThread) {
            history.push(routes.consultation.chat(consultation.id));
          } else {
            history.push(routes.consultation.review(consultation.id));
          }
        }}
      >
        View message
      </Button>
    </StatusLayout>
  );
};
