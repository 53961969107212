import {
  convertMsToMinutes,
  formatTimeToRead,
  TimeToRead,
} from '@customer-frontend/education';
import { colors } from '@eucalyptusvc/design-system/src/theme/software/palette';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock-alarm.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { Typography } from '@eucalyptusvc/design-system';

type EducationItemProps = {
  content?: {
    fields?: {
      title?: string;
      description?: string;
      hero?: {
        fields?: {
          file?: {
            url?: string;
          };
          title?: string;
        };
      };
    };
  };
  onSelect: () => void;
  timeToReadMs?: number | null;
};

const EducationItem: React.FC<EducationItemProps> = ({
  content,
  onSelect,
  timeToReadMs,
}: EducationItemProps) => {
  const timeToReadMinutes = timeToReadMs ? convertMsToMinutes(timeToReadMs) : 1;

  return (
    <div
      className="rounded-lg border border-black bg-white w-full flex flex-col cursor-pointer"
      onClick={onSelect}
    >
      <div
        className="w-full h-20 rounded-t-lg"
        style={{
          backgroundImage: `url(${content?.fields?.hero?.fields?.file?.url})`,
          backgroundSize: 'cover',
        }}
      />
      <div className="p-3 mt-1 space-y-4 flex flex-col justify-between flex-1">
        <div className="space-y-2">
          <Typography size="medium-paragraph" isBold>
            {content?.fields?.title}
          </Typography>
          <Typography size="paragraph" element="p" color={colors.primary[500]}>
            {content?.fields?.description}
          </Typography>
        </div>
        <div className="flex justify-between items-center ">
          <TimeToRead
            icon={<ClockIcon />}
            textSize="small-text"
            minutes={timeToReadMinutes}
            textColor={colors.primary[500]}
            formatStringFn={(minutes: number) =>
              `${formatTimeToRead(minutes)} read`
            }
          />
          <div className="bg-black rounded-full h-4 w-4 flex items-center justify-center">
            <ChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationItem;
