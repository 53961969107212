import { type ReactElement } from 'react';
import {
  ConsultationCardContentConsultationFragment,
  ProblemType,
} from '@customer-frontend/graphql-types';
import { Button, ButtonProps } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useConsultationCardContent } from './hooks/use-consultation-card-content';
import { hasPendingPathologyRequest } from '@customer-frontend/consultation';
import { RefetchQueriesInclude, gql } from '@apollo/client';
import { UseCustomTreatmentDetails } from '../treatment';

const pathologyProblemTypes: ProblemType[] = ['STI'];

type ConsultationCardContentProps = {
  consultation: ConsultationCardContentConsultationFragment;
  refetchQueries?: RefetchQueriesInclude;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  routes: {
    quiz: {
      root: (id: string) => string;
    };
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
      followUp: (id: string) => string;
      collectAddress: (id: string) => string;
      collectMedicare: (id: string) => string;
      phoneCall: (id: string) => string;
      scheduleCall: (id: string) => string;
      consultationPayment: (id: string) => string;
      upfrontPayment: (
        id: string,
        options?: {
          couponCode?: string | null;
        },
      ) => string;
      reviewFollowUpOrReview: (id: string) => string;
      reviewSubstitution: (id: string) => string;
      reason: (id: string) => string;
      plan: (id: string) => string;
      confirmPathology: (id: string) => string;
      activatePurchaseFlow: {
        review: (id: string) => string;
      };
    };
    treatment: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
    profile: string;
  };
};

export function ConsultationCardContent({
  consultation,
  refetchQueries,
  useCustomTreatmentDetails,
  routes,
}: ConsultationCardContentProps): ReactElement {
  const consultationCardContent = useConsultationCardContent({
    consultation,
    routes,
    refetchQueries,
    useCustomTreatmentDetails,
  });
  const history = useHistory();

  const buttonLevel: ButtonProps['level'] =
    consultation.status === 'AWAITING_RESULTS' ? 'primary' : 'secondary';

  const shouldRenderPathologyButton =
    hasPendingPathologyRequest(consultation) ||
    pathologyProblemTypes.includes(consultation.type);

  return (
    <div className="space-y-4">
      {consultationCardContent}
      {shouldRenderPathologyButton && (
        <Button
          key="pathologyButton"
          level={buttonLevel}
          onClick={() => {
            history.push(routes.consultation.confirmPathology(consultation.id));
          }}
          isFullWidth
        >
          <FormattedMessage
            defaultMessage="View pathology referral"
            description="Button to view a pathology referral page"
          />
        </Button>
      )}
    </div>
  );
}

ConsultationCardContent.fragment = gql`
  fragment ConsultationCardContentConsultation on Consultation {
    id
    status
    pathologyRequests {
      id
      result {
        id
      }
    }
    ...UseConsultationCardContentConsultation
  }

  ${useConsultationCardContent.fragment}
`;
