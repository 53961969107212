import {
  ProblemType,
  TreatmentActiveCardTreatmentFragment,
} from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { ConsultationCardProps } from '../types';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { type ReactElement } from 'react';
import { gql } from '@apollo/client';

type TreatmentActiveCardProps = {
  problemType: ProblemType;
  treatment: TreatmentActiveCardTreatmentFragment;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  routes: {
    treatment: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function TreatmentActiveCard({
  treatment,
  problemType,
  useCustomTreatmentDetails,
  routes,
}: TreatmentActiveCardProps): ReactElement {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const deemphasizeDashboardLink = useFeatureFlagBoolean(
    'DEEMPHASIZE_DASHBOARD_LINK',
  );
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');

  const buttons: ConsultationCardProps['buttons'] = [];

  if (treatment.active) {
    const treatmentActiveCardTreatmentButtonProps = {
      key: 'treatmentActiveCardTreatmentButton',
      text: (
        <FormattedMessage
          defaultMessage="View treatment plan"
          description="Button that navigates to the treatment plan details page"
        />
      ),
      onClick: () => {
        history.push(routes.treatment.plan(treatment.id));
      },
    };

    if (sunsetBpDashboard || problemType !== 'WEIGHT_LOSS') {
      buttons.push({
        ...treatmentActiveCardTreatmentButtonProps,
        level: 'primary' as const,
      });
    } else {
      const dashboardButtonProps = {
        key: 'treatmentActiveCardWeightButton',
        text: <FormattedMessage defaultMessage="View dashboard" />,
        onClick: () => {
          history.push(routes.weightLoss.dashboard);
        },
      };

      if (deemphasizeDashboardLink) {
        buttons.push(
          {
            ...treatmentActiveCardTreatmentButtonProps,
            level: 'primary' as const,
          },
          {
            ...dashboardButtonProps,
            level: 'link' as const,
          },
        );
      } else {
        buttons.push(
          {
            ...dashboardButtonProps,
            level: 'primary' as const,
          },
          {
            ...treatmentActiveCardTreatmentButtonProps,
            level: 'secondary' as const,
          },
        );
      }
    }
  }

  return (
    <ConsultationCardContentView paragraphs={[]} buttons={buttons}>
      <TreatmentDetails
        status={formatMessage({ defaultMessage: 'Active' })}
        showNextOrderDate
        showFullProductList
        treatment={treatment}
        useCustomTreatmentDetails={useCustomTreatmentDetails}
      />
    </ConsultationCardContentView>
  );
}

TreatmentActiveCard.fragment = gql`
  fragment TreatmentActiveCardTreatment on Treatment {
    id
    active
    ...TreatmentDetailsTreatment
  }

  ${TreatmentDetails.fragment}
`;
