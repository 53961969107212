import React from 'react';
import clsx from 'clsx';

import { Typography, Collapse } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import type { ExpandableCardProps } from './types';
import { getRootClassName, getDividerClassName } from './styles';

// NOTE: This card is a temporary solution to handle Pilot and Kin reskin
// The decision was not to add an additional variant to the gumdrop accordion
// as this style/concept will be most likely archived in the future.

export const ExpandableCard = ({
  defaultExpanded,
  icon,
  expandIcon = <ChevronDownIcon />,
  title,
  disabled,
  hasBorder = true,
  mountOnEnter = false,
  unmountOnExit = false,
  children,
  id,
  className,
  tabIndex,
}: ExpandableCardProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const [expandedOnce, setExpandedOnce] = React.useState(defaultExpanded);

  const { brand } = getConfig();

  React.useEffect(() => {
    if (expanded && !expandedOnce) {
      setExpandedOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleClick = (): void => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={clsx(getRootClassName(brand, hasBorder), className)}
      id={id}
      tabIndex={tabIndex}
    >
      <button
        className={clsx('w-full', { 'px-6': hasBorder })}
        aria-expanded={expanded}
        disabled={disabled}
        onClick={handleClick}
      >
        <div
          className={clsx(
            'flex items-center gap-2 transition-all duration-300 my-6',
            {
              'mb-4': expanded,
            },
          )}
        >
          {icon}
          <Typography
            element="h3"
            size={hasBorder ? 'xs' : 'md'}
            textAlign="left"
            isBold
          >
            {title}
          </Typography>
          <div
            className={clsx('ml-auto transition-transform duration-200', {
              'transform -rotate-180': expanded,
            })}
          >
            {expandIcon}
          </div>
        </div>
      </button>
      {!disabled && (
        <Collapse
          in={expanded}
          unmountOnExit={unmountOnExit || (mountOnEnter && !expandedOnce)}
        >
          <hr className={getDividerClassName(brand, hasBorder)} />
          <div className={clsx('pt-4 pb-6', { 'px-6': hasBorder })}>
            {children}
          </div>
        </Collapse>
      )}
    </div>
  );
};
