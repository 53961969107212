import { type ReactElement } from 'react';
import type {
  ConsultationStatus,
  ProblemType,
  TreatmentProcessingOrderCardTreatmentFragment,
} from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { ConsultationCardProps } from '../types';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { gql } from '@apollo/client';

type TreatmentProcessingOrderCardProps = {
  problemType: ProblemType;
  treatment: TreatmentProcessingOrderCardTreatmentFragment;
  consultationStatus: ConsultationStatus;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  routes: {
    treatment: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function TreatmentProcessingOrderCard({
  treatment,
  consultationStatus,
  problemType,
  useCustomTreatmentDetails,
  routes,
}: TreatmentProcessingOrderCardProps): ReactElement {
  const history = useHistory();

  const { formatMessage } = useIntl();
  const deemphasizeDashboardLink = useFeatureFlagBoolean(
    'DEEMPHASIZE_DASHBOARD_LINK',
  );
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');
  const showDashboardButton =
    problemType === 'WEIGHT_LOSS' && !sunsetBpDashboard;

  const treatmentProcessingOrderTreatmentButtonProps = {
    key: 'treatmentProcessingOrderTreatmentButton',
    text: (
      <FormattedMessage
        defaultMessage="View treatment plan"
        description="Button that navigates to the treatment plan details page"
      />
    ),
    onClick: () => {
      history.push(routes.treatment.plan(treatment.id));
    },
  };
  const buttons: ConsultationCardProps['buttons'] = [];

  if (treatment.active) {
    const dashboardButtonProps = {
      key: 'treatmentProcessingOrderWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
    };

    if (deemphasizeDashboardLink) {
      buttons.push({
        ...treatmentProcessingOrderTreatmentButtonProps,
        level: 'primary' as const,
      });

      if (showDashboardButton) {
        buttons.push({
          ...dashboardButtonProps,
          level: 'link' as const,
        });
      }
    } else {
      buttons.push({
        ...treatmentProcessingOrderTreatmentButtonProps,
        level: 'secondary' as const,
      });

      if (showDashboardButton) {
        buttons.unshift({
          ...dashboardButtonProps,
          level: 'primary' as const,
        });
      }
    }
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'treatmentProcessingOrderText',
          text: (
            <FormattedMessage
              defaultMessage="You will be notified when your order is on its way. Sit tight!"
              description="Description telling users that they will be notified of any updates to their order"
            />
          ),
        },
      ]}
      buttons={buttons}
    >
      <TreatmentDetails
        treatment={treatment}
        status={
          consultationStatus === 'AWAITING_VERIFY_ID'
            ? formatMessage({
                defaultMessage: 'Your identity is being verified',
                description:
                  'Status label informing users their identity is being verified in our system',
              })
            : formatMessage({
                defaultMessage: 'Your order is being processed',
                description:
                  'Status label informing users their order is processing',
              })
        }
        useCustomTreatmentDetails={useCustomTreatmentDetails}
      />
    </ConsultationCardContentView>
  );
}

TreatmentProcessingOrderCard.fragment = gql`
  fragment TreatmentProcessingOrderCardTreatment on Treatment {
    id
    active
    ...TreatmentDetailsTreatment
  }

  ${TreatmentDetails.fragment}
`;
