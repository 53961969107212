import { useCallback, type FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Typography, Button } from '@eucalyptusvc/design-system';
import { QuizApplication } from '@customer-frontend/graphql-types';
import { useStartQuizApplication } from '@customer-frontend/services';
import { StatusLayout } from './status-card/components';
import { routes } from 'utils/routes';
import { Card as CardType, CardKey } from './use-profile-cards';

export type QuizCardProps = {
  quizApplication: Pick<QuizApplication, 'problemType'>;
};

const QuizCard: FunctionComponent<QuizCardProps> = ({ quizApplication }) => {
  const { problemType } = quizApplication;
  const startQuizApplication = useStartQuizApplication();
  const history = useHistory();

  const onClick = useCallback(async (): Promise<void> => {
    const quizApplicationInfo = await startQuizApplication(problemType);
    return history.push(routes.quiz.root(quizApplicationInfo.id));
  }, [startQuizApplication, problemType, history]);

  return (
    <Card size="sm">
      <StatusLayout title="" problemType={problemType} testId="quiz-card">
        <Typography key="text" size="medium-paragraph">
          <strong>Status:</strong> Complete your consult so that we can connect
          you with a practitioner who can prescribe a treatment plan.
        </Typography>

        <Button onClick={onClick}>Continue consult</Button>
      </StatusLayout>
    </Card>
  );
};

export const getQuizCard = ({
  props,
  key,
}: {
  props: QuizCardProps;
  key: CardKey;
}): CardType<QuizCardProps> => ({
  props,
  key,
  CardComponent: QuizCard,
});

export default QuizCard;
