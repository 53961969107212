import { Maybe, Treatment } from '@customer-frontend/graphql-types';
import { useProfile } from '@customer-frontend/services';
import { shouldShowExploreProductsSection } from 'pages/profile/treatment/utils';
import { isSkinProblemType } from '@customer-frontend/utils';

const validRoutes = [
  '/home',
  '/treatment',
  '/discover',
  '/support',
  '/quiz/complete/skinCheckInQuiz',
];

const getShouldShowOtcCartForTreatment = (
  treatment: Maybe<Treatment>,
): boolean => {
  const isPaused = !!treatment && treatment.status === 'PAUSED';

  // show cart if treatment is paused ONLY on treatment page
  if (isPaused && window.location.pathname.startsWith('/treatment')) {
    return true;
  }

  const isValidTreatmentStatus = shouldShowExploreProductsSection(treatment);

  const isValidRoute = validRoutes.some((route) =>
    window.location.pathname.startsWith(route),
  );

  return isValidRoute && isValidTreatmentStatus;
};

export const useShouldShowOtcCartNavButton = (): boolean => {
  const profileResp = useProfile();
  const user = profileResp.data?.profile;
  const consultations = user?.consultations ?? [];
  const treatment = consultations.find((c) =>
    isSkinProblemType(c.type),
  )?.treatment;

  return getShouldShowOtcCartForTreatment(treatment);
};
