import { useState } from 'react';
import {
  MedicareCollectionForm,
  MedicareFormOnPage,
  usePatientAndHealthcareAttrs,
} from '../../../healthcare-collection';
import {
  Button,
  LoadingSpinner,
  Typography,
  TypographySize,
  Card,
} from '@eucalyptusvc/design-system';

interface EditMedicareDetailsProps {
  paragraphSize?: TypographySize;
  enableEditing?: boolean;
}

export const EditMedicareDetails = ({
  paragraphSize = 'paragraph',
  enableEditing = false,
}: EditMedicareDetailsProps): React.ReactElement => {
  const { medicareDetailsCollected, loading } = usePatientAndHealthcareAttrs();
  const [isEditing, setIsEditing] = useState(enableEditing);
  const toggleEditing = (): void => {
    setIsEditing(!isEditing);
  };

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (isEditing) {
    return (
      <MedicareCollectionForm
        validationLevel="all"
        submitButtonText="Save"
        onPage={MedicareFormOnPage.patientProfile}
        onCancel={toggleEditing}
        autoFocus={enableEditing}
      />
    );
  }

  return (
    <Card>
      <div className="flex align-start justify-between">
        <Typography isBold size="md">
          Medicare Details
        </Typography>
        <span>
          <Button level="secondary" onClick={toggleEditing}>
            {medicareDetailsCollected ? 'Edit' : 'Add'}
          </Button>
        </span>
      </div>
      {medicareDetailsCollected && (
        <Typography size={paragraphSize}>
          Medicare number: •••• ••••• •
        </Typography>
      )}
      {!medicareDetailsCollected && (
        <Typography size={paragraphSize}>
          Add your medicare details for your patient profile.
        </Typography>
      )}
    </Card>
  );
};
