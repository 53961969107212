import { Typography } from '@eucalyptusvc/design-system';
import clsx from 'clsx';
import React from 'react';
import { SkinGoalPersonalisationOption } from './constants';

interface SkinGoalLabel {
  options: SkinGoalPersonalisationOption;
  small?: boolean;
  className?: string;
}

export const SkinGoalBadge = ({
  options,
  className,
  small = false,
}: SkinGoalLabel): React.ReactElement => {
  return (
    <div
      className={clsx(
        small ? 'h-5 w-5' : 'h-8 w-8',
        'flex text-black items-center justify-center rounded-full absolute -top-1 -left-1',
        className,
      )}
      style={{ backgroundColor: options.color }}
    >
      <Typography size={small ? 'paragraph' : 'medium-paragraph'}>
        {options.letter}
      </Typography>
    </div>
  );
};

export const SkinGoalPill = ({
  options,
  className,
}: SkinGoalLabel): React.ReactElement => {
  return (
    <div
      className={clsx('py-1 px-2 rounded-full flex-shrink-0', className)}
      style={{ backgroundColor: options.color }}
    >
      <Typography size="paragraph">{options.shortText}</Typography>
    </div>
  );
};
