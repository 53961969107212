import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  TextArea,
  TextInput,
} from '@eucalyptusvc/design-system';
import {
  maxLengthValidation,
  requiredValidation,
} from '@customer-frontend/utils';

export interface SupportFormData {
  subject: string;
  body: string;
}

export const SupportForm = ({
  loading,
  disabled,
  onClose,
  onSubmit,
}: {
  loading: boolean;
  disabled?: boolean;
  onClose: () => void;
  onSubmit: (formData: SupportFormData) => void;
}): React.ReactElement => {
  const { register, handleSubmit, errors } = useForm<{
    subject: string;
    body: string;
  }>();

  return (
    <form className="flex flex-col space-y-4">
      <TextInput
        name="subject"
        label="Subject"
        ref={register({
          ...requiredValidation('Subject'),
          ...maxLengthValidation('Subject', 100),
        })}
        errorMessage={errors?.subject?.message}
      />
      <TextArea
        name="body"
        label="Your message"
        rows={5}
        ref={register({
          ...requiredValidation('Message'),
          ...maxLengthValidation('Message', 5000),
        })}
        errorMessage={errors?.body?.message}
      />
      <div className="flex flex-col sm:flex-row gap-4 justify-end">
        <Button level="secondary" isDisabled={disabled} onClick={onClose}>
          Cancel
        </Button>
        <Button
          level="primary"
          isLoading={loading}
          isDisabled={disabled}
          onClick={handleSubmit((formData) => onSubmit(formData))}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export const SupportFormSubmitted = (): React.ReactElement => {
  return (
    <div>
      <Typography size="medium-paragraph">
        Our team will get back to you within 24 hours.
      </Typography>
    </div>
  );
};
