import {
  ProgressEntry,
  QueryProgressEntriesArgs,
} from '@customer-frontend/graphql-types';
import { gql, useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

interface Response {
  progressEntries: ProgressEntry[];
}

const progressEntriesQuery = gql`
  query ProgressEntries($type: ProgressEntryType!) {
    progressEntries(type: $type) {
      date
      userId
      type
      photos {
        id
        angle
        signedUrl
      }
      responses {
        id
        answer
        questionCode
      }
      id
    }
  }
`;

export function useProgressEntries(
  options: QueryHookOptions<Response, QueryProgressEntriesArgs>,
): QueryResult<Response, QueryProgressEntriesArgs> {
  return useQuery<Response, QueryProgressEntriesArgs>(
    progressEntriesQuery,
    options,
  );
}
