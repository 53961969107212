import { FormattedMessage } from 'react-intl';
import type { ReminderCardReminderFragment } from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { ReactComponent as BellRingIcon } from '../../../../assets/icons/bell-ring.svg';
import { ReactComponent as BellIcon } from '../../../../assets/icons/bell.svg';
import { type ReactElement } from 'react';
import { ConsultationCardProps } from '../types';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { getConfig } from '@customer-frontend/config';
import { gql } from '@apollo/client';

const getContent = ({
  isTriggered,
  doctorName,
  reminder,
}: {
  isTriggered: boolean;
  doctorName: string;
  reminder: ReminderCardReminderFragment;
}): {
  paragraphs: ConsultationCardProps['paragraphs'];
  buttonText: ReactElement;
} => {
  const config = getConfig();

  if (isTriggered) {
    return {
      paragraphs: [
        {
          key: 'reminderCardDoctorText',
          text: (
            <>
              <BellRingIcon className="inline-block mr-2 w-4 h-4" />
              <FormattedMessage
                defaultMessage="{doctorName} would like to hear from you"
                values={{ doctorName }}
              />
            </>
          ),
          isBold: true,
        },
        {
          key: 'reminderCardPromptText',
          text: (
            <FormattedMessage defaultMessage="Please let them know whether you would like to proceed." />
          ),
        },
      ],
      buttonText: <FormattedMessage defaultMessage="Send a message" />,
    };
  } else {
    return {
      paragraphs: [
        {
          key: 'reminderCardDoctorText',
          text: (
            <>
              <BellIcon className="inline-block mr-2 w-4 h-4" />
              <FormattedMessage
                defaultMessage="{doctorName} has set a reminder for {scheduledAt}"
                values={{
                  doctorName,
                  scheduledAt: formatDate(
                    mapBrandToAdaptersBrand(config.brand),
                    reminder.scheduledAt,
                    { dateStyle: 'medium' },
                  ),
                }}
              />
            </>
          ),
          isBold: true,
        },
        {
          key: 'reminderCardPromptText',
          text: (
            <FormattedMessage defaultMessage="Feel free to continue your consult when you're ready." />
          ),
        },
      ],
      buttonText: (
        <FormattedMessage defaultMessage="View consult" description="" />
      ),
    };
  }
};

type ReminderCardProps = {
  consultationId: string;
  doctorName: string;
  reminder: ReminderCardReminderFragment;
  hasChatThread: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
  };
};

export function ReminderCard({
  consultationId,
  doctorName,
  reminder,
  hasChatThread,
  routes,
}: ReminderCardProps): ReactElement {
  const history = useHistory();
  const isTriggered = reminder.doneAt !== null;
  const { paragraphs, buttonText } = getContent({
    isTriggered,
    doctorName,
    reminder,
  });

  return (
    <ConsultationCardContentView
      paragraphs={paragraphs}
      buttons={[
        {
          key: 'reminderCardButton',
          level: 'secondary' as const,
          eventElementName: 'consultationReminderCardButton',
          text: buttonText,
          onClick: () => {
            if (hasChatThread) {
              history.push(routes.consultation.chat(consultationId));
            } else {
              history.push(routes.consultation.review(consultationId));
            }
          },
        },
      ]}
    />
  );
}

ReminderCard.fragment = gql`
  fragment ReminderCardReminder on ConsultationReminder {
    id
    scheduledAt
    doneAt
  }
`;
