import {
  ConsultationStatus,
  FulfillmentStatus,
  ProblemType,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { ProfileCard } from '@customer-frontend/services';
import { isSkinProblemType } from '@customer-frontend/utils';
import moment from 'moment';

export const completedStatuses: ConsultationStatus[] = [
  'CUSTOMER_COMPLETED',
  'ORDER_COMPLETED',
];

export const shouldShowExploreProductsSection = (
  skinTreatment?: {
    type: ProblemType;
    hasUnfulfilledOrder: boolean;
    status: TreatmentStatus;
  } | null,
): boolean => {
  return (
    !!skinTreatment &&
    isSkinProblemType(skinTreatment.type) &&
    !skinTreatment.hasUnfulfilledOrder &&
    skinTreatment.status !== 'REVIEW' &&
    skinTreatment.status !== 'FOLLOW_UP'
  );
};
export type ProfileQuizApplication = {
  updatedAt: string;
};

export type ProfileConsultation = {
  status: ConsultationStatus;
  type: ProblemType;
  updatedAt: string;
};

export type ProfileTreatmentCard = ProfileCard<
  ProfileQuizApplication,
  ProfileConsultation,
  unknown
>;

// If somebody has cards for both skin & hair, the sorting logic is as follows:
// 1. Quizzes/Consults (due to active CTA)
//     1. Order by recency of update first
// 2. Completed consults:
//     1. Skin > hair (due to sneak peek)
export const sortProfileTreatments = (
  a: ProfileTreatmentCard,
  b: ProfileTreatmentCard,
): number => {
  if (a.treatmentState === 'purchase' || b.treatmentState === 'purchase') {
    return 0;
  }

  if (
    a.treatmentState === 'consultation' &&
    b.treatmentState === 'consultation'
  ) {
    if (
      completedStatuses.includes(a.consultation.status) &&
      completedStatuses.includes(b.consultation.status)
    ) {
      if (isSkinProblemType(a.consultation.type)) {
        return -1;
      }

      if (isSkinProblemType(b.consultation.type)) {
        return 1;
      }

      return b.consultation.updatedAt.localeCompare(a.consultation.updatedAt);
    }

    if (completedStatuses.includes(a.consultation.status)) {
      return 1;
    }

    if (completedStatuses.includes(b.consultation.status)) {
      return -1;
    }
  }

  if (
    a.treatmentState === 'consultation' &&
    completedStatuses.includes(a.consultation.status)
  ) {
    return 1;
  }

  if (
    b.treatmentState === 'consultation' &&
    completedStatuses.includes(b.consultation.status)
  ) {
    return -1;
  }

  let aUpdatedAt: string;
  let bUpdatedAt: string;
  if (a.treatmentState === 'consultation') {
    aUpdatedAt = a.consultation.updatedAt;
  } else {
    aUpdatedAt = a.quizApplication.updatedAt;
  }
  if (b.treatmentState === 'consultation') {
    bUpdatedAt = b.consultation.updatedAt;
  } else {
    bUpdatedAt = b.quizApplication.updatedAt;
  }

  return bUpdatedAt.localeCompare(aUpdatedAt);
};

export const findFirstFulfilledOrderDate = (
  orders?: {
    fulfillment?: FulfillmentStatus | null | undefined;
    fulfilledAt?: string;
    createdAt?: string;
  }[],
): string | undefined => {
  const firstOrder = orders
    ?.filter((t) => t.fulfillment === 'FULFILLED')
    .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))[0];

  if (!firstOrder) {
    return;
  }

  return firstOrder?.fulfilledAt
    ? firstOrder.fulfilledAt
    : firstOrder.createdAt;
};
