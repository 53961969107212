import React from 'react';
import { ExpandableCard } from '@customer-frontend/profile';
import { Typography } from '@eucalyptusvc/design-system';
import { Card as CardType, CardKey } from './use-profile-cards';

const SkinSideEffectsCard = (): React.ReactElement => {
  return (
    <ExpandableCard title="Experiencing skin side effects?" defaultExpanded>
      <div className="flex flex-col justify-between space-y-3">
        <Typography size="medium-paragraph">
          If you notice some redness, dryness, flaking, or even a bit of
          stinging after applying your formula, that&apos;s totally normal. In
          fact, it means that your treatment is working!
        </Typography>
        <Typography size="medium-paragraph">
          Watch our video to learn more about how to manage side effects.
        </Typography>
        <div className="pt-4">
          <iframe
            className="w-full"
            width="500"
            height="285"
            src="https://www.youtube-nocookie.com/embed/rHseGrQtU4U"
            title="Side effects video"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </ExpandableCard>
  );
};

export const getSkinSideEffectsCard = ({
  key,
}: {
  key: CardKey;
}): CardType<Record<string, never>> => ({
  CardComponent: SkinSideEffectsCard,
  key,
  props: {},
});
