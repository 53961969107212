import { gql, useMutation } from '@apollo/client';
import {
  MutationUpdateProfileArgs,
  User,
} from '@customer-frontend/graphql-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '@customer-frontend/auth';
import {
  Card,
  Button,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { capitaliseString } from '@customer-frontend/utils';
import { useUpdateResidentialAddress } from '../../../graphql/mutations/update-residential-address';
import { onError } from '../../../utils/logging';

import { getConfig } from '@customer-frontend/config';
import { FeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { IntlAddressInput } from '@customer-frontend/intl';

const updateProfileDocument = gql`
  mutation UpdateProfileUserDetailsSoftware(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $namePreference: NamePreferenceCreateWithoutUserInput
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      namePreference: $namePreference
    ) {
      id
      firstName
      lastName
      email
      shortAddressableName
      fullName
      phone
      preferredName
    }
  }
`;

type FormDetails = {
  firstName: string;
  lastName: string;
  preferredName: string;
  phone: string;
  email: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
};

export const AccountDetailsCard = ({
  user,
  onUpdated,
}: {
  user: User;
  onUpdated?: () => void;
}): React.ReactElement => {
  const notify = useNotification();
  const [isEditing, setIsEditing] = useState(false);
  const { updateUser } = useAuth();
  const config = getConfig();

  const { register, handleSubmit, errors, reset } = useForm<FormDetails>({});

  useEffect(() => {
    reset({
      firstName: user.legalFirstName ?? '',
      lastName: user.legalLastName ?? '',
      preferredName: user.preferredName ?? '',
      phone: user.phone ?? '',
      email: user.email ?? '',
      address: {
        line1: user.residentialAddress?.line1 ?? '',
        line2: user.residentialAddress?.line2 ?? '',
        city: user.residentialAddress?.city ?? '',
        postalCode: user.residentialAddress?.postalCode ?? '',
        state: user.residentialAddress?.state ?? '',
        country: user.residentialAddress?.country ?? config.country,
      },
    });
  }, [reset, user, isEditing, config.country]);

  const [updateProfile, { loading }] = useMutation<
    {
      updateProfile: User;
    },
    MutationUpdateProfileArgs
  >(updateProfileDocument, {
    onCompleted: (response) => {
      if (response.updateProfile) {
        updateUser?.(response.updateProfile);
        setIsEditing(false);
        onUpdated?.();
      }
    },
  });

  const [updateResidentialMutation] = useUpdateResidentialAddress({
    onError,
  });

  const submitForm = async (data: FormDetails): Promise<void> => {
    try {
      await updateResidentialMutation({
        variables: {
          residentialAddress: {
            line1: data.address.line1,
            line2: data.address.line2 || undefined,
            city: data.address.city,
            postalCode: data.address.postalCode,
            state: data.address.state,
            country: config.country, // required because react hook form wont set values for disabled fields
          },
        },
      });

      const payload: MutationUpdateProfileArgs = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phone,
      };

      if (data.preferredName) {
        payload.namePreference = {
          preferredName: data?.preferredName,
        };
      }

      await updateProfile({
        variables: payload,
      });
      notify.success({
        message: 'Your details have been saved successfully!',
      });
    } catch (err) {
      notify.error({
        message: capitaliseString(err.message),
      });
    }
  };

  return (
    <Card>
      <div className="flex justify-between items-center mb-4">
        <Typography size="md" isBold>
          Your details
        </Typography>
        {!isEditing && (
          <span>
            <Button
              eventElementName="accountPageEditButton"
              level="secondary"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          </span>
        )}
      </div>
      {isEditing ? (
        <form className="space-y-2" onSubmit={handleSubmit(submitForm)}>
          <div className="flex space-x-2">
            <div className="w-1/2">
              <TextInput
                ref={register}
                label="First name"
                name="firstName"
                errorMessage={errors?.firstName?.message}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                ref={register}
                label="Last name"
                name="lastName"
                errorMessage={errors?.lastName?.message}
              />
            </div>
          </div>
          <FeatureFlagBoolean flagKey="PREFERRED_NAME">
            <TextInput
              ref={register}
              label="Preferred name"
              name="preferredName"
            />
          </FeatureFlagBoolean>
          <TextInput
            ref={register}
            label="Email"
            name="email"
            disabled={true}
          />
          <TextInput
            ref={register}
            label="Phone"
            name="phone"
            errorMessage={errors?.phone?.message}
          />
          <IntlAddressInput
            className="mt-3"
            name="address"
            registerField={register}
            errors={errors.address ?? {}}
            useAutoComplete
          />
          <div className="flex flex-col space-y-2 items-center md:w-56 md:flex-row md:space-x-2 md:space-y-0">
            <Button
              eventElementName="accountPageSaveButton"
              level="primary"
              isLoading={loading}
              isSubmit
              isFullWidth
            >
              Save
            </Button>
            <Button
              eventElementName="accountPageCancelButton"
              level="secondary"
              isDisabled={loading}
              isSubmit
              isFullWidth
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : (
        <div className="space-y-2">
          <Typography size="medium-paragraph">
            <strong>Name: </strong>
            {user.legalFullName}
          </Typography>
          <FeatureFlagBoolean flagKey="PREFERRED_NAME">
            <Typography size="medium-paragraph">
              <strong>Preferred name: </strong>
              {user.preferredName}
            </Typography>
          </FeatureFlagBoolean>
          <Typography size="medium-paragraph">
            <strong>Email: </strong>
            {user.email}
          </Typography>
          <Typography size="medium-paragraph">
            <strong>Phone: </strong>
            {user.phone}
          </Typography>
          <Typography size="medium-paragraph">
            <strong>Home Address: </strong>
          </Typography>
          <Typography size="medium-paragraph">
            {user.residentialAddress?.line1}
          </Typography>
          <Typography size="medium-paragraph">
            {user.residentialAddress?.line2}
          </Typography>
          <Typography size="medium-paragraph">
            {[
              user.residentialAddress?.city,
              user.residentialAddress?.postalCode,
              user.residentialAddress?.state,
            ]
              .filter((a) => !!a)
              .join(', ')}
          </Typography>
        </div>
      )}
    </Card>
  );
};
