import { ProblemType } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardProps } from '../types';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useIHI } from '@customer-frontend/healthcare-collection';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { ReactElement } from 'react';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';

type TreatmentReviewAwaitingSurveyCardProps = {
  consultationId: string;
  hasActiveWeightTreatment: boolean;
  problemType: ProblemType;
  quizApplicationSubmitted: boolean;
  children: React.ReactNode;
  routes: {
    consultation: {
      collectAddress: (id: string) => string;
      collectMedicare: (id: string) => string;
      phoneCall: (id: string) => string;
      followUp: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function TreatmentReviewAwaitingSurveyCard({
  consultationId,
  hasActiveWeightTreatment,
  problemType,
  quizApplicationSubmitted,
  routes,
  children,
}: TreatmentReviewAwaitingSurveyCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const { ihiCollected, hasResidentialAddress } = useIHI(problemType);
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');

  const buttons: ConsultationCardProps['buttons'] = quizApplicationSubmitted
    ? [
        {
          key: 'treatmentReviewAwaitingSurveyCardButtonContinue',
          text: (
            <FormattedMessage
              defaultMessage="Continue consult"
              description="Button to continue consult"
            />
          ),
          onClick: () => {
            if (!hasResidentialAddress) {
              return history.push(
                routes.consultation.collectAddress(consultationId),
              );
            }
            if (!ihiCollected) {
              return history.push({
                pathname: routes.consultation.collectMedicare(consultationId),
              });
            }
            return history.push(routes.consultation.phoneCall(consultationId));
          },
        },
      ]
    : [
        {
          key: 'treatmentReviewAwaitingSurveyCardButton',
          text: (
            <FormattedMessage
              defaultMessage="Start consult"
              description="Button to start a review consult"
            />
          ),
          onClick: () => {
            history.push(routes.consultation.followUp(consultationId));
          },
        },
      ];
  if (hasActiveWeightTreatment && !sunsetBpDashboard) {
    buttons.push({
      key: 'treatmentReviewAwaitingSurveyCardWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: 'secondary' as const,
    });
  }

  const paragraphs: ConsultationCardProps['paragraphs'] =
    quizApplicationSubmitted
      ? [
          {
            key: 'treatmentReviewAwaitingSurveyCardTextContinue',
            text: (
              <FormattedMessage
                defaultMessage="Continue with your consult so your {isPrescriber, select, true {prescriber} other {practitioner}} can review your treatment."
                description="Description informing users that they need to continue with thier consultation"
                values={{
                  isPrescriber:
                    config.prescriberType === PrescriberType.PRESCRIBER,
                }}
              />
            ),
          },
        ]
      : [
          {
            key: 'treatmentReviewAwaitingSurveyCardTextStart',
            text: (
              <FormattedMessage
                defaultMessage="We've created a consultation to review your current treatment plan. Before we begin, you'll need to answer some questions for your {isPrescriber, select, true {prescriber} other {practitioner}}."
                description="Description informing users that they need to answer some questions before their next consultation"
                values={{
                  isPrescriber:
                    config.prescriberType === PrescriberType.PRESCRIBER,
                }}
              />
            ),
          },
        ];

  return (
    <ConsultationCardContentView paragraphs={paragraphs} buttons={buttons}>
      {children}
    </ConsultationCardContentView>
  );
}
