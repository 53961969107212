import { Card } from '@eucalyptusvc/design-system';
import { getSkinPillOption } from 'pages/consultation/prepayment-experience/personalisation-screen/utils';
import { getPersonalisationInput } from 'utils/personalisation';
import { ConsultationStatusCard } from './status-card';
import { StatusCardContentConsultation } from './status-card/types';
import { Card as CardType, CardKey } from './use-profile-cards';

export type ConsultationProfileCardProps = {
  consultation: StatusCardContentConsultation & {
    quizApplication?: {
      answers?:
        | {
            answer?: string | null;
            answersArray: string[];
            question?: { shortcode?: string | null } | null;
          }[]
        | null;
    } | null;
    rejectReason?: unknown;
  };
  userEmail: string | undefined;
};

const ConsultationProfileCard: React.FC<ConsultationProfileCardProps> = ({
  consultation,
  userEmail,
}) => {
  const personalisationInput = getPersonalisationInput(
    consultation?.quizApplication?.answers,
  );

  const skinGoalOption = personalisationInput.primarySkinGoal
    ? getSkinPillOption(personalisationInput.primarySkinGoal)
    : null;

  return (
    <div className="flex flex-col gap-y-4 md:gap-y-8">
      <div className="relative">
        <Card size="sm">
          {skinGoalOption && !consultation.rejectReason && (
            <img
              src={skinGoalOption.graphic}
              className="h-32 w-32 absolute top-4 -right-14 z-10"
            />
          )}
          <ConsultationStatusCard
            consultation={consultation}
            userEmail={userEmail}
            personalisationInput={personalisationInput}
          />
        </Card>
      </div>
    </div>
  );
};

export const getConsultationProfileCard = ({
  props,
  key,
}: {
  props: ConsultationProfileCardProps;
  key: CardKey;
}): CardType<ConsultationProfileCardProps> => ({
  props,
  key,
  CardComponent: ConsultationProfileCard,
});
