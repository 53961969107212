import { SkinUsage } from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import { ReactComponent as Minus } from '../../../../assets/icons/minus.svg';
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg';

const getUsageRow = (
  usage?: SkinUsage | null,
): { icon: React.ReactElement; text: string } => {
  switch (usage) {
    case 'ONCE_A_WEEK':
    case 'TWICE_A_WEEK':
      return {
        icon: <Cross className="mr-2" />,
        text: 'Using your treatment 1-2 times a week',
      };
    case 'THREE_TIMES_A_WEEK':
    case 'FOUR_TIMES_A_WEEK':
      return {
        icon: <Minus className="mr-2" />,
        text: 'Using your treatment 3-4 times a week',
      };
    case 'FIVE_PLUS_TIMES_A_WEEK':
      return {
        icon: <Check className="mr-2" />,
        text: 'Using your treatment 5+ times a week',
      };
    default:
      throw Error('usage is missing');
  }
};

export const CheckInResponseSummary = ({
  usage,
  sideEffects,
  seeingResults,
  title,
}: {
  usage?: SkinUsage | null;
  sideEffects?: boolean | null;
  seeingResults?: boolean | null;
  title?: string | React.ReactElement;
}): React.ReactElement => {
  const usageRow = getUsageRow(usage);

  return (
    <div className="space-y-2">
      {title}
      <div className="flex items-center">
        {usageRow.icon}
        <Typography size="small-text" isItalic>
          {usageRow.text}
        </Typography>
      </div>
      <div className="flex items-center">
        {sideEffects ? (
          <>
            <Minus className="mr-2" />
            <Typography size="small-text" isItalic>
              Experiencing some side effects.
            </Typography>
          </>
        ) : (
          <>
            <Check className="mr-2" />
            <Typography size="small-text" isItalic>
              Not experiencing side effects.
            </Typography>
          </>
        )}
      </div>
      <div className="flex items-center">
        {seeingResults ? (
          <>
            <Check className="mr-2" />
            <Typography size="small-text" isItalic>
              Seeing results.
            </Typography>
          </>
        ) : (
          <>
            <Cross className="mr-2" />
            <Typography size="small-text" isItalic>
              Not Seeing results.
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
