import { QuizCode } from '@customer-frontend/quiz';

export enum OnboardingQuizStatus {
  HAVENT_STARTED,
  IN_PROGRESS,
  COMPLETED,
}

export const getOnboardingQuizStatus = (
  trackerQuizApplications?:
    | {
        quiz: {
          quizCode: string;
        };
        createdAt: string;
        submittedAt?: string | null;
      }[]
    | null,
): OnboardingQuizStatus | undefined => {
  if (!trackerQuizApplications) {
    return undefined;
  }

  const skinOnboardingQuizApplications = trackerQuizApplications.filter(
    (application) => application.quiz.quizCode === QuizCode.SKIN_ONBOARDING,
  );

  const alreadyHasCompletedApplication = skinOnboardingQuizApplications.some(
    (application) => !!application.submittedAt,
  );

  if (alreadyHasCompletedApplication) {
    return OnboardingQuizStatus.COMPLETED;
  }

  if (skinOnboardingQuizApplications.length > 0) {
    return OnboardingQuizStatus.IN_PROGRESS;
  }

  return OnboardingQuizStatus.HAVENT_STARTED;
};
