import { Button, Typography } from '@eucalyptusvc/design-system';
import { ConsultationCardProps } from '../types';

export const ConsultationCardContentView: React.FC<ConsultationCardProps> = ({
  paragraphs,
  buttons,
  children,
  footnote,
}) => {
  return (
    <div className="space-y-4">
      {children}
      {paragraphs.map(({ text, key, isBold = false }) => (
        <Typography size="medium-paragraph" key={key} isBold={isBold}>
          {text}
        </Typography>
      ))}
      {buttons.length > 0 &&
        buttons.map((button) => (
          <Button
            key={button.key}
            level={button.level}
            onClick={button.onClick}
            eventElementName={button.eventElementName}
            isFullWidth
            isDisabled={button.isDisabled}
          >
            {button.text}
          </Button>
        ))}
      {footnote}
    </div>
  );
};
