import { PhotoAngleType } from '@customer-frontend/graphql-types';
import {
  getWeekLabel,
  getWeeksBetweenDates,
} from 'pages/profile/progress/utils';

export const getEntryWeekLabel = (
  progressEntry: { date: Date },
  startDate?: Date,
): string => {
  if (startDate) {
    const weeks = getWeeksBetweenDates(startDate, progressEntry.date);
    const adjustedWeekValue = weeks < 0 ? 0 : weeks + 1;
    return getWeekLabel(adjustedWeekValue);
  }
  return 'Before Software';
};

export const getFrontPhotoUrlFromEntry = (entry: {
  photos?: Array<
    { angle: PhotoAngleType; signedUrl: string } | undefined | null
  > | null;
}): string | undefined => {
  const frontPhoto = entry.photos?.find((photo) => photo?.angle === 'FRONT');
  return frontPhoto?.signedUrl;
};
