import { ReactElement } from 'react';
import polariods from '../../../../assets/progress-tracker/modal-polariods.png';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useStartQuizApplication } from '@customer-frontend/services';
import { Typography, Button } from '@eucalyptusvc/design-system';

const Welcome = (): ReactElement => {
  const history = useHistory();
  const startQuizApplication = useStartQuizApplication();

  const startSkinQuizCheckIn = async (): Promise<void> => {
    const skinTrackerQuizApplication = await startQuizApplication(
      'SKIN_GENERAL',
      'TRACKER',
    );

    history.push(
      routes.quiz.root(
        skinTrackerQuizApplication.id + '?utm_source=welcome_card',
      ),
    );
  };

  const onClick = (): void => {
    startSkinQuizCheckIn();
  };

  return (
    <div className="flex flex-col items-center space-y-8">
      <img className="m-auto sm:w-auto w-1/2" src={polariods} />
      <div className="space-y-4 text-center">
        <Typography size="md" isBold>
          Track your skin progress
        </Typography>
        <Typography size="large-paragraph">
          Take photos of your skin so you can compare your progress over time.
        </Typography>
      </div>

      <Button
        eventElementName="profileProgressInfoCardButton"
        isFullWidth
        onClick={onClick}
      >
        Next
      </Button>
    </div>
  );
};

export default Welcome;
