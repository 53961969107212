import { Typography } from '@eucalyptusvc/design-system';
import { ReactElement } from 'react';

export const ImageTitle = ({
  copy,
  className = '',
}: {
  copy: string;
  className?: string;
}): ReactElement => {
  return (
    <div className={`text-center mx-auto mb-1 font-semibold ${className}`}>
      <Typography size="paragraph">{copy}</Typography>
    </div>
  );
};
