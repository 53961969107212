import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { ProblemType } from '@customer-frontend/graphql-types';
import {
  getMedicareAnsOptedFromSS,
  MedicareAnswerOpted,
  usePatientAndHealthcareAttrs,
} from '@customer-frontend/healthcare-collection';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { colors } from '@eucalyptusvc/design-system/src/theme/software/palette';
import { useProgressEntries } from 'graphql/queries/progress-tracker/progress-entries';
import moment from 'moment';
import {
  getMedicareProgressNodeClassName,
  getMedicareProgressNodeText,
  getMedicareProgressNodeVariant,
} from 'pages/consultation/utils';
import { useMemo } from 'react';
import { useStartTrackerQuiz } from 'utils/hooks/use-start-tracker-quiz';
import { isSkinProblemType } from '@customer-frontend/utils';
import { ProgressChecklist } from './progress-checklist';
import { ProgressChecklistItem } from './types';

export const ProgressChecklistGuide: React.FunctionComponent<{
  source: string;
  problemType?: ProblemType;
}> = ({ source, problemType }) => {
  const featureFlagClient = useFeatureFlagClient();

  const isMedicareCollectionEnabled =
    featureFlagClient.getMultivariate('ENABLE_MEDICARE_COLLECTION', {
      disableExposureTracking: true,
    }) === 'variation';

  const showMedicareInProgressCheckList =
    getMedicareAnsOptedFromSS() !== MedicareAnswerOpted.NoMedicare &&
    isMedicareCollectionEnabled;
  const { medicareDetailsCollected } = usePatientAndHealthcareAttrs();

  const startSkinQuiz = useStartTrackerQuiz();

  const { data: progressEntryData, loading: progressEntriesLoading } =
    useProgressEntries({
      variables: {
        type: 'SKIN',
      },
      fetchPolicy: 'cache-first',
    });

  const progressEntries = useMemo(() => {
    return (
      progressEntryData?.progressEntries.filter(
        (entry) => moment().diff(entry.createdAt, 'days') < 5,
      ) ?? []
    );
  }, [progressEntryData]);

  const hasRecentSkinTrackerEntries = Boolean(progressEntries.length);

  if (progressEntriesLoading) {
    return null;
  }

  const progressChecklistItems: ProgressChecklistItem[] = [
    {
      key: 'confirm payment',
      variant: 'completed',
      node: (
        <Typography size="medium-paragraph" color={colors.primary[500]}>
          <span className="line-through">You&apos;ve confirmed payment</span>
        </Typography>
      ),
    },
  ];

  if (showMedicareInProgressCheckList) {
    progressChecklistItems.push({
      key: 'medicare status',
      variant: getMedicareProgressNodeVariant(medicareDetailsCollected),
      node: (
        <p
          className={getMedicareProgressNodeClassName(medicareDetailsCollected)}
        >
          {getMedicareProgressNodeText(medicareDetailsCollected)}
        </p>
      ),
    });
  }

  if (isSkinProblemType(problemType)) {
    progressChecklistItems.push({
      key: 'upload skin tracker photos',
      variant: hasRecentSkinTrackerEntries ? 'completed' : 'incomplete',
      node: hasRecentSkinTrackerEntries ? (
        <Typography size="medium-paragraph" color={colors.primary[500]}>
          <span className="line-through">Photo entry uploaded</span>
        </Typography>
      ) : (
        <div className="space-y-2">
          <Typography size="xs" isBold>
            Upload your ‘before’ photos
          </Typography>
          <Typography size="medium-paragraph">
            These will help the practitioner assess your skin and let you track
            your progress.
          </Typography>
          <div className="w-auto">
            <Button
              eventElementName="progressChecklistButton"
              size="sm"
              onClick={() => startSkinQuiz(source, 'SKIN_GENERAL')}
            >
              Upload
            </Button>
          </div>
        </div>
      ),
    });
  }

  progressChecklistItems.push(
    {
      key: 'doctor will review',
      variant: 'incomplete',
      node: (
        <div className="space-y-2">
          <Typography size="xs" isBold>
            Consult with a practitioner
          </Typography>
          <Typography size="medium-paragraph">
            An Australian practitioner will review your quiz answers and be in
            touch to begin your consultation soon.
          </Typography>
          <Typography size="medium-paragraph">
            You’ll be notified by <strong>text</strong> in{' '}
            <strong>24-48 business hours</strong> (often sooner).
          </Typography>
        </div>
      ),
    },
    {
      key: 'confirm prescription',
      variant: 'disabled',
      node: (
        <Typography size="medium-paragraph" color={colors.primary[500]}>
          Confirm your order
        </Typography>
      ),
    },
  );

  return <ProgressChecklist items={progressChecklistItems} />;
};
