import React from 'react';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import {
  ProblemType,
  TreatmentOverviewSoftwareQuery,
  TreatmentOverviewSoftwareQueryVariables,
} from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { useLocation, useParams } from 'react-router-dom';
import { getInlineReadableProblemType } from '@customer-frontend/utils';
import { gql, useQuery } from '@apollo/client';
interface TreatmentProps {
  children: React.ReactElement;
}

const getHeaderText = (route: string, problemType?: ProblemType): string => {
  if (route.includes('plan')) {
    return getInlineReadableProblemType(problemType) ?? '';
  }

  if (route.includes('notes')) {
    return 'Practitioner Notes';
  }

  return '';
};

export default function TreatmentLayout({
  children,
}: TreatmentProps): React.ReactElement {
  const { treatmentId } = useParams<{ treatmentId: string }>();

  const location = useLocation();
  useHistoryGoBackBehaviour();

  const { data } = useQuery<
    TreatmentOverviewSoftwareQuery,
    TreatmentOverviewSoftwareQueryVariables
  >(
    gql`
      query TreatmentOverviewSoftware($id: String!) {
        treatment(id: $id) {
          id
          type
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id: treatmentId,
      },
    },
  );

  const headerText = getHeaderText(location.pathname, data?.treatment?.type);

  return (
    <section className="bg-beige-100">
      {headerText && (
        <div className="bg-white text-center w-full py-6">
          <Typography size="md" isBold>
            <span className="capitalize">{headerText}</span>
          </Typography>
        </div>
      )}
      <div className="max-w-2xl px-4 py-8 mb-8 mx-auto overflow-hidden sm:overflow-visible">
        {children}
      </div>
    </section>
  );
}
