import { ReactElement } from 'react';
import clsx from 'clsx';
import { PhotoAngleType } from '@customer-frontend/graphql-types';
import { getFrontPhotoUrlFromEntry } from './utils';
import { ImageTitle } from './image-title';

type EntryImageSize = 'large' | 'small';

export const SkinProgressEntry = ({
  entry,
  imageTitle,
}: {
  entry: {
    photos?:
      | ({ angle: PhotoAngleType; signedUrl: string } | undefined | null)[]
      | null;
  };
  imageTitle: string;
}): ReactElement => {
  const image = getFrontPhotoUrlFromEntry(entry);
  return (
    <div>
      <ImageTitle copy={imageTitle} />
      <EntryImage image={image} />
    </div>
  );
};

export const EntryImage = ({
  image,
  size = 'large',
}: {
  image?: string;
  size?: EntryImageSize;
}): React.ReactElement => {
  const bgStyles = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const getClassName = (styles: string): string => {
    return clsx(
      styles,
      { 'h-36 md:h-40': size === 'small' },
      { 'h-48 md:h-60': size === 'large' },
    );
  };

  return (
    <div className={getClassName(`w-full px-1`)}>
      <div style={bgStyles} className={getClassName(`bg-gray-200 rounded-md`)}>
        &nbsp;
      </div>
    </div>
  );
};
