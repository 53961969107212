import { useEffect, useState } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import {
  PersonalDetailsFragment,
  UpdateProfileUserDetailsMutation,
  UpdateProfileUserDetailsMutationVariables,
} from '@customer-frontend/graphql-types';
import {
  Card,
  Typography,
  Button,
  TextInput,
  useNotification,
} from '@eucalyptusvc/design-system';
import { useForm } from 'react-hook-form';
import { useAuth } from '@customer-frontend/auth';
import { useUpdateResidentialAddress } from '@customer-frontend/services';
import { getConfig } from '@customer-frontend/config';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import {
  IntlMobileNumberInput,
  IntlFullNameInput,
  IntlAddressInput,
  IntlAddressView,
} from '@customer-frontend/intl';

const updateProfileDocument = gql`
  mutation UpdateProfileUserDetails(
    $firstName: String!
    $lastName: String!
    $phone: String!
  ) {
    updateProfile(firstName: $firstName, lastName: $lastName, phone: $phone) {
      id
      firstName
      lastName
      email
      shortAddressableName
      fullName
      phone
    }
  }
`;

type PersonalDetailsCardProps = {
  user: PersonalDetailsFragment;
  onUpdated?: () => void;
  validPhoneRegions: { countryCode: string }[];
  enableEditing?: boolean;
  onError: (e: ApolloError) => void;
};

export const PersonalDetailsCard = ({
  user,
  onUpdated,
  validPhoneRegions,
  enableEditing = false,
  onError,
}: PersonalDetailsCardProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [isEditing, setIsEditing] = useState(enableEditing);
  const { updateUser } = useAuth();
  const config = getConfig();
  const notify = useNotification();

  const { register, handleSubmit, errors, reset } = useForm<{
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    address: {
      line1?: string;
      line2?: string;
      city?: string;
      postalCode?: string;
      prefecture?: string;
      municipality?: string;
      townArea?: string;
      state?: string;
      building?: string;
      company?: string;
      country?: string;
    };
  }>({});

  useEffect(() => {
    reset({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      phone: user.phone ?? '',
      email: user.email ?? '',
      address: {
        line1: user.residentialAddress?.line1 ?? '',
        line2: user.residentialAddress?.line2 ?? '',
        city: user.residentialAddress?.city ?? '',
        postalCode: user.residentialAddress?.postalCode ?? '',
        prefecture: user.residentialAddress?.prefecture ?? '',
        municipality: user.residentialAddress?.municipality ?? '',
        townArea: user.residentialAddress?.townArea ?? '',
        state: user.residentialAddress?.state ?? '',
        building: user.residentialAddress?.building ?? '',
        company: user.residentialAddress?.company ?? '',
        country: user.residentialAddress?.country ?? config.country,
      },
    });
  }, [reset, user, isEditing, config.country]);

  const [updateProfile, { loading }] = useMutation<
    UpdateProfileUserDetailsMutation,
    UpdateProfileUserDetailsMutationVariables
  >(updateProfileDocument, {
    onCompleted: (response) => {
      if (response.updateProfile) {
        updateUser?.(response.updateProfile);
        setIsEditing(false);
        onUpdated?.();
      }
    },
  });

  const [updateResidentialMutation] = useUpdateResidentialAddress({
    onError,
    context: {
      skipErrorNotification: true,
    },
  });

  return (
    <Card>
      <div className="flex justify-between align-start">
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Personal details"
            description="Title of personal details settings section"
          />
        </Typography>
        {!isEditing && (
          <span>
            <Button level="secondary" onClick={() => setIsEditing(true)}>
              <FormattedMessage
                defaultMessage="Edit"
                description="Button to edit details"
              />
            </Button>
          </span>
        )}
      </div>
      {isEditing ? (
        <form
          className="mt-4"
          onSubmit={handleSubmit(async (data) => {
            try {
              await updateResidentialMutation({
                variables: {
                  residentialAddress: {
                    line1: data.address.line1 || null,
                    line2: data.address.line2 || null,
                    city: data.address.city || null,
                    postalCode: data.address.postalCode || null,
                    prefecture: data.address.prefecture || null,
                    municipality: data.address.municipality || null,
                    townArea: data.address.townArea || null,
                    state: data.address.state || null,
                    building: data.address.building || null,
                    company: data.address.company || null,
                    country: data.address.country || config.country,
                  },
                },
                context: {
                  skipErrorNotification: true,
                },
              });

              await updateProfile({
                variables: {
                  firstName: data?.firstName,
                  lastName: data?.lastName,
                  phone: data?.phone,
                },
                context: {
                  skipErrorNotification: true,
                },
              });

              notify.success({
                message: formatMessage({
                  defaultMessage: 'Your details have been saved successfully!',
                  description:
                    'Success message after updating the user details in profile',
                }),
              });
            } catch (err) {
              const descriptions = getErrorMessageDescriptorsFromError(err);
              descriptions.forEach((descriptor) =>
                notify.error({ message: formatMessage(descriptor) }),
              );
            }
          })}
        >
          <IntlFullNameInput
            register={register}
            errors={errors}
            className="gap-4 mb-4"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <TextInput
                ref={register}
                label={formatMessage({
                  defaultMessage: 'Email',
                  description: 'Label for email attribute in settings',
                })}
                name="email"
                disabled={true}
              />
            </div>
            <div>
              <IntlMobileNumberInput
                countryCodes={validPhoneRegions.map(
                  ({ countryCode }) => countryCode,
                )}
                register={register}
                errorMessage={errors?.phone?.message}
              />
            </div>

            <div className="col-span-1 md:col-span-2">
              <IntlAddressInput
                className="mt-3"
                name="address"
                registerField={register}
                errors={errors.address ?? {}}
                useAutoComplete
              />
            </div>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4">
            <Button
              isFullWidth
              level="secondary"
              onClick={() => setIsEditing(false)}
            >
              <FormattedMessage
                defaultMessage="Cancel"
                description="Button that cancels action"
              />
            </Button>
            <Button isSubmit isFullWidth isLoading={loading}>
              <FormattedMessage
                defaultMessage="Save"
                description="Button that saves details"
              />
            </Button>
          </div>
        </form>
      ) : (
        <div className="space-y-2">
          <Typography size="medium-paragraph">
            <span className="font-medium">
              <FormattedMessage
                defaultMessage="Name"
                description="Label for name attribute in settings"
              />
              {/* eslint-disable formatjs/no-literal-string-in-jsx,react/jsx-no-literals*/}
              :{' '}
            </span>
            {user.fullName}
          </Typography>
          <Typography size="medium-paragraph">
            <span className="font-medium">
              <FormattedMessage
                defaultMessage="Email"
                description="Label for email attribute in settings"
              />
              :{' '}
            </span>
            {user.email}
          </Typography>
          <Typography size="medium-paragraph">
            <span className="font-medium">
              <FormattedMessage
                defaultMessage="Phone"
                description="Label for phone attribute in settings"
              />
              :{' '}
            </span>
            {user.phone ?? '-'}
          </Typography>
          <Typography size="medium-paragraph">
            <span className="font-medium">
              <FormattedMessage
                defaultMessage="Home Address"
                description="Label for home address attribute in settings"
              />
              :{' '}
            </span>
            {!user.residentialAddress && '-'}
          </Typography>
          <IntlAddressView address={user.residentialAddress} />
        </div>
      )}
    </Card>
  );
};

PersonalDetailsCard.fragment = gql`
  fragment PersonalDetails on User {
    id
    email
    fullName
    firstName
    lastName
    phone
    residentialAddress {
      id
      line1
      line2
      city
      postalCode
      prefecture
      municipality
      townArea
      state
      building
      company
      country
    }
  }
`;
