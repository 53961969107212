import React from 'react';
import { Typography } from '@eucalyptusvc/design-system';
import { SupportForm, SupportFormData } from './support-form';
import { SkinSupportCategory, SupportCategory } from './types';
import { ProblemType } from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';

const getDescription = (problemType?: ProblemType): string => {
  if (isSkinProblemType(problemType)) {
    return 'In your response please include what side effects you are experiencing, when they started, how often you are applying your formula & what your current skincare routine looks like.';
  }

  return "To help our team respond to your concerns, please tell us about the side effects you're experiencing and when they started.";
};

export const SupportMedical = ({
  category,
  onClose,
  onSubmit,
  submitLoading,
  requestLoading,
  problemType,
}: {
  category: SupportCategory | SkinSupportCategory | 'checkInQuiz';
  onClose: () => void;
  onSubmit: (formData: SupportFormData) => void;
  submitLoading: boolean;
  requestLoading: boolean;
  problemType?: ProblemType;
}): React.ReactElement => {
  return (
    <div className="space-y-4">
      {category === 'sideEffects' && (
        <Typography size="medium-paragraph">
          {getDescription(problemType)}
        </Typography>
      )}
      <SupportForm
        loading={submitLoading}
        disabled={requestLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
      <Typography size="paragraph">
        Our Medical Support Team operate 9am-5pm Monday to Sunday. If you are
        experiencing a medical emergency, please call 000.
      </Typography>
    </div>
  );
};
