import { type ReactElement } from 'react';
import {
  ProblemType,
  TreatmentFollowUpCardTreatmentFragment,
} from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { ConsultationCardProps } from '../types';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useIHI } from '@customer-frontend/healthcare-collection';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';
import { gql } from '@apollo/client';
import { PrescriberType } from '@customer-frontend/doctor';

type TreatmentFollowUpCardProps = {
  consultationId: string;
  pendingFollowUpReminderDate: string | null;
  isLastOrderUnfulfilled: boolean;
  treatment: TreatmentFollowUpCardTreatmentFragment;
  problemType: ProblemType;
  quizApplicationSubmitted: boolean;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  routes: {
    consultation: {
      followUp: (id: string) => string;
      collectAddress: (id: string) => string;
      collectMedicare: (id: string) => string;
      phoneCall: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function TreatmentFollowUpCard({
  consultationId,
  pendingFollowUpReminderDate,
  isLastOrderUnfulfilled,
  treatment,
  problemType,
  quizApplicationSubmitted,
  useCustomTreatmentDetails,
  routes,
}: TreatmentFollowUpCardProps): ReactElement {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const config = getConfig();
  const { ihiCollected, hasResidentialAddress } = useIHI(problemType);
  const reminderDate = pendingFollowUpReminderDate
    ? formatDate(
        mapBrandToAdaptersBrand(config.brand),
        pendingFollowUpReminderDate,
        {
          dateStyle: 'medium',
        },
      )
    : null;
  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');

  const paragraphs = [];
  if (quizApplicationSubmitted) {
    paragraphs.push({
      key: 'treatmentFollowUpQuizSubmitted',
      text: (
        <FormattedMessage
          defaultMessage="Continue with your consult so your {isPrescriber, select, true {prescriber} other {practitioner}} can review your treatment."
          description="Description informing users that they need to continue with thier consultation"
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
    });
  } else {
    if (isLastOrderUnfulfilled) {
      paragraphs.push({
        key: 'treatmentFollowUpUnfulfilled',
        text: (
          <FormattedMessage
            defaultMessage="You will be notified when your order is on its way. Sit tight!"
            description="Description telling users that they will be notified of any updates to their order"
          />
        ),
      });
    } else if (reminderDate) {
      paragraphs.push(
        {
          key: 'treatmentFollowUpReminder',
          text: (
            <FormattedMessage
              defaultMessage="We have scheduled a follow up consultation when you are close to finishing your treatment on the {reminderDate}."
              description="Message informing users when their follow-up consultation is scheduled"
              values={{
                reminderDate: <strong>{reminderDate}</strong>,
              }}
            />
          ),
        },
        {
          key: 'treatmentFollowUpEarlyConsult',
          text: (
            <FormattedMessage
              defaultMessage="However, if you are concerned about your treatment, <link>click here to start your follow up consult early</link>"
              description="Message informing users when their follow-up consultation is scheduled"
              values={{
                link: (text) => (
                  <Link
                    className="text-link"
                    to={routes.consultation.followUp(consultationId)}
                  >
                    {text}
                  </Link>
                ),
              }}
            />
          ),
        },
      );
    } else {
      paragraphs.push({
        key: 'treatmentFollowUpStart',
        text: (
          <FormattedMessage
            defaultMessage="Start a follow up consultation to get a new script with the {isPrescriber, select, true {prescriber} other {practitioner}}."
            description="Description letting users know how to get a new script for their treatment"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        ),
      });
    }
  }

  const buttons: ConsultationCardProps['buttons'] = quizApplicationSubmitted
    ? [
        {
          key: 'treatmentFollowUpButtonContinue',
          text: (
            <FormattedMessage
              defaultMessage="Continue consult"
              description="Button to continue consult"
            />
          ),
          onClick: () => {
            if (!hasResidentialAddress) {
              return history.push(
                routes.consultation.collectAddress(consultationId),
              );
            }
            if (!ihiCollected) {
              return history.push({
                pathname: routes.consultation.collectMedicare(consultationId),
              });
            }
            return history.push(routes.consultation.phoneCall(consultationId));
          },
        },
      ]
    : [
        {
          key: 'treatmentFollowUpButtonStart',
          level: 'primary' as const,
          text: (
            <FormattedMessage
              defaultMessage="Start follow up"
              description="Button that starts a followup consult with the practitioner"
            />
          ),
          onClick: () => {
            history.push(routes.consultation.followUp(consultationId));
          },
        },
      ];

  if (treatment.active && problemType === 'WEIGHT_LOSS' && !sunsetBpDashboard) {
    buttons.push({
      key: 'treatmentFollowUpWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      level: 'secondary' as const,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
    });
  }

  return (
    <ConsultationCardContentView paragraphs={paragraphs} buttons={buttons}>
      <TreatmentDetails
        treatment={treatment}
        status={
          isLastOrderUnfulfilled
            ? formatMessage({
                defaultMessage: 'Your order is being processed',
                description: 'Title showing a users order status',
              })
            : formatMessage({
                defaultMessage: 'Follow up required',
                description: 'Title showing status of a treatment',
              })
        }
        useCustomTreatmentDetails={useCustomTreatmentDetails}
      />
    </ConsultationCardContentView>
  );
}

TreatmentFollowUpCard.fragment = gql`
  fragment TreatmentFollowUpCardTreatment on Treatment {
    id
    active
    nextPayment
    otcSchedules {
      id
      cadence
      quantity
      isActive
      product {
        id
        name
      }
    }
    product {
      id
      name
      friendlyName
    }
    ...TreatmentDetailsTreatment
  }

  ${TreatmentDetails.fragment}
`;
