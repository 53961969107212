import { RefetchQueriesInclude, gql, useMutation } from '@apollo/client';
import {
  RefillTreatmentRestartTreatmentJuniperMutation,
  RefillTreatmentRestartTreatmentJuniperMutationVariables,
} from '@customer-frontend/graphql-types';
import { formatCentsToCurrency } from '@customer-frontend/order';
import {
  Modal,
  Button,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const restartTreatmentMutation = gql`
  mutation RefillTreatmentRestartTreatmentJuniper($treatmentId: String!) {
    refillTreatment(treatmentId: $treatmentId) {
      id
      status
      refillsLeft
      nextPayment
    }
  }
`;

type RestartTreatmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  chargeAmount: number;
  treatment: {
    id: string;
  };
  refetchQueries?: RefetchQueriesInclude;
};

export const RestartTreatmentModal = ({
  isOpen,
  onClose,
  treatment,
  chargeAmount,
  refetchQueries,
}: RestartTreatmentModalProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const notify = useNotification();

  const [restartTreatment, { loading }] = useMutation<
    RefillTreatmentRestartTreatmentJuniperMutation,
    RefillTreatmentRestartTreatmentJuniperMutationVariables
  >(restartTreatmentMutation, {
    refetchQueries,
    onCompleted: () =>
      notify.success({
        message: formatMessage({
          defaultMessage: 'Your treatment has been restarted.',
          description:
            'Success message on profile after the user selects to restart their treatment',
        }),
      }),
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage:
            'Your request to restart your treatment has failed. Please try again.',
          description:
            'Error message when user attempts to reset their treatment and fails',
        }),
      });
      close();
    },
    context: {
      skipErrorNotification: true,
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Confirm shipment"
            description="Modal title to show the confirm shipment in profile"
          />
        </Typography>
      }
    >
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="You will be charged <strong> {amount}. </strong>"
            description="Text in profile to show how much the user will be charged"
            values={{
              amount: formatCentsToCurrency(chargeAmount),
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
        <Button
          isSubmit
          isFullWidth
          isLoading={loading}
          onClick={() => {
            restartTreatment({
              variables: {
                treatmentId: treatment.id,
              },
            });
          }}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            description="Button text to confirm resting treatment in profile"
          />
        </Button>
      </div>
    </Modal>
  );
};
