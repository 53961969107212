import { Typography, Card } from '@eucalyptusvc/design-system';
import { ProgressEntry as ProgressEntryData } from '@customer-frontend/graphql-types';
import React, { ReactElement } from 'react';
import ProgressEntry from './progress-entry';
import { EntryWithWeek } from './types';
import {
  getWeekLabel,
  groupEntriesByWeeks,
  progressEntryToProgressEntryAnswerInput,
} from './utils';
import moment from 'moment';

const ProgressFeed = ({
  entries,
  treatmentStartDate,
}: {
  treatmentStartDate: Date;
  entries: ProgressEntryData[];
}): ReactElement => {
  const weeklyEntries = groupEntriesByWeeks(treatmentStartDate, entries);
  weeklyEntries.sort((a, b) => b.week - a.week);

  return (
    <div className="my-8 space-y-4">
      {entries &&
        weeklyEntries.map((entry) => (
          <Card key={entry.week}>
            <ul className="space-y-4">
              <Typography size="md" isBold>
                {getWeekLabel(entry.week)}
              </Typography>
              <ProgressEntryWeek entriesWithWeek={entry} />
            </ul>
          </Card>
        ))}
    </div>
  );
};

export default ProgressFeed;

const ProgressEntryWeek = ({
  entriesWithWeek,
}: {
  entriesWithWeek: EntryWithWeek;
}): ReactElement => {
  const { entries } = entriesWithWeek;

  const entriesForWeek = entries
    .sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    })
    .map((entry: ProgressEntryData) => (
      <li key={entry.id}>
        <ProgressEntry
          date={moment(entry.date).local().format('DD MMM YYYY').toString()}
          answers={progressEntryToProgressEntryAnswerInput(entry)}
        />
      </li>
    ));

  return <>{entriesForWeek}</>;
};
