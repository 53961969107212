import { ProblemType, ProductType } from '@customer-frontend/graphql-types';
import { isOtc } from '@customer-frontend/order';
import { isSkinProblemType } from '@customer-frontend/utils';

export const SKIN_RX_PRODUCT_NAME = 'Software prescription cream';

export const getRxProductName = ({
  product,
  problemType,
}: {
  product: { name?: string | null };
  problemType: ProblemType;
}): string | undefined | null => {
  if (isSkinProblemType(problemType)) {
    return SKIN_RX_PRODUCT_NAME;
  }

  return product.name;
};

export const getLineItemDisplayName = (
  problemType: ProblemType,
  lineItem: { name?: string | null; productType?: ProductType | null },
): string | undefined | null => {
  if (isOtc(lineItem)) {
    return lineItem.name;
  }

  return getRxProductName({
    product: lineItem,
    problemType,
  });
};
