import { Button, Typography, useResponsive } from '@eucalyptusvc/design-system';
import { ReactComponent as HeartBeat } from '../../../../assets/icons/heart-beat.svg';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Card as CardType, CardKey } from '../use-profile-cards';

export type SkinCheckInCardProps = {
  ctaText: string;
};

const CheckInCard: React.FC<SkinCheckInCardProps> = ({ ctaText }) => {
  const { isMobile } = useResponsive();
  const history = useHistory();
  return (
    <div className="border border-black rounded-2xl bg-secondary-100 p-4 relative">
      <div className="flex flex-col sm:flex-row justify-between space-y-4 md:space-x-4">
        <div className="flex flex-row flex-col space-y-3">
          <div className="flex items-center space-x-2">
            <HeartBeat className="h-3 w-auto" />
            <Typography size="large-paragraph" isBold={true}>
              <span>It&apos;s time for a check-in</span>
            </Typography>
          </div>
          <Typography size="medium-paragraph">
            Take 2 minutes to update us on your skin and receive custom
            recommendations.
          </Typography>
        </div>
        <div className="flex flex-col justify-center">
          <Button
            size={isMobile ? 'sm' : 'md'}
            isFullWidth={isMobile}
            onClick={() => history.push(routes.checkIn)}
          >
            {ctaText}
          </Button>
        </div>
      </div>
      <div className="rounded-full w-2 h-2 absolute top-5 right-3 bg-error-500" />
    </div>
  );
};

export const getCheckInCard = ({
  props,
  key,
}: {
  props: SkinCheckInCardProps;
  key: CardKey;
}): CardType<SkinCheckInCardProps> => ({
  CardComponent: CheckInCard,
  key,
  props,
});
