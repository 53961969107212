import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { ProductRow, SkinGoalsSummary, StatusLayout } from './components';
import { routes } from 'utils/routes';
import { getActiveOtcScheduleProducts } from '@customer-frontend/order';
import { getRxProductName } from 'utils/logic/order';
import { ViewPathologyReferral } from './view-pathology-referral';

export const ProcessingOrderTreatmentCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const treatment = consultation.treatment;
  const activeOrderProducts = getActiveOtcScheduleProducts(
    consultation.treatment,
  );

  if (consultation.treatment?.product) {
    activeOrderProducts.unshift({
      ...consultation.treatment?.product,
      name:
        getRxProductName({
          product: treatment?.product ?? {},
          problemType: consultation.type,
        }) ?? '',
      cadence: 'RECURRING',
      quantity: 1,
    });
  }

  return (
    <StatusLayout {...layoutProps} testId="processing-order-treatment-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <strong>Treatment plan: </strong>
          {consultation.treatment?.product.name}
        </Typography>
        <Typography size="medium-paragraph">
          <strong>Repeats left: </strong>
          {consultation.treatment?.refillsLeft}
        </Typography>
        <div className="pt-2 space-y-4">
          <Typography size="medium-paragraph">
            Your order is being processed.
          </Typography>
          <ul className="flex flex-col md:flex-row md:flex-wrap">
            {activeOrderProducts.map((product) => (
              <ProductRow product={product} key={product.id} />
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        {treatment && (
          <Button
            isFullWidth
            eventElementName="treatmentPageProcessingOrderButton"
            onClick={() => {
              history.push(routes.treatment.plan(treatment.id));
            }}
          >
            View details
          </Button>
        )}
        <ViewPathologyReferral consultation={consultation} />
      </div>
    </StatusLayout>
  );
};
