import React from 'react';
import {
  ProgressEntry,
  ProgressEntryAnswerInput,
} from '@customer-frontend/graphql-types';
import {
  getAnswerByQuestionCode,
  photoResponseOrder,
  progressEntryToProgressEntryAnswerInput,
} from '../utils';

export const ProgressEntryImage = ({
  imageId,
}: {
  imageId: string;
}): React.ReactElement => {
  const bgStyles = {
    backgroundImage: `url(${imageId})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div className="w-full h-32 md:h-36">
        <div
          style={bgStyles}
          className="w-full h-32 md:h-36 bg-gray-200 rounded-md"
        >
          &nbsp;
        </div>
      </div>
    </>
  );
};

const getOrderedImages = (
  answers: ProgressEntryAnswerInput[],
): React.ReactElement[] => {
  const images: React.ReactElement[] = [];

  photoResponseOrder.forEach((questionCode) => {
    const matchingResponse = getAnswerByQuestionCode(answers, questionCode);
    if (matchingResponse?.type === 'PHOTO') {
      images.push(
        <ProgressEntryImage
          key={matchingResponse.answer}
          imageId={matchingResponse.answer}
        />,
      );
    }
  });
  return images;
};

export const EntryImages = ({
  entry,
}: {
  entry: ProgressEntry;
}): React.ReactElement => {
  return (
    <div className="flex space-x-0.5 w-full">
      {getOrderedImages(progressEntryToProgressEntryAnswerInput(entry))}
    </div>
  );
};
