import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, useNotification } from '@eucalyptusvc/design-system';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import debounce from 'lodash.debounce';

import { getConfig } from '@customer-frontend/config';
import { useUpdateShippingAddressMutation } from '@customer-frontend/graphql-types';

import type { ShippingDetailsFormStyleConfig } from './types';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { useIntl } from 'react-intl';
import { AddressFields, IntlAddressInput } from '@customer-frontend/intl';
import { ShippingValidationStatus } from '@customer-frontend/order';

interface FormFields {
  shippingAddress: AddressFields;
  shippingAddressProximity?: string;
}

const validateShippingAddressRange = debounce(
  async (
    shippingAddress: AddressFields,
    apollo: ApolloClient<unknown>,
    onComplete: (p: FormFields['shippingAddressProximity']) => void,
  ) => {
    const line1 = shippingAddress?.line1;
    const line2 = shippingAddress?.line2;
    const suburb = shippingAddress?.city;
    const postcode = shippingAddress?.postalCode;

    if (!(line1 && postcode && suburb)) {
      return;
    }

    const query = await apollo.query({
      query: gql`
        query PilotProfileShippingDetailsRange(
          $line1: String!
          $line2: String
          $suburb: String!
          $postcode: String!
        ) {
          shippingAddressWithinRange(
            line1: $line1
            line2: $line2
            postcode: $postcode
            suburb: $suburb
          )
        }
      `,
      variables: {
        line1,
        line2,
        suburb,
        postcode,
      },
    });

    onComplete(
      query.data.shippingAddressWithinRange ? 'inRange' : 'outOfRange',
    );
  },
  1000,
);

type ShippingDetailsFormProps = {
  styleConfig?: ShippingDetailsFormStyleConfig;
  defaultValues?: FormFields;
  onSubmitted?: () => void;
  onCancel: () => void;
  shouldValidateShippingAddressRange: boolean;
};

const ShippingDetailsForm = ({
  styleConfig,
  defaultValues,
  onSubmitted,
  onCancel,
  shouldValidateShippingAddressRange,
}: ShippingDetailsFormProps): JSX.Element => {
  const config = getConfig();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const apollo = useApolloClient();

  const [validationLoading, setValidationLoading] = useState(false);

  const { formState, register, errors, handleSubmit, setValue, watch } =
    useForm<FormFields>({ defaultValues, shouldUnregister: false });

  const [updateShippingAddress] = useUpdateShippingAddressMutation();

  const onSubmit = async (data: FormFields): Promise<void> => {
    try {
      await updateShippingAddress({
        variables: {
          address: {
            line1: data.shippingAddress.line1 || null,
            line2: data.shippingAddress.line2 || null,
            city: data.shippingAddress.city || null,
            postalCode: data.shippingAddress.postalCode || null,
            state: data.shippingAddress.state || null,
            prefecture: data.shippingAddress.prefecture || null,
            municipality: data.shippingAddress.municipality || null,
            townArea: data.shippingAddress.townArea || null,
            company: data.shippingAddress.company || null,
            building: data.shippingAddress.building || null,
            country: data.shippingAddress.country || config.country,
          },
        },
      });

      notify.success({
        message: 'Shipping details successfully updated',
      });

      onSubmitted?.();
    } catch (err) {
      const descriptions = getErrorMessageDescriptorsFromError(err);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    }
  };

  const shippingAddress = watch('shippingAddress');
  const shippingAddressProximity = watch('shippingAddressProximity');
  const isShippingAddressWithinRange = shippingAddressProximity === 'inRange';

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="md:space-y-3">
        <div>
          <IntlAddressInput
            name="shippingAddress"
            registerField={register}
            errors={errors?.shippingAddress}
            palette={styleConfig?.textInput?.palette}
            useAutoComplete
            onChange={async (address) => {
              if (shouldValidateShippingAddressRange) {
                setValidationLoading(true);
                await validateShippingAddressRange(address, apollo, (p) => {
                  setValue('shippingAddressProximity', p);
                  setValidationLoading(false);
                });
              }
            }}
          />
          {shouldValidateShippingAddressRange && (
            <div className="text-black">
              <ShippingValidationStatus
                isResidential={false}
                isValidatingShippingAddress={validationLoading}
                isShippingAddressCompleted={!!shippingAddress?.postalCode}
                isShippingAddressWithinRange={isShippingAddressWithinRange}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse gap-2">
        <Button
          level={styleConfig?.saveButton?.level}
          palette={styleConfig?.saveButton?.palette}
          isLoading={formState.isSubmitting}
          isFullWidth
          isSubmit
          isDisabled={!isShippingAddressWithinRange || validationLoading}
        >
          Save
        </Button>
        <Button
          level={styleConfig?.cancelButton?.level}
          palette={styleConfig?.cancelButton?.palette}
          isDisabled={formState.isSubmitting}
          isFullWidth
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ShippingDetailsForm;
