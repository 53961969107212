import { useHistory } from 'react-router-dom';
import { ConsultationStatus } from '@customer-frontend/graphql-types';
import { Button } from '@eucalyptusvc/design-system';
import { routes } from 'utils/routes';

export const ViewPathologyReferral = ({
  consultation,
}: {
  consultation: {
    id: string;
    status: ConsultationStatus;
    pathologyRequests?: unknown[] | null;
  };
}): React.ReactElement | null => {
  const history = useHistory();
  const hasPathologyRequest = (consultation.pathologyRequests ?? []).length > 0;

  if (!hasPathologyRequest) {
    return null;
  }

  return (
    <Button
      isFullWidth
      level={
        consultation.status === 'AWAITING_RESULTS' ? 'primary' : 'secondary'
      }
      onClick={() => {
        history.push(routes.consultation.confirmPathology(consultation.id));
      }}
    >
      View pathology referral
    </Button>
  );
};
