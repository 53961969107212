import clsx from 'clsx';
import { Brand } from '@customer-frontend/types';

const baseClasses = {
  border: 'border border-solid',
  divider: 'mx-6',
} as const;

export const getRootClassName = (brand: Brand, hasBorder: boolean): string => {
  switch (brand) {
    case 'pilot': {
      return clsx('border-white border-opacity-30 rounded-md', {
        [baseClasses.border]: hasBorder,
      });
    }

    case 'kin': {
      return clsx('bg-white border-primary-purple-200 rounded-md', {
        [baseClasses.border]: hasBorder,
      });
    }

    case 'software': {
      return clsx('rounded-xl border-black', {
        [baseClasses.border]: hasBorder,
      });
    }

    default: {
      return baseClasses.border;
    }
  }
};

export const getDividerClassName = (
  brand: Brand,
  hasBorder: boolean,
): string => {
  switch (brand) {
    case 'pilot': {
      return clsx('border-white opacity-30', {
        [baseClasses.divider]: hasBorder,
      });
    }

    case 'kin': {
      return clsx('border-primary-purple-300', {
        [baseClasses.divider]: hasBorder,
      });
    }

    case 'software': {
      return 'hidden';
    }

    default: {
      return clsx({
        [baseClasses.divider]: hasBorder,
      });
    }
  }
};
