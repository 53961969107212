import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';

export const AwaitingAnswersCard: React.FC<{
  consultationId: string;
  doctorName: string;
  hasChatThread: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
  };
}> = ({ consultationId, doctorName, hasChatThread, routes }) => {
  const buttonKey = 'awaitingAnswersButton';
  const history = useHistory();

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'awaitingAnswersText',
          text: (
            <FormattedMessage
              defaultMessage="{person} has sent you a message."
              description="Status text informing users someone has sent them a message"
              values={{
                person: doctorName,
              }}
            />
          ),
        },
      ]}
      buttons={[
        {
          key: buttonKey,
          text: (
            <FormattedMessage
              defaultMessage="View message"
              description="Button that navigates to message screen"
            />
          ),
          onClick: () => {
            if (hasChatThread) {
              history.push(routes.consultation.chat(consultationId));
            } else {
              history.push(routes.consultation.review(consultationId));
            }
          },
        },
      ]}
    />
  );
};
