import { Button, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getInlineReadableProblemType } from '@customer-frontend/utils';
import { SkinGoalsSummary, StatusLayout } from './components';
import { ReviewTreatmentContent } from './review-treatment';
import { StatusCardContentProps } from './types';
import { ViewPathologyReferral } from './view-pathology-referral';
import { routes } from 'utils/routes';

export const CreatedTreatmentCardContent = (
  props: StatusCardContentProps,
): React.ReactElement => {
  const history = useHistory();
  const isFollowUpOrReview =
    props.consultation.stage === 'FOLLOW_UP' ||
    props.consultation.stage === 'REVIEW';

  // Handle scenario in which a patient has asked a question about the treatment
  // Treatment is CREATED, and consultation is DOCTOR_ASSIGNED
  if (props.consultation.status === 'DOCTOR_ASSIGNED') {
    return <ReviewTreatmentContent {...props} />;
  }

  return (
    <StatusLayout {...props} testId="created-treatment-status-card">
      <div className="space-y-4">
        <SkinGoalsSummary
          skinGoals={props.personalisationInput.mappedSkinGoals}
        />
        <Typography size="medium-paragraph">
          {isFollowUpOrReview
            ? `${
                props.consultation.doctor?.shortClinicianName
              } has updated your treatment plan to help you achieve your ${getInlineReadableProblemType(
                props.problemType,
              )} goals.`
            : `${
                props.consultation.doctor?.shortClinicianName
              } has created a treatment plan for you to help you achieve your ${getInlineReadableProblemType(
                props.problemType,
              )} goals.`}
        </Typography>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        <Button
          isFullWidth
          eventElementName="treatmentPageCreatedTreatmentCardButton"
          onClick={() => {
            history.push(routes.consultation.plan(props.consultation.id));
          }}
        >
          {props.consultation.isUpfrontPayment ? (
            <span
              className="animate-pulse"
              style={{
                animationIterationCount: 2,
              }}
            >
              Confirm your order
            </span>
          ) : (
            'View treatment plan'
          )}
        </Button>
        <ViewPathologyReferral consultation={props.consultation} />
      </div>
    </StatusLayout>
  );
};
