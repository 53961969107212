import { getConfig } from '@customer-frontend/config';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  ConsultationStatus,
  ProblemType,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { isSkinProblemType } from '@customer-frontend/utils';
import { completedStatuses } from '../utils';
import {
  getOnboardingQuizStatus,
  OnboardingQuizStatus,
} from './get-onboarding-quiz-status';
import {
  SkinOnboardingQuizCardPosition,
  getSkinOnboardingQuizPosition,
} from './utils';

interface SkinOnboardingQuizState {
  shouldShowSkinOnboardingQuiz: boolean;
  skinOnboardingQuizPosition: SkinOnboardingQuizCardPosition;
  ctaText: string;
}
export const useSkinOnboardingQuizConfig = ({
  consultation,
  trackerQuizApplications,
}: {
  consultation?: {
    type: ProblemType;
    status: ConsultationStatus;
    treatment?: {
      status: TreatmentStatus;
    } | null;
  } | null;
  trackerQuizApplications?:
    | {
        quiz: {
          quizCode: string;
        };
        createdAt: string;
        submittedAt?: string | null;
      }[]
    | null;
}): SkinOnboardingQuizState => {
  const config = getConfig();
  const featureFlagClient = useFeatureFlagClient();
  const skinOnboardingQuizStatus = getOnboardingQuizStatus(
    trackerQuizApplications,
  );
  const skinOnboardingQuizPosition = getSkinOnboardingQuizPosition(
    consultation?.treatment?.status,
  );
  const shouldShowSkinOnboardingQuiz =
    !!consultation &&
    config.showOnboardingQuiz &&
    isSkinProblemType(consultation?.type) &&
    completedStatuses.includes(consultation.status) &&
    skinOnboardingQuizStatus !== undefined &&
    skinOnboardingQuizStatus !== OnboardingQuizStatus.COMPLETED &&
    skinOnboardingQuizPosition !== SkinOnboardingQuizCardPosition.HIDDEN &&
    featureFlagClient.getBoolean('SOFTWARE_SKIN_ONBOARDING_QUIZ');

  const ctaText =
    skinOnboardingQuizStatus === OnboardingQuizStatus.IN_PROGRESS
      ? 'Resume'
      : 'Read';

  return {
    shouldShowSkinOnboardingQuiz,
    skinOnboardingQuizPosition,
    ctaText,
  };
};
